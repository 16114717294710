.widget-tile {
  .tiles {  

    min-height: 50px;
    padding: 5px;
    border-radius: 0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.15);

    >.title{
      margin-top: -5px;
      padding: 10px 5px; 
      >span {
        font-weight: 300;
        font-size: 16px;
        margin-left: 5px;
      }
      >.icon-title {
        position: relative;
        vertical-align: -5px;
        width: 20px;
        height: 20px;
        margin-right: 3px;
        opacity: 0.6;
      }

      .title-badge {
        display: inline-block;
        background: $color-green;
        color: #fff;
        font-size: 12px;
        text-transform: uppercase;;
        padding: 4px 10px 4px 8px;
        margin-left: 15px;
        box-shadow: 0 2px 2px rgba(0,0,0,0.2);
        margin-bottom: -5px;
        margin-top: -2px;
        vertical-align: 1px;
        i {
          vertical-align: -2px;
          margin-right: 5px;
          font-size: 12px;
        }
      }
    }
    md-divider {
      margin: 0 5px;
      border-top-color: rgba(255,255,255,0.3) !important;
    }
    .widget-tile-contents {
      padding: 15px 10px;
      font-size: 12px;
      color:#fff;

    }

    .overrideButton {
      float: right;
      font-weight: 300;
      i {
        font-size: 14px;
        vertical-align: -2px;
      }
      > span {
        padding-right: 10px;
        color: #fff;
        font-size: 12px;
        text-transform: uppercase;
        &:hover {
          color: #46A9D4;
        }
      }

      .report-problem {
        padding-right: 0;
        cursor: pointer;
        opacity: 0.3;
        padding-top: 8px;
        &:hover {
          opacity: 1;
        }
        i {
          font-size: 23px;
          vertical-align: -6px;
        }
      }
    }

    .packeryCard, .packeryCard > * {
      color: $color-white;
      border-color: #222;

      .overrideButton>span {
        color: $color-white;
      }
    }

    // better defaults....
    h5 {
      font-size: 16px;
      font-weight: 600;
      margin-top: 0;
    }
    p {
      font-size: 12px;
    }
  }
}