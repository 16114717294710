.slider {
  display: none;
  visibility: hidden;
}

.prev-button,
.next-button,
.prev-button-1,
.next-button-1 {
  top: 80px;
  font-size: 22px;
  cursor: pointer;
  display: block;
  margin-top: -10px\9; /*lte IE 8*/
  cursor: pointer;
}
.prev-button:hover:before,
.prev-button:focus:before,
.next-button:hover:before,
.next-button:focus:before,
.prev-button-1:hover:before,
.prev-button-1:focus:before,
.next-button-1:hover:before,
.next-button-1:focus:before
{
    opacity: 1;
}

.prev-button:before,
.next-button:before,
.prev-button-1:before,
.next-button-1:before
{
  opacity: .75;
  color: $color-light-grey;
} 