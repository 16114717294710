.org-editor {
  color: $color-white !important;
  .user-navigation {
    text-align: right;
  }
  div.actionButtons {
    text-align: center;
    button:first-child {
      margin-right: 10px;
    }
    margin-bottom: 40px;
  }
  json-editor {
    .form-control {
      background-color: #2c2f33 !important;
      border-color: $color-white-1;
      color: $color-grey-200;
    }
    .btn-default {
      background-color: $color-grey;
      border-color: #2c2f33;
      color: $color-white !important;
    }
  }
}