$record-tabs-background: #fff !default;
$record-tabs-border-color: #e7ecea !default;
$record-tabs-unselected-text-color: #777 !default;
$record-tabs-selected-color: #1AB595 !default;
$record-tabs-selected-background: #f5f5f5 !default;

.record-tabs-wrapper {
  margin: -1px -15px 20px -15px;

  &.locked {

    .record-tabs {
      position: fixed;
      width: 100%;
      z-index: $locked-record-tabs-z;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);

      .tab-list .tab-list-item a {
        padding-bottom: 0.25em;
      }

      .widget-label-value-simple, .tab-badge-container {
        display: none;
      }
    }


    .record-tabs .tab-list-more {

      .tab-list-item a {
        padding-bottom: 1em;
      }

      .widget-label-value-simple, .tab-badge-container {
        display: block;
      }
    }
  }

  .record-tabs {
    background: $record-tabs-background;
    transition: box-shadow 0.25s linear;

    .menu {
      position: absolute;
      z-index: $record-tab-menu-z;
      right: 20px;
      top: 20px;
    }

    .tab-list {
      font-size: 16px;
      position: relative;
      display: flex;
      padding: 0;

      .tab-list-more {
        position: absolute;
        z-index: $locked-record-tabs-z;
        border: 1px solid $record-tabs-border-color;
        background: $record-tabs-background;
        left: 0;
        right: 0;
        max-height: calc(90vh - 100px);
        overflow-y: scroll;
        box-shadow: 0 2px 2px rgba(0,0,0,0.2);

        .tab-current {
          border-bottom: 1px solid $record-tabs-border-color;
        }
      }

      &.collapsed-tab-list {
        width: 250px;
        flex-direction: column;
        border-left: 1px solid $record-tabs-border-color;
        border-bottom: 0;

        .tab-list-item {
          width: 100%;
          max-width: 1000px;
          > a {
            border-left: 0;
            border-bottom: 1px solid $record-tabs-border-color;
            border-right: 0;
          }
        }

        .tab-list-item:not(.tab-current) {
          a {
            &:after { 
              display: none;
            }
          }
        }


      }

      .tab-list-item {
        max-width: 400px;
        outline: none;
        position: relative;
        z-index: $record-tab-list-item-z;
        display: flex;
        margin: 0;
        text-align: left;
        flex: 1;
        word-break: break-word;

        &:last-child a {
          border-right: 1px solid $record-tabs-border-color;
        }

        &:first-child a {
          border-left: 0;
        }

        &.hide-more-link {
          background: rgba(128,128,128,0.1);
          a {
            padding: 0px 20px;
          }
        }
        
        .tab-badge-container {
          margin-top: 10px;
          line-height: 16px;

          .tab-badge {
            font-weight: 600;
            display: inline-block;
            background: $record-tabs-selected-color;
            color: #fff;
            font-size: 12px;
            text-transform: uppercase;;
            padding: 4px 10px 4px 8px;
            box-shadow: 0 2px 2px rgba(0,0,0,0.2);
            i {
              vertical-align: -2px;
              margin-right: 5px;
              font-size: 12px;
            }
            &.deceased {
              background-color: $color-red;
            }
          }
        }

        a {
          font-weight: 400;
          flex: 1;
          
          position: relative;
          display: block;
          line-height: 3;

          padding: 0.5em 20px 1em;
          border-left: 1px solid $record-tabs-border-color;
          border-bottom: 1px solid $record-tabs-border-color;
          transition: color 0.2s;

          color: $record-tabs-unselected-text-color;
          text-decoration: none;
          outline: none;

          .tab-label {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .material-icons {
            margin-right: 5px;
            font-size: 20px;
            vertical-align: -5px;
          }

          &:after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 6px;
            background: $record-tabs-selected-color;
            content: '';
            transition: transform 0.3s;
            transform: translate3d(0,-150%,0);
          }

          &:hover, &:focus {
            .tab-label {
              color: $record-tabs-selected-color;
            }
          }
        }

        transition: background 0.3s;
        &.tab-current {
          background: $record-tabs-selected-background;

          a {
            .tab-label {
              color: $record-tabs-selected-color;
            }
            border-bottom-color: transparent;
            &:after {
              transform: translate3d(0,0,0);
            }
          }
        }
      }
    }
  }
}