.notifications-icon {
  color: $color-white !important;
  a {
    color: $color-white;
    &:focus, &:hover {
      color: $color-white;
    }
  }
  p {
     color: $color-grey;
  }
  i.count {
    font-style: normal;
    font-size: 10px;
    background: $color-red;
    border-radius: 15px;
    padding-right: 0.45em;
    padding-left: 0.25em;
    position: absolute;
    line-height: 15px;
    right: 0.9em;
    bottom: 1.4em;
  }
}

.notifications-menu {
  width: 300px;
  
  .icon {
    font-size: 24px;
    user-select: none;
    -webkit-touch-callout: none;
  }
  .menu {
    color: $color-grey;
    position: absolute;
    right: -1px;
    background: $color-light-dark-grey;
    width: 420px;
    margin-top: 0;
    padding-top: 1em;
    overflow: scroll;
    text-align: left;
    &::-webkit-scrollbar {
      background: $color-light-dark-grey;
    }
    &::-webkit-scrollbar-thumb {
      border: 4px solid transparent;
      background-clip: padding-box;
      -webkit-border-radius: 7px;
      background-color: $color-black-1;
      -webkit-box-shadow: inset -1px -1px 0px $color-black-1, inset 1px 1px 0px $color-black-1;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }
    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
    &::-moz-scrollbar {
      background: $color-light-dark-grey;
    }
    &::-moz-scrollbar-thumb {
      border: 4px solid transparent;
      background-clip: padding-box;
      -webkit-border-radius: 7px;
      background-color: $color-black-1;
      -webkit-box-shadow: inset -1px -1px 0px $color-black-1, inset 1px 1px 0px $color-black-1;
      border-radius: 10px;
    }
    &::-moz-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }
    &::-webkit-moz-corner {
      background-color: transparent;
    }
  }

  .arrow-up {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $color-light-dark-grey;
  }

  .notification {
    p {
      font-size: 12px;
      padding: 10px 0;
      margin: 0;
      overflow: hidden;
      color: $color-white;
    }
    p.inline {
      height: inherit;
    }
    border-bottom: 1px solid $color-light-grey;
    &:first-of-type {
      margin-top: -0.65em;
    }
  }

  .emoji {
    width: 1.5em;
    height: 1.5em;
    display: inline-block;
    margin-bottom: -0.25em;
  }

  p {
    color: $color-grey;
  }


  .menu {
    background: $color-light-dark-grey !important;
    background-color: $color-light-dark-grey !important;

    &::-webkit-scrollbar {
      background: $color-light-dark-grey;
    }
    &::-webkit-scrollbar-thumb {
      border: 4px solid transparent;
      background-clip: padding-box;
      -webkit-border-radius: 7px;
      background-color: $color-black-1;
      -webkit-box-shadow: inset -1px -1px 0px $color-black-1, inset 1px 1px 0px $color-black-1;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }
    &::-moz-scrollbar-corner {
      background-color: transparent;
    }
    &::-moz-scrollbar {
      background: $color-light-dark-grey;
    }
    &::-moz-scrollbar-thumb {
      border: 4px solid transparent;
      background-clip: padding-box;
      -webkit-border-radius: 7px;
      background-color: $color-black-1;
      -webkit-box-shadow: inset -1px -1px 0px $color-black-1, inset 1px 1px 0px $color-black-1;
      border-radius: 10px;
    }
    &::-moz-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }
    &::-moz-scrollbar-corner {
      background-color: transparent;
    }
  }

  .arrow-up {
    margin-top: 3px;
    border-bottom: 10px solid $color-light-dark-grey !important;
  }
  .three-lines {
    -webkit-line-clamp: 3;
  }
}
