.single-record-view {

  #associatedCards {
    .widget-tile {
      display: inline-block;
      width: 100%;
      margin: 0 0 10px 0;
    }
  }

  .mastercards {
    padding-right: 20px;
    display: flex;

    .mastercard-details {
      md-divider {
        margin: 10px 0;
      }

      h3 {
        margin-top: 0;
      }

      .site-links {
        float: right;
      }
    }

    .mastercard-details {
      flex: 1;
      padding: 20px 0 0 20px;
    }

    .mastercard-additional-info {
      display: flex;
      width: 40%;
      max-width: 600px;

      .mastercard-findings {
        max-width: 300px;
        width: 50%;
        padding: 20px 0 0 20px;
      }
      .mastercard-notes {
        max-width: 300px;
        width: 50%;
        padding: 20px 0 0 20px;
      }

      @media screen and (max-width:12800px) {
        //flex-wrap: wrap;
        flex-direction: column;
        width: 25%;
        max-width: 300px;
        .mastercard-findings, .mastercard-notes {
          width: 100%;
        }
      }
    }

    @media screen and (max-width:1024px) {
      flex-wrap: wrap;
      .mastercard-additional-info {
        min-width: 100%;
        flex: 1;
        flex-direction: row;
        .mastercard-findings, .mastercard-notes {
          min-width: 50%;
        }
      } 
    }
    
    // >div {
    //   padding: 20px 0 0 20px;
    // }
    // .widget-tile {
    //   margin: 20px;
    // }
  }
  .tile-container {
    padding: 20px;
  }
}