.widget-value-grid {
  > h5 {
    font-size: 16px;
    font-weight: 600;
  }

  .widget-value-grid-contents {
    display: flex;
    flex-wrap: wrap;

    > * {
      flex-grow: 1;
      flex-shrink: 1;
    }
  }

  .widget-value-grid-elements.layout-single-row .widget-value-grid-contents {
    flex-wrap: nowrap;
  }


  .widget-value-grid-elements.layout-multiple-rows .widget-value-grid-contents {
    margin-bottom: -15px;
    
    > * {
      width: calc(50% - 15px);
      max-width: calc(50% - 15px);
      margin-left: 15px;
      margin-bottom: 15px;

      @media (min-width: 1280px) {
        width: calc(33% - 15px);
        max-width: calc(33% - 15px);
      }
      @media print {
        width: calc(33% - 15px);
        max-width: calc(33% - 15px);
      }
    }
  }
}