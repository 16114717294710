@use '../../../public/scss/colors';

:has(.admin) .json-editor {
  width: 100%;
  color: $color-black !important;
  .card {
    color: inherit;
    background-color: $color-white !important;
  }

  .card-body {
    padding: .5rem;
  }
}

.json-editor {
  .card {
    color: inherit;
    background-color: inherit !important;
    border: 1px solid rgba($color-white, .3);
  }
}