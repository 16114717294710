.tile-valuation-reconciliation {
  .widget-label-value-medium {
    text-align: left;
  }
  .widget-split-columns.with-padding {
    .widget-split-column:last-child {
      padding-left: 0;
      overflow: hidden;
    }
  }
  .chart {
    height: 380px;
    width: 270px;
    position: relative;
    margin: auto;
  }

  svg {
    fill: white;
    line {
      stroke: white;
    }
    .label, .min-label, .max-label {
      font-size: 12px;
      font-weight: 400;
    }
  }
}