.widget-value-group {
  margin-bottom: 15px;

  h6 {
    margin-bottom: 3px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}