.widget-more {
  .moreContent {
    display: none;

  }
  &.isShowing {
    .moreContent {
      display: block;
    }
    .moreLabel {
      display: none;
    }
  }
}