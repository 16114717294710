@use "sass:math";

$text-color: #646464 !default; 
$text-underline-color: $text-color !default;
$border-color: rgba(0,0,0,0.1) !default;
$even-color: #f5f5f5 !default;
$odd-color: #ffffff !default; 
$cell-hover-color: #eee !default;
$chart-color: $odd-color !default;
$chart-border-color: #ccc !default;
$font: sans-serif !default;
$number-cell-font: sans-serif !default;
$number-cell-font-size: 12px !default;
$font-size: 12px !default;
$base-row-height: 37px !default; 
$base-row-padding: 10px !default;
$table-border-color: #e0e0e0 !default;
$table-toggle-color: #cccccc !default;
$header-color: #666666 !default; 
$header-background: #e0e0e0 !default;
$aggregation-row-header-background: #e0e0e0 !default;
$aggregation-row-header-color: #000 !default;
$shadow-color: rgba(0,0,0,0.07) !default;
$shadow-offset: 2px !default;
$shadow-spread: 4px !default;
$totals-background-color: #fff !default;
$totals-font-weight: bold !default;
$header-background-hover: tint-color($header-background, 15%) !default;
$scroller-width: 10px !default;
$scroller-background-color: #e0e0e0 !default;
$scroller-grip-color: #999 !default;
$hover-background-color: #fff !default;
$hover-text-color: #333 !default;
$chart-drop-color: $header-background !default;
$histogram-box-and-whiskers: #0054a7 !default;
$histogram-box-and-whiskers-outer-bound:#468ea8 !default;
$histogram-box-and-whiskers-na: #847da8 !default;

$config-background: #fff !default;
$config-foreground: #333 !default;
$config-border: #aaa !default;
$context-background: #fff !default;
$context-foreground: #666 !default;
$context-border: #aaa !default;

$column-choices-checkmark-color: #fff !default;
$column-choices-checkmark-background: #3ec027 !default;


.gird-grid {
  position: relative;
  overflow: hidden;
  font-family: $font;
  user-select: none;

  

  &.mid-drag, &.mid-hover {
    .cell:not(.dragging) {
      transition: left 0.1s linear;
    }
    .table-row {
      transition: top 0.1s linear;
    }
    .pane {
      transition: width 0.1s linear;
    }
  }

  .scroller-vertical {
    width: $scroller-width;
    right: 1px;
    top: 0;
    bottom: 1px;
    border-bottom: $scroller-width solid $header-background;
    
    .scroller-grip {
      width: $scroller-width;
      height: $scroller-width * 5;
    }
  }

  .scroller-horizontal {
    height: $scroller-width;

    
    right: 1px;
    left: 0;
    bottom: 1px;
    border-right: $scroller-width solid $header-background;
    
    .scroller-grip {
      width: $scroller-width * 5;
      height: $scroller-width;
    }
  }

  .scroller-horizontal, .scroller-vertical {
    &.scroller-full {
      display: none;
    }
    z-index: 1990;
    box-shadow: 0 0 3px rgba(0,0,0,0.2);
    position: absolute;
    background: $even-color;

    .scroller-wrapper {
      width: 100%;
      height: 100%;
      background: $scroller-background-color;
      
      .scroller-grip {
        position: absolute;
        background: $scroller-grip-color;
      }
    }
  }

  td{
    text-align:left
  }
  th{
    text-align:left
  }

  .grip {
    position: absolute;
    z-index: 1000;
    top: -1px;
    bottom: -1px;
    width: $base-row-padding;
    background: #000;
    opacity: 0.1;
    right: 0;
    cursor: ew-resize;
  }

  .pane {
    box-sizing: border-box;
    position: absolute;
    height: 100%;

    &.left-pane {
      width: calc(50% - 1px);
      border: 1px solid $border-color;
      border-right: 0;
      .scroll-container, .outer-sticky-container {
        left: -1px;
        right: -1px;
      }

      .table-row.table-row-expanded, .table-header, .totals-row {
        box-shadow: $shadow-offset $shadow-offset $shadow-spread $shadow-color;
      }
      .table-header {
        border-left: 1px solid rgba(0,0,0,0.1);
      }

      .table-row .cell.cell-text, .table-row .cell.cell-range {
        width: 100% !important;
      }
    }
    &.right-pane {
      width: calc(50% + 1px);
      right: 0;
      border: 1px solid $border-color;
      border-left: 0;

      // .scroll-container {
      //   overflow-x: scroll;
      // }
      .table-row, .totals-row, .table-header {
        border-left: 0;
      }

      .table-row.table-row-expanded, .table-header, .totals-row {
        box-shadow: -$shadow-offset $shadow-offset $shadow-spread $shadow-color;
      }
    }
    &.full-pane {
      left: -1px;
      right: 0;
      border: 1px solid $border-color;
    }

    .scroll-container {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      overflow: hidden;

      @media (pointer: coarse) {
        overflow: scroll;

        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
      }
    }

    .grid-container {
      position: relative;
    }

    &.scroll-pane {
      width: 100%;
      left: 0;
      .scroll-container {
        overflow: scroll;

        &::-webkit-scrollbar {
          width: 0px;
          height: 0px;
        }
        &::-webkit-scrollbar-thumb {
          
        }
        
      }
      z-index: 2000;
    }

    .outer-sticky-container {
      overflow: hidden;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0; // this messes up non-retina chrome for some reason... we have to explicitly set sticky container height... blah.
    }
  }

  .cell {
    position: absolute;
    box-sizing: border-box;
    display: inline-block;
    height: $base-row-height;
    border-right: 1px solid rgba(0,0,0,0.1);

    padding: $base-row-padding;
    padding-bottom: $base-row-padding - 1;
    user-select: all;

    &.touchable {
      cursor: pointer;
      &:hover, &.active {
        background: $cell-hover-color !important;
      }
    }

    &.cell-number {
      text-align: right;
      font-family: $number-cell-font;
      font-size: $number-cell-font-size; 
    }
    &.cell-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &.cell-range {
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &.cell-link, &.cell-link a {
      text-decoration: underline;
      text-decoration-color: $text-underline-color;
      color: $text-color;
      cursor: pointer;
    }
    &.spark-cell {
      padding: 0 5px;
    }

    &.dragging {
      box-shadow: 0 0 3px rgba(0,0,0,0.2);
      z-index: 100;
    }

    &.spark-histogram-cell {
      rect {
        fill: rgba(255,255,255, 0.75);
      }
    }

    .sub-cell-2 {
      &.comparison-only {
        opacity: 0.5;
      }
      $comparison-cell-width: 45px;
      position: absolute;
      top: 0;
      bottom: 0;
      padding: $base-row-padding;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      box-sizing: content-box;
      &.sub-cell-2-1 {
        left: 0;
        width: calc(100% - #{$comparison-cell-width + $base-row-padding*4.5});
      }
      &.sub-cell-2-2 {
        padding: $base-row-padding math.div($base-row-padding, 2);
        padding-right: $base-row-padding*2;
        right: 0;
        background: rgba(0,0,0,0.3);
        width: $comparison-cell-width;
        font-size: $number-cell-font-size - 2px;

        &.negative {
          color: rgb(255,85,85);
          &:before {
            content: "↓";
            position: absolute;
            font-size: $number-cell-font-size + 3px;
            right: math.div($base-row-padding,2);
          }
          &.negative-1 { color: rgb(255,240,240); }
          &.negative-2 { color: rgb(255,215,215); }
          &.negative-3 { color: rgb(255,185,185); }
          &.negative-4 { color: rgb(255,165,165); }
          &.negative-5 { color: rgb(255,145,145); }
          &.negative-6 { color: rgb(255,125,125); }
          &.negative-7 { color: rgb(255,105,105); }
        }

        &.positive {
          color: rgb(85,255,85);
          &:before {
            content: "↑";
            position: absolute;
            font-size: 15px;
            right: math.div($base-row-padding, 2);
          }
          &.positive-1 { color: rgb(240,255,240); }
          &.positive-2 { color: rgb(215,255,215); }
          &.positive-3 { color: rgb(185,255,185); }
          &.positive-4 { color: rgb(165,255,165); }
          &.positive-5 { color: rgb(145,255,145); }
          &.positive-6 { color: rgb(125,255,125); }
          &.positive-7 { color: rgb(105,255,105); }
        }
      }
    }

    &.spark-graph-cell, &.spark-histogram-cell {
      padding: math.div($base-row-padding, 2) $base-row-padding;
      text-align: left;
      display: flex;

      .svgContainer {
        flex: 1;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }



      svg, .spark-box-and-whiskers, .spark-histogram {
        height: $base-row-height - ($base-row-padding);
        flex: 1;
        min-width: 70px;
        display: flex;
        position: relative;
        align-items:flex-end;

        .histogram-bar-container{
          height: 100%;
          display: flex;
          align-items: flex-end;
          width: auto;
          flex: 1;


          &:hover .histogram-bar {
            opacity: 1;
            background:tint-color($histogram-box-and-whiskers,40%) !important;
            &.outer-bound {
              background: tint-color($histogram-box-and-whiskers-outer-bound,40%) !important;
            }
            &.NA {
              background: tint-color($histogram-box-and-whiskers-na,40%) !important;
            }
          }
        }
        .histogram-bar{
          background: $histogram-box-and-whiskers;
          &.outer-bound {
            background: $histogram-box-and-whiskers-outer-bound;
          } &.NA {
            background: $histogram-box-and-whiskers-na;
          }
          bottom: math.div($base-row-padding, 2);
          z-index: 2;
          opacity:1;
          min-width: 100%;
          box-sizing: border-box;
          outline: 1px solid shade-color($histogram-box-and-whiskers, 20%);
        }

        .spark-box-and-whiskers-box {
          background: $histogram-box-and-whiskers;
          border: 1px solid shade-color($histogram-box-and-whiskers, 20%);
          top: math.div($base-row-padding,2);
          bottom: math.div($base-row-padding,2);
          position: absolute;
          z-index: 2;
          &.narrow-box {
            background: tint-color($histogram-box-and-whiskers, 5%);
            min-width: 3px;
          }
        }
        .spark-box-and-whiskers-whisker {
          position: absolute;
          border-top: 2px solid tint-color($histogram-box-and-whiskers, 5%);
          box-shadow: 0 0 2px rgba(0,0,0,0.5);
          top: 50%;
        }
        .spark-box-and-whiskers-median {
          top: math.div($base-row-padding,2);
          bottom: math.div($base-row-padding,2);
          position: absolute;
          border-left: 2px solid rgba(255,255,255,0.3);
          z-index: 3;
        }
        .spark-box-and-whiskers-mean {
          top: 50%;
          margin-top: math.div(-$base-row-padding,4);
          height: math.div($base-row-padding,2);
          width: math.div($base-row-padding,2);
          background: rgba(255,255,255,0.3);
          position: absolute;
          border-radius: math.div($base-row-padding,2);
          z-index: 3;
        }
      }
      .value {
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: math.div($base-row-padding,2);
        padding-left: math.div($base-row-padding,2);

        .spacer {
          opacity: 0;
          height: 0;
          user-select: none;
        }
      } 
    }


    .girdgrid-spark-bar-with-comparison {
      .girdgrid-spark-bar:first-child {
        margin-top: $base-row-height * 0.2;
        height: $base-row-height * 0.40;
        .girdgrid-spark-bar-item {
          height: $base-row-height * 0.40;
        }
      }
      .girdgrid-spark-bar:last-child {
        background: #000;
        height: $base-row-height * 0.20;
        .girdgrid-spark-bar-item {
          height: $base-row-height * 0.20;
        }
      }
    }

    .girdgrid-spark-bar {
      width: 100%;
      height: $base-row-height;
      display: flex;
      .girdgrid-spark-bar-item {
        height: $base-row-height;
        &.girdgrid-spark-bar-item-with-text {
          width: 100%;
          padding: $base-row-padding;
          box-sizing: border-box;
          font-family: $font;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  .sticky-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    min-height: 1px;
    z-index: 1000;
  }

  .table-element {
    position: absolute;
    right: 0;
  }

  .chart-element {
    background-color: $chart-color;
    padding: $base-row-padding $base-row-padding*2;
    box-sizing: border-box;
    border: 1px solid $chart-border-color;
    border-left: 0;
    border-bottom: 0;
    //margin-top: 1px;

    &.last-chart {
      padding-right: 15px + $base-row-padding*2;
      .chart-element-config-button {
        right: math.div($base-row-padding,2) + 15;
      }
    }

    canvas {
      width: 100%;
      height: 100%;
    }

    .chart-element-grip {
      color: $text-color;
      position: absolute;
      left: math.div($base-row-padding,2);
      top: math.div($base-row-padding,2);
      opacity: 0.5;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }

    .chart-element-config-button {
      color: $text-color;
      position: absolute;
      right: math.div($base-row-padding,2);
      top: math.div($base-row-padding,2);
      opacity: 0.5;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }

    .vertical-grip {
      position: absolute;
      height: 10px;
      bottom: 0;
      left: 0;
      right: 0;
      cursor: ns-resize;
    }

    .required-capabilities-label,
    .required-capabilities-grouping,
    .required-capabilities-number_label,
    .required-capabilities-aggregation,
    .required-capabilities-number_aggregation,
    .required-capabilities-normalized_aggregation,
    .required-capabilities-summing_aggregation,
    .required-capabilities-time_series {
      display: none;
    }

    &.capabilities-label .required-capabilities-label { display: block; }
    &.capabilities-grouping .required-capabilities-grouping { display: block; }
    &.capabilities-number_label .required-capabilities-number_label { display: block; }
    &.capabilities-aggregation .required-capabilities-aggregation { display: block; }
    &.capabilities-number_aggregation .required-capabilities-number_aggregation { display: block; }
    &.capabilities-normalized_aggregation .required-capabilities-normalized_aggregation { display: block; }
    &.capabilities-summing_aggregation .required-capabilities-summing_aggregation { display: block; }
    &.capabilities-time_series .required-capabilities-time_series { display: block; }

    .chart-element-drag-targets {
      display: none;
      background: rgba(0,0,0,0.25);
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;

      .chart-element-config {
        margin: 20px;
        border: 5px dashed $text-color;
        color: $text-color;
        font-size: 16px;
        text-align: center;
        flex: 1;
        padding: 20px;
        opacity: 0.7;
        word-break: break-word;

        .config-label {
          font-size: 16px;
          margin-bottom: 10px;
        }
        .config-value {
          font-size: 12px;
        }

        &:hover {
          opacity: 1;
          background: rgba(255,255,255,0.5);
        }
      }
    }

    &.drag-bottom {
      border-bottom: $base-row-height solid $chart-drop-color;
    }

    &.drag-right {
      border-right: $base-row-height solid $chart-drop-color;
    }

    &.drag-left {
      border-left: $base-row-height solid $chart-drop-color;
    }

    &.mid-drag:not(.drag-bottom):not(.drag-left):not(.drag-right) {
      transition: left linear 0.2s, width linear 0.2s;

      .chart-element-drag-targets {
       display: flex !important;
      }
    }

    .chart-error {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;

      .error {
        color: white;
      }
    }
  }

  .table-row-toggle {
    left: 0;
    top: 0;
    position: absolute;
    display: inline-block;
    z-index: 2;
    width: $base-row-height;
    height: $base-row-height;
    line-height: $base-row-height;
    font-size: $font-size;
    text-align: center;
    color: $table-toggle-color;
    transition: transform 0.1s;
    transform: rotate(-90deg);
    cursor: pointer;
    &.expanded {
      transform: rotate(0deg);
    }
  }


  .aggregation-row-header {
    background: $aggregation-row-header-background;
    color: $aggregation-row-header-color;
    height: $base-row-height;
    font-size: 12px;
    line-height: $base-row-height;
    padding: 0;
    border: 1px solid $aggregation-row-header-background;
    border-top: 0;
    border-bottom: 0;
    background-image: repeating-linear-gradient(45deg, transparent, transparent 4px, rgba(255,255,255,.02) 4px, rgba(255,255,255,.02) 8px);
    font-family: $font;


    .aggregation-row-header-info {
      display: flex;
      position: relative;
      margin-right: 10px;

      .row-header-grip {
        display: inline-block;
        cursor: grab;
        width: $base-row-height;
        text-align: center;
        margin-right: -$base-row-padding;
      }

      .aggregation-row-header-label {
        padding: 0 $base-row-padding;
        margin-right: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        flex: 1;
        cursor: pointer;

        position: relative;
        .cog {
          position: absolute;
          right: 2px;
          display: inline;
          opacity: 0.3;
        }

        &:hover {
          background: rgba(255,255,255,0.1);
          .cog {
            opacity: 1;
          }
        }
      }

      .sort-indicator {
        position: relative;
        width: 20px;
        cursor: pointer;

        &:hover {
          background: rgba(255,255,255,0.1);
        }

        .sort-indicator-up, .sort-indicator-down {
          position: absolute;
          font-size: 10px;
          right: 5px;
          opacity: 0.3;
          line-height:  math.div(($base-row-height - 5), 2);
          height: math.div(($base-row-height - 5), 2);
        }

        .sort-indicator-up {
          top: 3px;
        }

        .sort-indicator-down {
          bottom: 3px;
        }
      }

      &.sort-up .sort-indicator-up {
        opacity: 1;
      }

      &.sort-down .sort-indicator-down {
        opacity: 1;
      }
    }
  }

  .add-aggregation {
    position: absolute;
    right: 0;
    top: 0;
    width:  math.div($base-row-height, 2);
    background: $header-background;
    color: $header-color;
    border-left: 1px solid rgba(0,0,0,0.2);
    cursor: pointer;

    &:hover {
      background: $header-background-hover;
    }

    i {
      font-style: normal;
      text-align: center;
      font-size:  math.div($base-row-height, 2);
      font-weight: bold;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &.left-add-aggregation {
      left: 0;
      right: auto;
      border-left: 0;
      border-right: 1px solid rgba(0,0,0,0.2);
    }
  }

  .table-header {
    font-weight: 600;
    color: $header-color;
    background: $header-background;
    height: $base-row-height + 1px;
    box-sizing: border-box;
    font-size: $font-size;
    line-height: $base-row-height - $base-row-padding - $base-row-padding;

    .header-wrapper {
      height: 100%;
    }

    .cell-micro-header {
      width: auto;
      padding-left: 4px !important;
      padding-right: 4px !important;
      margin-right: ($base-row-padding - 4px) !important;
      margin-left: ($base-row-padding - 4px) !important;
      position: static;

      &:not(:last-child):after {
        content: '▼';
        position: absolute;
        margin-left: 6px;
        transform: rotate(-90deg);
        font-size: 8px;
        opacity: 0.3;
      }
      &:hover {

      }


      &.drop-target {
        border: 2px dashed $text-color;
        margin-top: -2px;
        opacity: 0.5;
        height: 27px;
        margin-bottom: -9px;
        &:after {
          content: '';
        }
      }

    }

    .cell-header {
      background: $header-background;
      padding-left: $base-row-height;
      padding-top: math.div($base-row-padding,2);
      padding-bottom: math.div($base-row-padding,2);
      cursor: pointer;
      user-select: none;
      font-family: $font;

      &.cell-number {
        font-size: $font-size;
      }
      
      &.fixed-position {
        cursor: default;
      }

      &.cell-text {
        padding-left: $base-row-padding;
        padding-right: $base-row-height;
      }

      &:hover {
        background: $header-background-hover;

        .cell-header-grip {
          opacity: 1;
        }
      }

      .sort-controls {
        position: absolute;
        bottom: math.div($base-row-padding,2);
        opacity: 0;
      }

      &.sort-up, &.sort-down {
        .sort-controls {
          opacity: 0.5;
        }
      }
      &.sort-up {
        .sort-controls {
          transform: rotate(180deg);
        }
      }

      .cell-header-label {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .cell-header-grip {
        position: absolute;
        width: 0;
        border-right: 6px solid rgba(128,128,128,0.5);
        border-left: 15px solid rgba(128,128,128,0);
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        cursor: ew-resize;
      }

      &.measuring {
        opacity: 0;
        .cell-header-label {
          display: inline-block;
          max-width: 100%;
        }
      }
    }
  }

  .table-row, .totals-row {
    font-weight: 400;
    color: $text-color;
    // border: 1px solid $table-border-color;
    height: $base-row-height + 1px;
    
    box-sizing: border-box;
    font-size: $font-size;
    line-height: $base-row-height - $base-row-padding - $base-row-padding;
    //overflow: hidden;
    //transition: box-shadow 0.2s linear;

    &.has-toggle {
      padding-left: $base-row-height - $base-row-padding;
    }

    &.even-row {
      background-color: $even-color;
      .cell {
        background-color: $even-color;
      }
    }
    &.odd-row {
      background-color: $odd-color;
      .cell {
        background-color: $odd-color;
      }
    }
    &.table-row-expanded {
      box-shadow: 0 $shadow-offset $shadow-spread $shadow-color;
    }

    .table-row-loading {
      padding: $base-row-padding;
      opacity: 0.5;
    }

    .table-row-contents {
      width: 100%;
      display: inline-block;
      position: relative;
      height: 100%;
    }

    .table-row-drop-target {
      position: absolute;
      top: $base-row-height;
      width: calc(100% + 28px);
      height: $base-row-height;
      background: $header-background;
      left: -28px;
    }
  }

  .totals-row {
    background: $totals-background-color;
    font-weight: $totals-font-weight;
    .cell {
      background: $totals-background-color;
      border-right: 0;
    }
  }
}

.girdgrid-hover {
  font-family: $font;
  position: absolute;
  z-index: 10000;
  background: $hover-background-color;
  padding: $base-row-padding;
  color: $hover-text-color;
  border: 1px solid $table-border-color;
  font-size: $font-size;
  max-width: 350px;

  h3 {
    font-weight: 500;
    margin: 5px 0;
    font-size: $font-size;
  }
  h4 {
    font-weight: 300;
    margin: 5px 0;
    font-size: $font-size;
  }

  .girdgrid-spark-comparison {
    text-align: right;
    padding: 0 $base-row-padding*2;
    h4 {
      margin-top: 3px;
      margin-bottom: 10px;
    }
    h5 {
      margin-top: 3px;
      margin-bottom: 3px;
    }
  }

  .girdgrid-spark-bar-tooltip-row {
    display: flex;
    margin: 3px 0;
    line-height: 20px;

    .girdgrid-spark-bar-tooltip-label {
      flex: 1;
    }
    &.focused-key {
      .girdgrid-spark-bar-tooltip-label {
        font-weight: bold;
      }
    }
    .girdgrid-spark-bar-tooltip-percent {
      width: 80px;
      font-weight: bold;
      text-align: right;
    }
    .girdgrid-spark-bar-tooltip-color {
      margin-right: 15px;
      width: 20px;
      height: 20px;
    }
  }
}

.gird-grid-config-screen {
  z-index: 9999;
  background: rgba(0,0,0,0.75);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.gird-grid-config {
  z-index: 10000;
  position: absolute;
  background: $config-background;
  color: $config-foreground;
  border: 1px solid $config-border;
  box-shadow: 1px 2px 10px rgba(0,0,0,0.5);
  font-family: $font;
  display: flex;
  flex-direction: column;
  font-size: $font-size;

  &.girdgrid-context-menu {
    background: $context-background;
    color: $context-foreground;
    border: 1px solid $context-border;
    .girdgrid-context-menu-item {
      padding: math.div($base-row-padding,2) $base-row-padding;
      border-bottom: 1px solid $context-border;
      cursor: pointer;
      position: relative;

      &:hover {
        background: shade-color($context-background, 5%);
      }

      &:last-child {
        border-bottom: 0;
      }

      .girdgrid-context-menu-expand {
        position: absolute;
        right: math.div($base-row-padding,2);
        top: math.div($base-row-padding,2);
        transform: rotate(270deg);
      }
    }
  }

  .input-filter {
    input {
      color: $config-foreground;
      background: tint-color($config-background, 10%);
      padding: $base-row-padding;
      width: 100%;
      border: 0;
      font-size: $font-size;
      outline: none;

      &::placeholder {
        color: shade-color($config-foreground, 30%);
      }
    }
    border-bottom: 1px solid $config-border;
  }

  .column-choices-wrapper {
    flex: 1;
    overflow: auto;

    .column-choice {
      padding: $base-row-padding;
      border-bottom: 1px solid $config-border;
      cursor: pointer;
      &:hover {
        background: shade-color($config-background, 5%);
      }

      &.selected {
        background: $odd-color;
        
      }
      &.active{
        position: relative;
        &:after{
          content:' ✔';
          color: $column-choices-checkmark-color;
          background: $column-choices-checkmark-background;
          border-radius: 50px;
          position: absolute;
          right: 7px;
          width: 20px;
          height: 20px;
          text-align: center;
          line-height: 20px;

        }
      }
    }

    .too-many {
      padding: $base-row-padding;
      opacity: 0.5;
    }
  }

  &.chart-config {
    
    $chart-config-padding: 15px;

    .chart-outlet {
      margin: $chart-config-padding;
      padding: $chart-config-padding;
      min-height: $base-row-height * 4;
      border: 1px solid shade-color($config-background, 20%);
      background: $chart-color;
      flex: 1;
    }

    .chart-outlet {
      height: inherit;

      .chart-error {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;

        .error {
          color: white;
        }
      }
    }

    .chart-config-close {
      position: absolute;
      font-size: $font-size*2;
      right: $chart-config-padding;
      top: math.div($chart-config-padding,2);
      transform: rotate(45deg);
      cursor: pointer;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }

    .chart-options {
      padding-top: $chart-config-padding*2;
      .chart-config-input {
        display: flex;
        padding: $chart-config-padding;
        padding-bottom: 0;
        font-size: $font-size;
        label {
          flex: 1;
          line-height: $font-size + $chart-config-padding*1.5;
          text-align: right;
          padding-right: $chart-config-padding;
        }
        .chart-config-dropdown {
          width: 50%;
          max-width: 400px;
          border: 1px solid shade-color($config-background, 20%);
          background: shade-color($config-background, 10%);
          padding: math.div($chart-config-padding,2);
          position: relative;
          cursor: pointer;
          &:after {
            content: '▼';
            opacity: 0.5;
            right: math.div($chart-config-padding,2);
            top: math.div($chart-config-padding,2);
            position: absolute;
          }          
        }
      }
    }
  }
}
