.dashboard-widget-pie {
  .chart-holder {
    position: relative;
    width: 100%;
    height: 230px;
    display: block;
    canvas {
      
    }
  }
}