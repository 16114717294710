.tile-property-description {
  p {
    font-size: 14px !important
  }
  .flag-tag {
    word-break: break-word;
    hyphens: auto;
    font-size: 18px;
    display: flex;
    padding-right: 20px;
    .thumb {
      position: relative;
      top: -3px;
      margin-right: 4px;
    }
    span {
      line-height: 4em;
      font-size: 14px;
      padding-left: 12px ;
    }
  }
}