.tile-phone {
  background-color: green;
  .md-list {
    display: block;
  }
  .md-list-item {
    position: relative;
    padding: 0 16px;
    min-height: 48px;
    display: flex;
    align-items: center;
    height: auto;
  }
}