.bettery-layout {
  display: flex;
  overflow-x: hidden;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0 -10px;
  width: calc(100% + 20px); 

  .landing-area {
    display: none;
  }

  .bettery-layout-hidden {
    position: absolute;
    left: 0;
    right: 0;
  }

  .left-column {
    width: 50%;
    padding-right: 10px;
    >.bettery-layout-tile.bettery-adding-to-layout {
      transform: translateX(-110%);
    }
  }
  .right-column {
    width: 50%;
    padding-left: 10px;
    >.bettery-layout-tile.bettery-adding-to-layout {
      transform: translateX(110%);
    }
  }

  .left-column, .right-column {
    position: relative;
    
    > .bettery-layout-tile:not(.bettery-layout-hidden) {
      margin-bottom: 20px;
    }
    > .bettery-layout-tile.bettery-adding-to-layout {
      //opacity: 0;
    }
    > .bettery-layout-tile {
      //transition: opacity 0.4s linear, 
      transition: transform 0.5s ease-out;
    }
  }

  @media (max-width: 1023px) {
    .left-column {
      width: 100%;
      padding: 0;
    }
    .right-column {
      display: none;
    }
  }
  @media print {
    display: flex;
    
    .left-column {
      padding-right: 10px;
      width: 50%;
      display: block;
    }
    .right-column {
      padding-left: 10px;
      width: 50%;
      display: block;
    }
  }
}