.treemapcontainer {
  .cell rect {
    stroke: $color-black-bg;
    stroke-width: 2px;
  }
  .theTitle{
    fill: $color-dark-grey;
  }
  .theValue {
    fill: $color-dark-grey;
    font-weight: bold;
  }
  .tooltip { 
    top: -100000px;
    font-size: 13px;     
    text-align: left;         
    width: auto;                    
    height: 150px;                   
    padding: 10px;                  
    background: $color-blue-hover; 
    border: 0px;        
    border-radius: 8px;         
  } 
}