// within the global stacking context

$app-z:                       0;
$navbar-z:                    1;
$growl-z:                 11000;
$tooltip-z:               99999;

// within the $navbar-z stacking context

$search-icon-z:               2;
$input-icon-z:                3;

// within the $app-z stacking context

$grid-map-chart-z:            0;
$loading-icon-z:              1;
$livability-score-z:          1;
$livability-title-z:          1;
$record-tab-list-item-z:      1;
$grid-map-chart-title-z:      1;
$pagination-z:                2;
$scenario-table-contents-z:   2;
$record-tab-menu-z:           5;
$table-pinned-z:              6;
$table-pinned-top-z:          7;
$link-z:                      9;
$scenario-header-z:          10;
$scenario-header-top-z:      11;
$scenario-input-z:          100;
$fixed-comments-z:          100;
$nav-tabs-z:                500;
$leaflet-z:                 500;
$d3-tip:                    501;
$leaflet-overlay-pane-z:    601;
$comments-container-z:      801;
$open-menu-container-z:     999;
$leaflet-top-z:            1000;
$user-dropdown-z:          1000;
$locked-record-tabs-z:     2000;
$spinner-z:                9998;
$outer-breadcrumb-z:      10000;
$cycle-arrow-z:           10000;
$select-menu-container-z: 10187;
$drop-zone-close-z:       20000;
$leaflet-pseudo-full-z:   99999;



/* These z-indexes are set in javascript or html (within $app-z):

singlemap d3-tip:             2147483647;
vehicle ownership link img:         -500;
split column grip:                 20000;

*/
