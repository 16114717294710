
.explorer {
  $controlBarHeight: 50px;
  .path-editor-wrapper {
    .CodeMirror {
      height: calc(50vh - 80px - #{$controlBarHeight});
      .CodeMirror-linenumber {
        color: #555;
      }
    }
  }

  .control-bar {
    height: $controlBarHeight;
    background: rgba(255,255,255,0.1);
    text-align: right;
    padding: calc($controlBarHeight * 0.25/2);

    button {
      background: #221;
      height: $controlBarHeight * 0.75;
      margin-left: calc($controlBarHeight * 0.25/2);
      min-width: 80px;
      text-align: center;
    }
  }

  .api-inputs {
    height: calc(50vh - #{$controlBarHeight});
    display: flex;
    .api-input {
      width: 50%;
      padding: 20px;
    }
  }

  .api-outputs {
    background: #111;
    overflow: scroll;
    height: 50vh;
    padding: 30px 0;
    &.dirty {
      opacity: 0.2;
    }
  }

  .tree-node {
    font-family: Menlo,Monaco,Consolas,"Courier New",monospace;
    .tree-node-label {
      margin-right: 10px;
      color: #888;
      display: inline-block;
      max-width: 50vw;
      word-break: break-all;
      &:hover {
        cursor: pointer;
        color: #77f;
      }
      &:empty {
        display: none;
      }
      &:after {
        content: ":";
      }
    }

    &.tree-node-array {
      >.tree-node-label:after {
        content: ": [";
      }
    }
    &.tree-node-object {
      >.tree-node-label:after {
        content: ": {";
      }
    }

    .tree-node-value {
      color: #fff;
    }
    .tree-node-list {
      padding-left: 20px;
      padding-bottom: 10px;
    }
    &.tree-node-literal, &.tree-node-fn, &.tree-node-string {
      display: flex;
      .tree-node-value {
        flex: 1;
      }
    }
    &.tree-node-fn {
      >.tree-node-value {
        color: #aaa;
      }
    }
    &.tree-node-string {
      >.tree-node-value {
        &:before { content: "\""; color: '#aaa'; }
        &:after { content: "\""; color: '#aaa'; }
      }
    }
  }
}