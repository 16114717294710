.padding-0 {
  padding: 0;
}

.padding-5 {
  padding: 5px;
}

.padding-10 {
  padding: 10px;
}

.padding-20 {
  padding: 20px;
}

.padding-30 {
  padding: 30px;
}

.padding-15 {
  padding: 15px;
}
.padding-60 {
  padding: 60px;
}


.padding-100 {
  padding: 100px;
}

.padding-left-0 {
  padding-left: 0;
}
.padding-left-5 {
  padding-left: 5px;
}
.padding-left-10 {
  padding-left: 10px;
}

.padding-left-15 {
  padding-left: 15px;
}

.padding-left-20 {
  padding-left: 20px;
}

.padding-left-21 {
  padding-left: 21px;
}

.padding-left-25 {
  padding-left: 25px;
}

.padding-left-30 {
  padding-left: 30px;
}

.padding-left-35 {
  padding-left: 35px;
}

.padding-left-50 {
  padding-left: 50px;
}

.padding-right-0 {
  padding-right: 0;
}

.padding-right-5 {
  padding-right: 5px;
}

.padding-right-10 {
  padding-right: 10px;
}

.padding-right-20 {
  padding-right: 20px;
}

.padding-right-50 {
  padding-right: 50px;
}

.padding-top-0 {
  padding-top: 0 !important;
}

.padding-top-5 {
  padding-top: 5px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-top-15 {
  padding-top: 15px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-25 {
  padding-top: 25px;
}

.padding-top-30 {
  padding-top: 30px;
}

.padding-top-40 {
  padding-top: 40px;
}

.padding-top-50 {
  padding-top: 50px;
}
.padding-top-100 {
  padding-top: 100px;
}
.padding-top-120 {
  padding-top: 120px;
}

.padding-bottom-0 {
  padding-bottom: 0 !important;
}

.padding-bottom-5 {
  padding-bottom: 5px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}
