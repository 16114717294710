.tile-person-summary {
  img {
    width: 100%;
  }
  .behavioral {
    h6 {
      float: left;
      margin-right: 10px;
      width: 135px;
      font-weight: 600;
    }
    .widget-bar-meter {
      margin-bottom: 3px;
    }
  }
  .creditSnapshot {
    margin-top: 10px;
    label {
      font-weight: 600;
    }
    span {
      margin-left: 10px;
    }
    .fa-file-lines:hover {
      color: $blue;
    }
  }
  .visualData {
    .widget-split-columns.with-padding .widget-split-column:first-child {
      padding-right: 0;
    }    
    .widget-label-value-medium {
      min-width: 85px;
      margin-right: 10px;
    }
    .sparkRow {
      height: 45px;
      display: flex;
    }
    .chart {
      max-width: 120px;
      width: 100%;
      height: 24px;
    }
  }
}

