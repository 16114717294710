.tile-quarterly-records {
  .quarters, .years {
    list-style: none;
    display: flex;
    padding: 0;

    .quarter, .year {
      outline: none;
      flex: 1;
      text-align: center;
      background: #eee;
      border: 1px solid #999;
      border-left-width: 0;
      color: #666;
      cursor: pointer;
      padding: 10px;
      max-width: 100px;

      &.selected {
        background: #337ab7;
        color: #fff;
      }

      &.disabled {
        opacity: .5;
        cursor: inherit;
      }

      &:first-child {
        border-left-width: 1px;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
      &:last-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }
}