.margin-100 {
  margin: 100px;
}

.margin-50 {
  margin: 50px;
}

.margin-5 {
  margin: 5px;
}

.margin-10 {
  margin: 10px;
}


.margin-top-0 {
  margin-top: 0;
}
.margin-top-2 {
  margin-top: 2px;
}
.margin-top-5 {
  margin-top: 5px;
}
.margin-top-7 {
  margin-top: 7px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-25 {
  margin-top: 25px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-35 {
  margin-top: 35px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-60 {
  margin-top: 60px;
}

.margin-top-75 {
  margin-top: 75px;
}

.margin-top-80 {
  margin-top: 80px;
}

.margin-top-100 {
  margin-top: 100px;
}

.margin-top-110 {
  margin-top: 110px;
}

.margin-top-120 {
  margin-top: 120px;
}

// .margin-top-125 {
//   margin-top: 125px;
// }

.margin-top-140 {
  margin-top: 140px;
}

.margin-top-200 {
  margin-top: 200px;
}


// .margin-top-20-xs-max {
//   @media (max-width: $screen-xs-max) {
//     margin-top: 20px;
//   }
// }

// .margin-top-50-xs-max {
//   @media (max-width: $screen-xs-max) {
//     margin-top: 50px;
//   }
// }

.margin-0 {
  margin: 0;
}

.margin-20-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.margin-0-25 {
  margin: 0 25px;
}

.margin-0-35 {
  margin: 0 35px;
}

.margin-0-auto {
  margin: 0 auto;
}

.margin-left-minus-3 {
  margin-left: -3px;
}

.margin-left-minus-5 {
  margin-left: -5px;
}
.margin-left-2 {
  margin-left: 2px;
}

.margin-left-0 {
  margin-left: 0;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-10 {
  margin-left: 10px;
}
.margin-left-10-sm-min {
  margin-left: 10px;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-left-25 {
  margin-left: 25px;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-left-35 {
  margin-left: 35px;
}

.margin-left-50 {
  margin-left: 50px;
}

.margin-left-60 {
  margin-left: 60px;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-right-0 {
  margin-right: 0px;
}
.margin-right-0-important {
  margin-right: 0px !important;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-25 {
  margin-bottom: 25px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.margin-bottom-60 {
  margin-bottom: 60px;
}

.margin-bottom-80 {
  margin-bottom: 80px;
}

.margin-top-minus-50 {
  margin-top: -50px;
}

.margin-top-minus-60 {
  margin-top: -60px;
}

.margin-top-minus-70 {
  margin-top: -70px;
}

.margin-right-minus-5 {
  margin-right: -5px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-15 {
  margin-right: 15px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-50 {
  margin-right: 50px;
}

.margin-right-100 {
  margin-right: 100px;
}


/* end margins */
