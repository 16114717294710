.filters-widget {

  margin-left: 20px;
  line-height: 42px;
  margin-top: 20px;
  color: white;
  display: inline-block;

  > span {
    &:first-child {
      margin-left: 0;
    }
    margin-left: 5px;
  }
  .self-compare & {
    color: inherit;
    margin-top: 10px;
  }

  > span, .self-comparison > span {
    margin-left: 5px;
  }
  > span:first-child, .self-comparison > span:first-child {
    margin-left: 0;
  }
  .self-comparison-secondary {
    margin-left: 43px;
  }
  .action {
    margin-left: 5px;
  }
  .record-count {
    font-weight: bold;
  }
  .filter-action {
    display: inline-block;
    border-radius: 2px;
    margin-right: 8px;
    border: solid 1px rgba(128,128,128,0.5);
    &.btn {
      background: rgba(128,128,128,0.1);
      .self-compare & {
        background: rgba(51,122,183,0.8);
        opacity: 1;
      }
      &:hover {
        background: rgba(128,128,128,0.2);
        .self-compare & {
          background: rgba(51,122,183,1);
          color: #fff !important;
        }
      }
      font-weight: normal;
      color: #fff !important;
      border: none;
      height: 38px;
      opacity: 0.7;
      font-size: 14px;
      position: relative;
      top: -1px;
      &:hover {
        opacity: 1;
      }

    }
  }

  ul {
    display: inline;
    padding: 0;
    margin-left: 8px;
    .filter {
      display: inline-block;
      border-radius: 2px;
      margin: 0 4px 0 0;
      border: none;
      line-height: 38px;
      .description {
        background: rgba(128,128,128,0.1);
        padding: 0 8px 0px;
        display: inline-block;
        border-radius: 2px 0 0 2px;
        color: $color-blue;
        line-height: 38px;

        &:hover {
          background: rgba(128,128,128,0.2);
          cursor: pointer;
        }
      }
      .remove-filter {
        background: rgba(128,128,128,0.1);
        padding: 0 4px;
        display: inline-block;
        line-height: 36px;
        border-radius: 0 2px 2px 0; 
        border-left: 1px solid rgba(128,128,128,0.5);
        border-left: 1px solid rgba(256,256,256,0.2);
        line-height: 38px;
        &:hover {
          background: rgba(128,128,128,0.2);
          cursor: pointer;
        }
      }
      .logic {
        font-size: 11px;
        margin-left: 5px;
        color: $color-blue;
      }
      .parens {
        color: $color-blue;
      }
      &.no-click {
        .description {
          color: inherit;
          cursor: default;
          opacity: 0.5;
        }

      }

      i {
        font-size: 14px;
        opacity: 0.5;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}