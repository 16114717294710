
.grid-map-chart {
  width: 100%;
  height: 100%;
  z-index: $grid-map-chart-z;
  position: relative;

  .leaflet-control-fullscreen a {
    background:$color-white url("../../../assets/img/fullscreen_white.png") no-repeat 0 0;
    background-size: 30px 30px;
  }

  .leaflet-fullscreen-on .leaflet-control-fullscreen a {
    background-position: inherit;
  }

  .actual-map .leaflet-overlay-pane {
    svg:first-child {
      pointer-events: inherit;
    }
    svg:nth-child(2) {
      pointer-events: none;
    }
  }

  .leaflet-draw-tooltip {
    background: rgba(0,0,0,0.8);
    white-space: nowrap;
    padding: 5px;
    border-radius: 3px;
    font-size: 10px;
    color: #ddd;
    margin-top: 20px;
    margin-left: 20px;
  }
    
  .leaflet-touch {
    .leaflet-draw-toolbar .leaflet-draw-draw-rectangle {
      cursor: pointer;
      background-image: url(../../../assets/img/pencil_white.svg);
      background-position: center center;
      background-size: 20px 20px;
      &.leaflet-draw-toolbar-button-enabled {
        background-color: tint-color(#343b46, 60%);
      }
    }
    .leaflet-draw-toolbar .leaflet-draw-draw-polygon {
      cursor: pointer;
      background-image: url(../../../assets/img/free-draw-icon.svg);
      background-position: center center;
      background-size: 20px 20px;
      &.leaflet-draw-toolbar-button-enabled {
        background-color: tint-color(#343b46, 60%);
      }
    }
    .leaflet-draw-actions {
      margin-top: 10px;
      padding-inline-start: 23px;
      color: $color-white;
      border-radius: 3px;
      box-shadow: 0 0 0 2px rgba(0,0,0,0.2);
      background: #000;
      li a {
        text-transform: uppercase;
        color: $color-white;
        font-weight: 400;
      }
    }
  }
    
  .color-steps-legend, .clear-filter-legend {
    margin-left: 12px;
    background: #000;
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    box-shadow: 0 0 0 2px rgba(0,0,0,0.2);

    .color-step {
      display: flex;
      margin-bottom: 3px;
      &:last-child {
        margin-bottom: 0;
      }

      .color-step-color {
        width: 12px;
        height: 12px;
        border-radius: 6px;
        margin-right: 3px; 
      }
      .color-step-label {
        line-height: 14px;
      }
    }
  }

  .clear-filter-legend {
    cursor: pointer;
    text-transform: uppercase;
    .material-icons {
      font-size: 16px;
      vertical-align: -4px;
    }
  }

  .actual-map {
    background: #1a1c21;
    width: 100%;
    height: 100%;
    z-index: $grid-map-chart-z;
    min-height: 10px; // fix leaflet/canvas issues
    min-width: 10px; 

    .leaflet-control-layers {
      margin-top: 20px;
      a {
        background-color: #555;
      }
    }
    .leaflet-bar {
      a {
        background-color: #555;
        color: #fff;
      }
    }

    .leaflet-pane {
      canvas {
        height: auto;
        width: auto;
      }
    }

    .leaflet-control-attribution {
      font-size: 9px;
      opacity: 0.5;
      background: rgba(0, 0, 0, 0.7);
    }

    .leaflet-pane > svg {
      pointer-events: visible;
      cursor: pointer;
    }

    .d3-tip {
      background-color: #151515;
      &:after {
        color: #151515;
      }
      &:before {
        color: #151515;
      }
      z-index: $d3-tip;
      h4 {
        font-size: 20px;
        position: static;
        background: transparent;
        text-align: right;
        margin: auto;
      }
    }
  }

  h4 {
    position: absolute;
    top: 0;
    right: 0;
    z-index: $grid-map-chart-title-z;
    font-size: 11px;
    background: rgba(0,0,0,0.4);
    padding: 3px;
    text-align: right;
    color: #fff;
    margin: 0;
  }
}


.pivot {
  .full-filters-widget {
    display: inline-block;
  }
  .gird-grid-outer-container {
    padding: 20px;
  }

  /* columns */
  $columns-border: tint-color(#383c41, 10%);
  $columns-background: #383c41;
  $columns-color: #fff;
  $columns-shadow: rgba(255,255,255,0.1);


  .input-container {
    padding: 10px;
    position: relative;

    input { 
      background: tint-color($columns-background, 10%);
      border: 1px solid rgba(255,255,255,0.15);
      border-radius: 3px;
      color: $columns-color;
    }

    .searchicon {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }

  .config-option {

    &.undo-button {
      button {
        min-width: 30px;
        padding-right: 0;
        padding-right: 0;
      }
      i {
        opacity: 0.75 !important;
      }
    }

    .config-option-details {
      &.mid-drag {
        opacity: 0;
        right: -500px;
      }
      transition: opacity 0.2s linear, right 0.2s linear;
      position: absolute;
      right: 0;
      background: $columns-background;
      color: $columns-color;
      top: 0;
      right: 0;
      width: 350px;
      margin-top: 37px;
      border: 1px solid $columns-border;
      border-top: 0;
      font-weight: 400;
      max-height: calc(100vh - 92px);
      

      // this is needed for ipads... :(
      // min-height: calc(100vh - 92px);

      box-shadow: 0 0 6px $columns-shadow;

      display: flex;
      flex-direction: column;

      .config-options-details-scroll-container {
        flex: 1;
        overflow-y: scroll;
      }

      .preset {
        padding: 10px;
        border-bottom: 1px solid $columns-border;
        &:last-child {
          border-bottom: 0;
        }
        .material-icons {
          margin-right: 5px;
        }
        .removePreset {
          float: right;
          opacity: 0.5;
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
        }
      }

      .column-group {
        border-bottom: 1px solid $columns-border;

        .draggable-agg {
          font-size: 12px;
          padding: 3px 10px 3px 34px;

          &:last-child {
            padding-bottom: 7px;
          }

          .agg-title {
            background: rgba(128,128,128,0.5);
            padding: 3px 5px;
            border-radius: 3px;
          }
        } 

        .draggable-column {
          border-bottom: 0;

          span {
            text-transform: capitalize;
          }
        }
      }

      .draggable-column {
        display: flex;
        padding: 10px;
        padding-bottom: 4px;
        padding-left: 16px;
        border-bottom: 1px solid $columns-border;

        &:last-child {
          border-bottom: 0;
          padding-bottom: 10px;
        }

        &[draggable=true] {
          cursor: move;
        }
        &[draggable=false] {
          .grip {
            opacity: 0;
          }
          .edit-controls {
            display: none;
          }
        }

        .btn {
          margin-top: 10px;
          margin-right: 10px;
        }

        .grip {
          margin-right: 15px;
          opacity:0.5;
          margin-top: 3px;
        }

        .edit-controls {
          margin-top: -6px;
          margin-right: -6px;
          margin-bottom: -6px;

          i {
            padding: 7px;

            opacity: 0.5;
            cursor: pointer;
            &:hover {
              opacity: 1;
            }
          }
        }
      }

      .chart-config {
        border-bottom: 2px solid #50555c;
      }

    }
  }
}
.save-preset-dialog {
  label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
  }
  .radio label, .checkbox label {
    min-height: 20px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer;
  }
  .checkbox input[type=checkbox], .checkbox-inline input[type=checkbox] {
    position: absolute;
    margin-top: 4px 0 0;
    margin-left: -20px;
  }
  input[type=radio], input[type=checkbox] {
    margin: 4px 0 0;
    margin-top: 1px 0 0;
    line-height: normal;
  }
}
.save-filter-dialog {
  .save-filter-modal-body {
    min-height: 400px;
    &.dim {
      min-height: inherit;
    }
  }
  .btn-group {
    margin-top: 15px;
    margin-left: 25px;
    label {
      &.btn {
        height: inherit;
        padding: 10px 7px;
        &:first-child {
          border-radius: 3px 0 0 3px;
        }
        &:last-child {
          border-radius: 0 3px 3px 0;
        }
        opacity: 0.6;
        &.active, &:hover {
          opacity: 1;
        }
      }
    }
  }
  .dim {
    display: none;
  }
  .searchbox {
    input {
      color: $color-black;
      border: solid 1px #ccc;
    }
    .results {
      a {
        text-align: left;
      }
    }
  }
  .connectorLogic {
    border: 1px solid #ccc;
    text-align: center;
    width: 150px;
    margin: auto;
    font-size: .9em;
    label {
      margin-left: 5px;
      margin-top: 5px;
      margin-bottom: 5px;
      font-weight: 600;
      &:first-of-type {
        margin-right: 10px;
      }
    }
  }
}

.pivot-promo {
  background: #000;
  padding: 20px;
  text-align: center;
  min-height: calc(100vh - 97px);

  iframe {
    max-width: calc(100vw - 40px);
    max-height: calc(100vh - 250px);
  }
}

.processingModal .modal-content {
  color: $color-white;
  background-color: #343b46;
  .processing {
    margin-top: 1rem;
    margin-bottom: 1rem;   
  }
}

.copyModal .modal-content {
  background-color: #343b46;
  width: 600px;
  .modal-body {
    padding: 0;
  }
  .copyValues {
    max-height: 75vh;
    margin: 15px;
    .values {
      max-height: calc(75vh - 46px);
      overflow: auto;
      text-align: center;
      textarea {
        color: $color-white;
        background-color: #20232C;
        padding: 10px;
        height: 400px;
        width: 100%;
        font-family: monospace;
        font-size: 15px;
        line-height: 1.8em;
      }
    }
    .actions {
      margin-top: 10px;
    }
  }  
}

