#summarygraph {
  width: 100%;
  height: 100px;
  background: $color-grey-tile;
  color: $color-white;

  img.filter-white {
    filter: brightness(0) invert(1);
    margin: 0 5px 5px 0;
  }

  .gaugeViz {
    width: 90%;
  }

  .gauge_container {
    aspect-ratio: 40 / 24;
    max-height: 29px;
    object-fit: contain
  }

  .statline {
    height: 48px;
    margin-top: 19px;
  }
}
#summarygraph .capitalTitle {
  text-transform: capitalize;
}
#summarygraph .accountNumber {
  font-size: 10px;
}

#summarygraph .underTitle {
  font-size: 9px;
}
.creditSummaryClass .tooltip-inner {
  white-space: pre-line;
}

.paymentIcon svg, .paymentIcon img {
  height: 15px;
  width: 15px;
}
.paymentIcon .negative svg rect {
  fill: $color-red;
}
.paymentIcon .negative img {
  filter: brightness(0) saturate(100%) invert(33%) sepia(32%) saturate(1827%) hue-rotate(320deg) brightness(129%) contrast(104%);
}
.paymentIcon .positive svg path {
  fill: $color-green;
}
.paymentIcon .positive img {
  filter: brightness(0) saturate(100%) invert(52%) sepia(99%) saturate(375%) hue-rotate(118deg) brightness(92%) contrast(85%);
}
.paymentIcon .equal svg rect, .paymentIcon .equal img rect {
  fill: $color-yellow;
}
.paymentIcon .equal img {
  filter: brightness(0) saturate(100%) invert(84%) sepia(24%) saturate(4332%) hue-rotate(354deg) brightness(101%) contrast(101%);
}
.currentBalance {
  color: $color-blue;
  font-size: 20px;
  font-weight: 700;

  @media (max-width : 1200px) 
    {
      font-size:16px;
    }
}




