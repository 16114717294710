.widget-icon {
  position: relative;
  display: inline-block;
  height: 35px;
  width: 35px;

  img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto auto;
  }
  i {
    position: absolute;
    top: 0;
    right: 0;
  }

  &.phone-style {
    width: 60px;
    height: 60px;
    background: rgba(127,127,127, 0.2);
    border-radius: 10px;
    box-shadow: 0 2px 3px rgba(0,0,0,0.2);
    padding: 5px;
  }
}

.widget-icon-list {
  padding: 5px 0;
  .widget-icon {
    margin-right: 10px;
    margin-bottom: 5px;
  }
}