.collections-table {
  .sortorder {
    &:after {
      content: '\25b2';
    }
    &.reverse:after {
      content: '\25bc';
    }
  }
  .text-column {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  tr {
    cursor: default;
  }
}