
.widget-payscen-labels {
  position: sticky;
  left: 0;
  background-color: $mbs-backgroundgrey;
  z-index: 11;

  .header {
    background-color: $mbs-backgroundgrey;
    height: 35px;
    margin: 10px 0 0 0;
    position: sticky;
    top: 0;
  }
  .labels {
    font-size: 12px;
    font-weight: 700;
    color: #ccc;
    padding: 10px 0 25px;

    .summarySpeeds {
      min-height: $cashflow-summary-header-height;
      .row-label {
        height: $summary-speeds-height;
      }
    }

    .row-label {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 28px;
      border: solid 1px transparent;
      padding: 0 10px;
      &.highlight {
        background: rgba(255,255,255,0.1);
      }

      label {
        margin-bottom: 0;
        text-align: right;
      }
    }

    .input-section {
      margin-bottom: 9px;

      .view-all-actions {
        height: 22px;
      }
    }

    .tranche {
      margin-bottom: 9px;

      .spacer {
        height: 35px;
        margin-bottom: 11px;
      }

      // .deal-user & .spacer {
      //   height: 95px;
      // }
    }
  }
}

