$breadcrumbs-border: #ddd !default;
$breadcrumbs-background: #fff !default;
$breadcrumbs-color: #000 !default;
$breadcrumbs-shadow: rgba(0,0,0,0.1) !default;

.outer-breadcrumb {
  @media print {
    padding-top: 0 !important;
  }
  display: flex;
  left: 0;
  right: 0;
  top: $navbar-height;
  z-index: $outer-breadcrumb-z;
  background-color: $color-black-bg;
  border-bottom: 1px solid $color-white-1;
  margin-bottom: 0;
  outline: none;
  font-weight: 400;
  // position: sticky;
  // padding-top: 60px;

  .breadcrumb {
    background-color: $color-black-bg;
  }

  li + li:before {
    color: $color-light-grey;
  }

  .btn-default {
    background-color: $color-black-bg;
    color: $color-light-grey;
    &.active, &:active {
      color: $color-white !important; // wtf
    }
  }
  .breadcrumbs-extra {
    .breadcrumb-button {
      position: relative;
      display: inline-block;
      .breadcrumb-button-payload {
        z-index: $outer-breadcrumb-z;
        position: absolute;
        background: $color-black-bg;
        color: $color-white;
        top: 0;
        right: 0;
        width: 350px;
        margin-top: 37px;
        border: 1px solid rgba(255,255,255,0.3);
        font-weight: 400;
        overflow-y: scroll;
        max-height: calc(100vh - 92px);
        box-shadow: 0 0 6px $breadcrumbs-shadow;
        box-shadow-color: rgba(255,255,255,0.3);
      }
      >button {
        background: $color-black-bg;
        color: $color-white;
        border: 1px solid rgba(255,255,255,0.3);
        border-right: 0;
        border-radius: 0;
        border-top: 0;
        border-bottom: 0;
        border: 0;
        height: 38px;
        font-weight: 400;

        .fa, .fa-solid, .fa-regular, .material-icons {
          margin-right: 3px;
          opacity: 0.3;
        }

        &:hover {
          background: tint-color($color-black-bg, 5%);
          color: $color-white;
        }
        &:active, &.active {
          color: $color-white;
          background: tint-color($color-black-bg, 5%);
        }
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
}

.breadcrumb {
  flex: 1;
  outline: none;
  
  border-radius: 0;
  font-weight: 400;
  color: $color-blue;
  background-color: $color-black-bg;
  padding: 9px 20px;
  cursor: default;
  margin-bottom: 0;

  a {
    font-weight: 400;
  }
  
  @media print {
    position: static;
  }

  .filter {
    display: inline-block;
    background: rgba(128,128,128,0.1);
    font-size: 11px;
    padding: 2px 4px;
    border: 1px solid rgba(128,128,128,0.3);
    border-radius: 2px;
    margin: 0 4px;

    &.no-click {
      cursor: default;
      background: rgba(128,128,180,0.05);
      border: 1px solid rgba(128,128,180,0.1);

      &:hover {
        background: rgba(128,128,180,0.05);
        border: 1px solid rgba(128,128,180,0.1);
      }
    }

    &:first-of-type {
      margin-left: 15px;
    }

    &:hover {
      border-color: rgba(128,128,128,0.6);
      background: rgba(128,128,128,0.2);
    }

    i {
      font-size: 14px;
      margin-left: 3px;
      opacity: 0.2;
      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }
  }
}

// for some reason breadcrumbs exist twice... when printing
// we only want one.  remove this when fixed
@media print {
  .apply-background .breadcrumb {
    display: none;
  }
}

.breadcrumb-adjustment {   
  padding-top: 90px;    
  @media print {    
    display: none;
  }    
}
.breadcrumb-adjustment-record {
  padding-top: 0;     
}
.breadcrumb ul, .breadcrumb li, .breadcrumb span {
  display: inline;
  overflow-wrap: break-word;
}
.breadcrumb > li + li:before {
  content: "";
  display: none;
}
.breadcrumb > li:after {
  padding: 0 5px;
  color: $color-bluegrey;
  font-family: 'Font Awesome\ 6 Free';
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  font: var(--fa-font-solid);
  content: "\f054";
}
.breadcrumb > li:last-of-type:after {
  display: none;
} 
.breadcrumb li span:hover {
  border-bottom: 2px solid $color-blue;
}
.breadcrumb li {
  white-space: nowrap;
  display: inline-block;
}

.no-border {
  border-bottom: none !important;
}