$tab-padding: 15px;
$tab-height: 44px + $tab-padding;
$footer-height: 50px;
$links-height: 44px;
$modal-width: 80vw;
$modal-height: 80vh;
$aspect-ratio: calc(16 / 9);

.modal-dialog.modal-xl {
  width: $modal-width;
  min-width: 900px;
}
.how-to-guide.appModal {
  // colors 
  $active-tab-color: white;
  $nav-tabs-score: tint-color(black, 10%);
  $blue-color: #4EB6CD;
  $aqua-color: #202225;
  $grey-color: tint-color(#2c2f33, 5%);
  $medium-grey-color: #898EA0;
  $dark-grey-color: #23252A;
  $dark-grey-2-color: #2c2f33;
  $dark-grey-3-color: #292f37;
  $light-grey-color: #b3b3b3;

  .modal-content {
    height: $modal-height !important;
    min-width: $modal-width;
    background-color: $dark-grey-3-color;    
  }


  .aspect-container {
    width: $modal-width;
    height: calc(calc(1 / #{$aspect-ratio}) * #{$modal-width});
    max-width: calc(#{$aspect-ratio} * (#{$modal-height} - #{$footer-height} - #{$links-height} - #{$tab-height}));
    max-height: calc(#{$modal-height} - #{$footer-height} - #{$links-height} - #{$tab-height});
    margin: auto;
    position: absolute;
    top:0;
    bottom:0; 
    left:0;
    right:0;
  }
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .main {
    margin-top: $tab-padding;
    height: calc(100% - #{$tab-height} - #{$footer-height});
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;

    .media {
      flex-grow: 1;
      max-height: 100%;
      position: relative;
    }

    .links {
      height: $links-height;
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        &:first-child {
          margin-left: 0;
        }
        margin-left: 50px;
      }
    }
  }
  .footer {
    height: $footer-height;
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;

    .actions {
      align-self: center;
      width: 100%;
      justify-content: center;
    }
  }


  .nav-tabs {
    border-bottom-color: $nav-tabs-score;
    >li {
      &.active>a, &.focus>a {
        color: $active-tab-color !important;
        background-color: $grey-color !important;
        border: 1px solid $grey-color !important;
        border-bottom: 1px solid $blue-color !important;
        &:hover,&:focus {
          background-color: $grey-color !important;
          border: 1px solid $grey-color !important;
          border-bottom: 1px solid $blue-color !important;
        }
      }
      >a {
        &:hover, &:focus {
          border: 1px solid $medium-grey-color !important;
          border-bottom: 1px solid $aqua-color;
        }
        border: 1px solid $dark-grey-color !important;
        border-bottom: 1px solid $aqua-color;
        background-color: $dark-grey-2-color !important;
      }
    }
  }
  .btn-default {
    border: 1px solid $dark-grey-color;
    background-color: $dark-grey-color;
    color: white !important;

    &:hover {
      color: $light-grey-color !important;
    }
  }
  
}