.widget-split-value-and-details {
  display: flex;

  >.widget-details > *, >.widget-value > * {
    display: block;
    width: 100%;
  }

  >.widget-value {
    flex: 1;
    max-width: 275px;
    width: 40%;
  }

  >.react-value {
    justify-content: center;
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  >.react-detail {
    justify-content: center;
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  >.widget-details {
    flex: 1;
    min-width: calc(60% - 30px);
  }
}