.input-icon > i {
  color: $color-grey-200;
  display: block;
  position: absolute;
  margin: 11px 2px 4px 10px;
  z-index: $input-icon-z;
  width: 16px;
  font-size: 16px;
  text-align: center;
}

.input-icon > .form-control {
  padding-left: 33px;
}

select {
  color: $color-grey;
  cursor:pointer;
  outline:0;
  -webkit-appearance: button;
  -webkit-padding-end: 25px;
  -moz-padding-end: 20px;
  -webkit-padding-start: 2px;
  -moz-padding-start: 2px;
  // background-image:url("../../assets/img/down-arrow.png");
  background-color: $color-white;
  background-position: center right;
  background-repeat: no-repeat;
  border: 1px solid $color-grey-170;
  -webkit-border-radius:  0;
  -moz-border-radius: 0;
  box-shadow: 0px 1px 3px $color-grey-170;
  font-size: inherit;
  margin: 0;
  overflow: hidden;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left:5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
}

select:after {
    content: "";
    position: absolute;
    right: 10px;
    top: 3px;
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid $color-grey-200;
}
/*target Internet Explorer 9 and Internet Explorer 10:*/

@media screen and (min-width: 0\0) {
  select {
    background: none;
    padding: 5px;
  }
}

.caret {
  border-top: 4px solid;
}

.select-text {
  font-weight: 700;
  font-size: 14px !important;
  font-family: 'Open Sans', sans-serif;
}

// .ui-select-choices {
//   padding-top: 0;
//   border-radius: 0;
// }

.ui-select-placeholder, .ui-select-match-text {
  font-weight: 700;
  padding-right: 20px;
  position: relative;
  top: 5px;
}

// .ui-select-search {
//   font-weight: 700;
// }

// .ui-select-choices-row-inner div {
//   font-weight: 500;
// }

.ui-select-container, .ui-select-container input, .ui-select-toggle {
  min-width: 300px;
  // height: 43px;
}

.formapp input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px $color-white inset;
  border-color: $color-grey-200 !important;
}
