.tile-collateral-value {
  .tile-collateral-value-main-contents {
    display: flex;
    .tile-collateral-value-image-column {
      max-width: 150px;
      margin-right: 20px;
      flex: 0.2;

      .google-street-view {
        width: 100%;
        padding: 0;
        margin-top: 5px;

        img{
          width: 100%;
        }
      }
    }
    .tile-collateral-value-values-column {
      flex: 0.8;
    }
    
    .tile-collateral-value-image {
      padding-top: 10px;
      clear: both; // why the fuck is google-street-view floating?
    }
  }
}