.widget-prototype-list {
  margin-top: 0;
  margin-bottom: 10px;
}

*:not(:first-child) > .widget-prototype-list {
  border-top: 1px solid rgba(255,255,255,0.3);
  margin-top: 20px;
  padding-top: 10px;
}

