.notifications {
  .notification {
    color: $color-blue;
    border-bottom: 1px solid $color-black-1;
    margin-bottom: 1em;
  }
  .three-lines {
    -webkit-line-clamp: 3;
  }
}
