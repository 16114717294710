.widget-label-value-medium {
  text-align: right;
  margin-bottom: 6px;
  &:last-child {
    margin-bottom: 0;
  }

  .widget-label {
    font-weight: 400;
    font-size: 12px;
  }
  .widget-value {
    font-size: 16px;
    font-weight: 600;
  }

}

.widget-label-value-medium-left {
  .widget-label-value-medium {
    text-align: left;
  }
}