.tile-neighborhood-listings {
  canvas {
    width: 100%;
    height: 300px;
  }

  .listing-options {
    padding-top: 10px;
    .listing-option {
      padding-right: 30px;
      display: inline-block;

      input[type="radio"] {
        display: none;
      }

      label {
        background: rgba(128,128,128,0.1);
        padding: 5px;
        font-size: 10px;
        font-weight: normal;
        cursor: pointer;
      }

      input[type="radio"]:checked+label      { 
        background: $color-blue;
        color: $color-white;
      } 

      h4 {
        font-size: 12px;
        display: inline-block;
        padding-right: 10px;
      }
    }
  }
}