.cbFieldSelector {
  text-align: left;
  display: flex;
  flex-direction: column;
  input {
    padding: 1px 1px 1px 8px;
    border-width: 0;
    border-bottom: solid 1px #cfcfcf;
  }
  .field-list {
    overflow: auto;
    height: 100%;
    padding: 5px;

    .field {
      &:nth-child(odd) {
        background: #f4f4f4;
      }
      padding: 3px 0;
      display: flex;
      .field-label {
        flex: 2;
      }
      .value {
        flex: 5;
      }
    }
  }
}