.visible-scrollbar {
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: block !important;
    -webkit-appearance: none;
    width: 11px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid $color-black-bg; /* should match background, can't be transparent */
    background-color: $color-purple-grey;
    color: $color-black-bg;
  }
  &::-webkit-scrollbar-corner {
    background: #21232B;
  }
  &::-moz-scrollbar-thumb {
    border: 2px solid #21232B; /* should match background, can't be transparent */
    background-color: #8A8E9E;
  }
  &::-moz-scrollbar-track { 
    background-color: #21232B; 
  }
  &::-moz-scrollbar-corner {
    background: #21232B;
  }
}

.invisible-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}
