.tile-neighborhood-population-density {
  .zipcode-density-table {
    table-layout: fixed;
    width: 100%;

    margin-top: 10px;
    .subzip {
      padding-left: 22px;
      &::before {
        content: "↳";
        position: absolute;
        left: 7px;
      }
    }
    td {
      white-space: nowrap;
    }
    th {
      width: 20%;
    }
    th.area-name {
      width: 40%;
    }
    .area-name {
      text-overflow: ellipsis;
      overflow: hidden;
      div{
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

