.portfolio-progress {
  display: inline-flex;
  flex-direction: column;
  width: 100px;
  vertical-align: text-bottom;
  height: 18px;
  margin-left: 5px;

  .progress-container {
    flex-grow: 1;
    border-top: solid 1px $color-blue;
    border-right: solid 1px $color-blue;
    border-left: solid 1px $color-blue;
    display: flex;

    &:last-child {
      border-bottom: solid 1px $color-blue;
    }
  }
  .progress {
    background-color: $color-green;
    margin-bottom: 0;
    border-radius: 0;
    height: inherit;
  }
}