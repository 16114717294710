.map-report-view {

  #map {
    min-height: 380px;
    height: calc(100vh - 280px);
  }

  .leaflet-marker-pane svg {
    pointer-events: visiblePainted;
  }

  .color-steps-legend, .clear-filter-legend {
    margin-left: 12px;
    background: #fff;
    color: #333;
    padding: 5px;
    border-radius: 3px;
    box-shadow: 0 0 0 2px rgba(0,0,0,0.2);

    .color-step {
      display: flex;
      margin-bottom: 3px;
      &:last-child {
        margin-bottom: 0;
      }

      .color-step-color {
        width: 12px;
        height: 12px;
        border-radius: 6px;
        margin-right: 3px; 
      }
      .color-step-label {
        line-height: 14px;
      }
    }
  }

  .clear-filter-legend {
    cursor: pointer;
    text-transform: uppercase;
    .material-icons {
      font-size: 16px;
      vertical-align: -4px;
    }
  }

  .leaflet-draw-tooltip {
    background: rgba(0,0,0,0.8);
    white-space: nowrap;
    padding: 5px;
    border-radius: 3px;
    font-size: 10px;
    color: #ddd;
    margin-top: 20px;
    margin-left: 20px;
  }
    
  .leaflet-touch {
    .leaflet-draw-toolbar .leaflet-draw-draw-rectangle {
      cursor: pointer;
      background-image: url(../../assets/img/pencil.svg);
      background-position: center center;
      background-size: 20px 20px;
      &.leaflet-draw-toolbar-button-enabled {
        background-color: tint-color(#343b46, 60%);
      }
    }
    .leaflet-draw-actions {
      margin-top: 10px;
      padding-inline-start: 23px;
      color: $color-white;
      border-radius: 3px;
      box-shadow: 0 0 0 2px rgba(0,0,0,0.2);
      background: #000;
      li a {
        text-transform: uppercase;
        color: $color-white;
        font-weight: 400;
      }
    }
  }

  .controls {
    display: flex;
    padding-left: 280px;
    position: relative;
    margin-top: -43px;
    height: 43px;
    padding-right: 43px;
    box-sizing: content-box;
    max-width: 600px;
    align-items: end;

    .selectize-input {
      border: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background-color: $color-purple !important;
      color: $color-light-grey;
      &:focus, &.focus {
        color: $color-purple !important;
        background-color: $color-white !important;
      }
    }

    .matched-selection {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      .matched-selection-label {
        font-weight: 500;
        opacity: 0.7;
        margin-right: 5px; 
      }
      span {
        width: auto;
        display: inline;
      }
    }

    .dropdown {
      width: 49%;
      min-width: inherit;
      margin-right: 1px;

      &.ui-select-container {
        input {
          min-width: 100%;
        }
      }

      .ui-select-toggle {
        min-width: inherit;
      }

      .ui-select-choices-row.active>span {
        background-color: #eee;
        color: #333;
      }

      input.ui-select-search {
        width: 100% !important;
        position: absolute;
      }

      .btn {
        background: $color-purple;
        color: $color-white;
        border: 1px solid $color-black-bg      }
    }
  }
}