.widget-split-draggable-columns {
  flex-wrap: nowrap;
  display: flex;
  height: 100%;

  .widget-split-draggable-column:first-child {
    flex: 1;
  }
  .grip {
    background: transparent;
    width: 10px;
    cursor: ew-resize;
    border-right: 1px solid rgba(255,255,255,0.2);
  }
  .widget-split-draggable-column {
    min-width: 250px;
    overflow: auto;
    position: relative;
  }
}