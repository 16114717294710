#gaugeChart {
  width: 100%;
  height: auto;
  background: $color-grey-tile;
  color: $color-white;

  .subtext {
    fill: $color-white;
    font-size: 16px;
  }

   svg g .min,  svg g .max,  svg g .middle {
    font-weight: 800;
  }

   .risk .min {
    fill: $color-green;
  }
   .risk .middle {
    fill: $color-yellow;
  }
   .risk .max {
    fill: $color-red;
  }
   .fico .min {
    fill: $color-red;
  }
   .fico .middle {
    fill: $color-yellow;
  }
   .fico .max {
    fill: $color-green;
  }
   .utilization .min {
    fill: $color-green;
  }
   .utilization .middle {
    fill: $color-yellow;
  }
   .utilization .max {
    fill: $color-red;
  }
}
