$highlight-color: #a1bac9;
$scenario-background: #303336;
$scenario-progress: rgb(5,174,125);
$scenario-in-progress: #009DDC;

.save-preset-dialog {
  .warning {
    background: tint-color($color-red, 30%);
    color: $color-red;
    border: 1px solid $color-red;
    padding: 10px;
    strong {
      margin-right: 10px;
    }
  }
}

.widget-payscen-scenario {
  font-size: 12px;
  background: $scenario-background;
  width: 175px;
  flex-shrink: 0;
  color: #fff;
  position: relative;
  margin-bottom: 150px;

  .deal-user & .disclaimer {
    display: block;
    border: solid 1px #656565;
    margin: 5px 10px 0;
    padding: 5px;
    font-size: 10px;
    font-style: italic;
  }

  .disclaimer {
    display: none;
  }

  > .header {
    position: sticky;
    top: 0;
    z-index: $scenario-header-z;
  }

  .scenario-progress {
    background: $scenario-progress;
    height: 2px;
    position: absolute;
    top: 0;
    left: 0;
    transition: width linear 0.5s, color linear 0.5s;
    &.in-progress {
      background: $scenario-in-progress;
    }
  }

  .focused-scenario & {
    width: 100%;
    margin: 0;
    .scenario-inputs {
      top: 55px;
    }
    .scenario-parameter-input {
      min-height: 0 !important;
    }
  }

  .view-all-action {
    text-align: center;
    display: block;
    height: 20px;
    line-height: 20px;
    margin: 2px 10px 0;
    color: hsla(0, 0%, 60%, 1);
    text-transform: uppercase;
    font-size: 10px;

    i {
      opacity: 0.5;
    }
  }

  .tranche-analytics.scenarioError {
    visibility: hidden;
  }

  select {
    background: tint-color($scenario-background, 5%);
    width: 100%;
    height: 35px;
    color: #fff;
    border-radius: 0px;
    border: 0;
    background-image: none;
    box-shadow: none;
    -webkit-appearance: menulist;
  }

  .scenario-parameter-input {
    padding: 10px 0;
  }

  .model-selection {
    display: flex;
    height: 35px;
    background: tint-color($scenario-background, 5%);

    .selectize-control {
      width: 100%;
    }

    .selectize-control.single {
      .selectize-dropdown {
        background: shade-color($scenario-background, 5%);
        color: #fff;
        border-color: tint-color($scenario-background, 30%);
        &.active {
          background: #40444e;
          color: #fff;
        }

        .optgroup-header {
          background: shade-color($scenario-background, 15%);
          font-size: 12px;
          text-transform: uppercase;
          color: #ccc;
          padding: 9px 9px;
        }

        .option {
          cursor: pointer;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          &:before {
            content: "—";
            padding-right: 8px;
            opacity: 0.5;
          }
        }
      }

      .selectize-input {
        background: transparent;
        border-color: transparent;
        padding-left: 10px;
        box-shadow: none;
        color: #fff;
        // font-size: 18px;

        .item {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 100px;

          .focused-scenario & {
            max-width: 245px;
          }
        }

        &:after {
          border-top-color: #fff;
        }

        input {
          color: #fff;
          &::placeholder {
            color: #ccc;
          }
        }
        
        &.input-active {
          background: rgba(255, 255, 255, 0.15);
          color: #fff;

          &:after {
            border-bottom-color: #fff;
          }
        }
      }
    }

    .edit-action {
      flex: 1;
      width: auto;
      button {
        background: transparent;
      }
    }
  }

  .cashflow-summary {

    .btn:not(.is-focused) {
      background: transparent;
    }

    .btn {
      width: 60px;
      margin: 5px;
      height: 25px;
      line-height: 15px;
      font-size: 11px;
      font-weight: normal;
    }

    .cashflow-summary-details-buttons {
      display: flex;
      margin-top: 10px;
      .btn {
        flex: 1;
        white-space: normal;
        height: inherit;
      }
    }

    .cashflow-summary-details {
      padding: 10px;
    }
    .cashflow-summary-header {
      background: tint-color($scenario-background, 5%);   
      height: 35px;
      display: flex;

      .header {
        width: 100%;
        padding: 10px;
        font-weight: bold;
      }
    }
  }

  .error-block {
    .focused-scenario & {
      height: inherit !important;
    }
    .error-display {
      background: tint-color($color-red, 10%);
      color: #333;
      border: $color-red;
      padding: 10px;
      text-align: center;
      width: 100%;

      button {
        display: block;
        margin: 10px auto 0;
      }
    }
  }

  .model-editor-link {
    line-height: 35px;
    text-align: center;
    width: 30px;
  }

  .scenario-inputs {
    outline: 0;
    box-sizing: content-box;

    z-index: $scenario-input-z;
    box-shadow: 0 2px 2px rgba(0,0,0,0.2);
    background: #343639;
  }
}

