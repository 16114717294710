.tile-school-district {
  .widget-tile .widget-details {
    md-divider {
      margin: 10px 0;
    }
  }

  .map-column, .leaflet-container {
    height: calc(100% - 5px);
  }
}

.tile-school-district-hover {
  text-align: center;
  padding: 5px 0;
}