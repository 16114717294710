.edit-nav-container {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px $color-blue;

  ul.navigator-edit.nav-tabs {
    border-bottom: none;

    :not(:first-child) {
      margin-left: 2px !important;
    }

    > li {
      &.active {
        > a {
          color: $color-white;
          border: solid 1px $color-blue !important;
          background-color: #383c41;
        }
      }
      > a {
        &:hover {
          border: solid 1px $color-blue !important;
        }
        border-bottom: solid 1px $color-blue !important;
        background-color: #383c41;
      }
    }
  }

  .user-navigation {
    .btn-outline-primary {
      background-color: transparent;
      border: solid 1px $color-blue;
      &:hover {
        border: solid 1px $color-light-blue;
        a {
          color: $color-light-blue;
        }
      }
    }
    label.btn {
      padding-top: 9px;
    }
  }
}
