// @media (max-width: $screen-xs-max) {
//   nav-tabs {
//     .ui-select-container, .ui-select-container input, .ui-select-toggle {
//       min-width: 211px;
//       position: relative;
//       margin-left: -1px;
//     }
//   }
// }
nav-tabs {
  color: $color-grey;
  .nav-tabs {
    margin-top: 15px;
    margin-bottom: 1px;
    border-bottom: 0px;
  }

  .nav-tabs > li > a.nav-link {	
    background-color: $color-purple;	
    color: $color-white;	
    border: 1px solid $color-black-bg;	
    border-bottom: 1px solid $color-black-bg;	
    padding: 10px 15px;
  }

  .text-muted {
    color: $color-grey-170;
  }

  .nav-tabs > li {
    z-index: $nav-tabs-z;
    // font-weight:700;
    text-transform: uppercase;
    display: inline-block;
    // margin-bottom: -1px;
    margin-left: 1px;
    &:first-of-type {
      margin-left: 0;
    }
  }

  .nav-tabs > li > a {
    outline:none;
    border-radius:0;
    margin-bottom:5px;
    margin: 0;
  }

  .nav-tabs > li > a.nav-link:hover {
    outline:none;
    border-radius:0;
    margin: 0;
    opacity:.9;
    background-color: $color-purple;
    color: $color-white;
    border: 1px solid $color-black-bg;
    border-bottom: 1px solid $color-black-bg;
  }

  .nav-tabs > li > a:active {
    opacity:1;
    color: $color-dark-grey;
  }

  .nav-tabs > li > a.nav-link:focus {
    outline:none;
    color:$color-white;
    margin-right: 0;
    border-radius:0;
  }

  .nav-tabs > li.active > a.nav-link, 
  .nav-tabs > li.active > a.nav-link:hover, 
  .nav-tabs > li.active > a.nav-link:focus {
    color: $color-black;
    border: 1px solid $color-purple-grey;
    border-bottom: 1px solid $color-blue;
    background-color: $color-grey-tile;
    outline:none;
    cursor: default;
    border-radius:0;
  }
  .nav-tabs>li.active>a.nav-link, .nav-tabs>li.focus>a.nav-link {
    color: $color-black !important;
  }
  
  // .nav-tabs .ui-select-toggle {
  //   transition: border-color 0s;
  //   background-color: $color-purple;
  //   color: $color-white;
  //   border: 1px solid $color-black-bg;
  //   border-bottom: 1px solid $color-black-bg;
  //   &:hover, &:focus {
  //     color: $color-white;
  //     background-color: $color-light-dark-grey;
  //     border: 1px solid $color-grey-200;
  //   }
  // }
  .nav-tabs .selectize-control .ui-select-choices-row.active>a {
    background-color: $color-light-grey !important;
  }
  .nav-tabs .selectize-control .ui-select-choices-row>a:hover {
    background-color: inherit !important;
  }
  .nav-tabs .selectize-control .ui-select-choices-row>a:hover, .selectize-control .ui-select-choices-row>a:focus {
    background-color: inherit;
  }

  .nav-tabs .bucketfields-dropdown {
    .selectize-input {
      border: none;
      padding: 5px 8px;
      height: 43px;
      display: flex;
      align-items: center;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background-color: $color-purple;
      color: $color-light-grey;
      &:focus, &.focus {
        color: $color-purple !important;
        background-color: $color-white !important;
      }
    }
  }

  .go-to-column {
    .selectize-input {
      border: none;
      padding: 5px 8px;
      display: flex;
      align-items: center;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background-color: $color-purple;
      color: $color-light-grey;
      &:focus, &.focus {
        color: $color-purple !important;
        background-color: $color-white !important;
      }
    }
    .ui-select-placeholder {
      color: #fff;
      font-size: 23px;
      padding: 0 2px;
      margin-top: -9px;
      display: block;
    }
    .ui-select-container, .ui-select-container input, .ui-select-toggle {
      // height: 38px;
      min-width: 0;
    }
    input {
      border-radius: 4px 4px 0 0;
    }
    i.caret {
      display: none;
    }
    .ui-select-toggle:hover, .ui-select-toggle:focus {
      // background-color: #009DDC !important;
      .ui-select-placeholder {
        color: #fff !important;
      }
    }
  }
  
}
