.locationmap {
  >div {
    width: 100%;
    height:400px;
  }
}

.map {
  > table th, > table td {
    text-align: left;
  }
}

.pagination {
  margin: 0;
}

.pagination > li:first-child > a, .pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.pagination > li:last-child > a, .pagination > li:last-child > span {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.pagination > li > a, .pagination > li > span {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857;
    text-decoration: none;
    color: $color-blue;
    background-color: $color-grey-tile;
    border: 1px solid $color-light-grey;
    margin-left: -1px;
}

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
    z-index: $pagination-z;
    color: $color-white;
    background-color: $color-blue;
    border-color: $color-white;
    cursor: default;
}