.tile-livability {
  .pie-container {
    width: 100%;
    min-height: 300px;
  }
  .under-pie {
    position: absolute;
  }
  .pie-home {
    position: relative;
  }
  .livability-piece {
    padding: 10px;
    position: relative;
    outline: none;
    color: #fff;
    cursor: pointer;

    .livability-piece-score {
      position: relative;
      z-index: $livability-score-z;
      float: right;
    }
    .livability-piece-title {
      position: relative;
      z-index: $livability-title-z;
      font-weight: bold;
      .arrow {
        display: inline-block;
        width: 15px;
      }
    }
    .livability-bar {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .livability-details {
    padding: 10px;
    border: 1px solid #000;
    border-top: 0;

    .livability-detail {
      margin-bottom: 3px;
    }
    .sub-piece {
      padding: 0px 0px 10px 10px;
    }
  }
}