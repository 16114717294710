/* comments */
$comments-border: #ddd;
$comments-background: #fff;
$comments-color: #000;
$comments-shadow: rgba(0,0,0,0.1);

.comments-container {
  
  textarea {
    background: $color-black-bg;
    color: inherit;
  }
    
  .toggle-comments-button {
    
  }

  .comments-list {

    textarea {
      font-size: 12px;
      border: 1px solid rgba(255,255,255,0.3);
      padding: 10px;
      width: 100%;
    }

    .comment {
      padding: 10px;
      border-bottom: 1px solid rgba(255,255,255,0.3);
      &:last-child {
        border-bottom: 0;
      }
      .btn {
        margin-top: 10px;
        margin-right: 10px;
      }
      .edit-controls {
        float: right;
        i {
          margin: 5px;
          margin-right: 0;
          margin-top: 0;

          opacity: 0.5;
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
        }
      }

      p {
        font-size: 12px;
        .emoji {
          width: 18px;
          height: 18px;
        }
      }
      .details-author {
        font-weight: 600;
        font-size: 12px;
      }
      .details-date {
        font-weight: 300;
        font-size: 12px;
      }
    }
  }
}