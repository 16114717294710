.hover-action-wrapper {
  &:hover {
    .hover-action-icon {
      opacity: 1;
    }
  }
  .hover-action-icon {
    opacity: 0;
  }
}

/* When tooltips need to go on top of everything... */

.tooltip-top {
  z-index: $tooltip-z;
}