.da-footer {
  color: $color-grey-170;
  width: 275px;
  left: calc(50% - 125px);
  position: absolute;
  bottom: 0;
  cursor: default;
  &:hover, &:focus {
    color: $color-grey-170;
  }
  margin: 0 0 10px;
}
