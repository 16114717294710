.creditgraph {
   top: 30px;
   width: 100%;
   height: auto;
 
  .tooltip {
    position: absolute;
    font-size: 13px;
    fill: #444;
    color: #444;
    overflow: hidden;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 20px;
    text-align: left;         
    width: auto;                    
    height: auto;                                    
    background: $color-light-grey; 
    border-radius: 5px;         
    pointer-events: none; 
    box-shadow: 1px 1px 1px $color-dark-grey;
  }
  .currentTitle {
    position: relative;
    top: 10px;
    left: 15px;
    font-size: 17px;
    font-weight:400;
    cursor: pointer;
  }
  .legend, text {
    text-anchor: front;
    font-size: 1em;
    fill: $color-white;
  }
  .legendTitle text{
    text-anchor: front;
    font-size: 13px;
    fill: $color-grey-68;
  }
  .portfolio {
    color: $color-white;
    background: $color-grey-tile;
  }
  .portfolio .allRects {
    stroke: $color-black;
    stroke-width: 0.1px;
  }
  .portfolio .xAxis {
    text-anchor: middle;
    font-size: 1em;
  }
  .portfolio .xAxis text {
    fill: $color-white;
    stroke-width: 0.1px;
  }
  .yAxis text {
    fill: $color-white;
    stroke-width: 0.1px;
    font-size: 1em;
  }
  .portfolio .yAxis line {
    stroke: $color-white;
    stroke-width: 0.1px;
    fill: none;
  }
  .portfolio .yAxis path {
    display: none;
  }
  .payment {
    color: $color-white;
    background:$color-grey-tile;
  }
  .payment .yAxis path {
    display: none;
  }
  .payment .yAxis line{
    fill: none;
    stroke-width: 1px; 
    stroke: $color-white;
    opacity: 0.3;
  }
  .payment .xAxis line {
    stroke: $color-white;
    stroke-width: 0.1px;
    fill: none;
  }
  .payment .xAxis text {
    opacity: 0;
  }
  .payment .scheduled {
    fill: none;
  }
}