.custom-editor {
  .custom-contents>div {
    height: calc(100vh - 250px);
    margin-top: 10px;
  }
  .CodeMirror-dialog {
    position: absolute;
    bottom: 10px;
    left: 55px;
    z-index: 2;
    background-color: #383c41;

    input {
      background-color: transparent;
      outline: none;
    }
  }
}