.widget-label-value-mega {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }

  .widget-label {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
  }
  .widget-value {
    font-size: 30px;
  }
}