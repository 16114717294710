.widget-value-card {
  margin-bottom: 5px;

  .primary-icon {
    position: absolute;

    .icon-title {
      font-size: 18px;
      position: relative;
      width: 20px;
      margin-right: 5px;
      text-align: center;
      height: 14px;
    }
  }

  >.widget-value-card-title {
    display: block;
    font-weight: 600;
    margin: 0;
    padding: 0;
    font-size: 14px;
    margin-bottom: 3px;
    padding-left: 25px;
    >.icon-title, >a>.icon-title {
      font-size: 18px;
      position: relative;
      width: 20px;
      margin-right: 5px;
      text-align: center;
      height: 14px;
    }

    .main-icon {
      margin-left: 0;
      margin-right: 5px;
      color: #1AB595;
    }

    .promote-icon, .delete-icon {
      opacity: 0;
      transition: opacity 0.2s linear;
      margin-left: 0;
      margin-right: 3px;
      color: #999;
      &:hover {
        color: #000;
      }
    }
    .widget-value-card-label {
      margin-right: 5px;
    }
  }

  &:hover {
    >.widget-value-card-title {
      .promote-icon, .delete-icon {
        opacity: 1;
      }
    }
  }

  .widget-value-card-body {
    padding-left: 25px;
    padding-bottom: 10px;
    &:empty {
      padding-bottom: 0;
    }
  }

  &.icon-scale-large {
    margin-bottom: 10px;
    min-height: 32px;

    .primary-icon .icon-title {
      width: 30px;
      height: 30px;
      margin-bottom: -18px;
      margin-right: 10px;

      i {
        font-size: 30px;
      }
    }
    
    .widget-value-card-body, .widget-value-card-title {
      padding-left: 41px;
    }
  }

  &:last-child {
    margin-bottom: 0;
    .widget-value-card-body {
      padding-bottom: 0;
    }
  }
}

.widget-value-card-wrapper {
  &:not(:last-child) {
    >.widget-value-card {
      .widget-value-card-body {
        padding-bottom: 10px;
        &:empty {
          padding-bottom: 0;
        }
      }
      margin-bottom: 5px;
    }
  }
}