
.widget-payscen-scenario-parameter-input {
  padding: 0 10px;
  height: 28px;
  border: solid 1px transparent;
  .focused-scenario &, .model-editor & {
    border: none;
    &.highlight {
      border: none;
    }
    &.blank {
      display: none;
    }
  }
  select.default {
    color: rgba(255,255,255,0.45);
    font-weight: 400;
  }
  .model-editor & {
    padding: inherit;
    height: inherit;
  }
  &.highlight {
    background: rgba(255,255,255,0.1);
  }
  label {
    color: #ccc;
    .payscen-scenarios & {
      display: none;
    }
    .payscen-scenarios .focused-scenario & {
      display: block;
    }
  }
  input::placeholder {
    color: rgba(255,255,255,0.45);
  }
  input, select {
    background: rgba(255, 255, 255, 0.05);
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0px;
    color: #fff;
  }
  input:focus {
    background: rgba(255, 255, 255, 0.05);
    color: inherit;
  }
  .btn {
    height: 34px;
    border-radius: 0;
    background: rgba(255,255,255,0.08);
    border: 1px solid rgba(255, 255, 255, 0.1);
    &:focus, &:active {
      border: 1px solid rgba(100, 100, 255, 0.3);
    }
  }
  .input-progress {
    bottom: 0;
    transition: width 1s linear;
    position: absolute;
    opacity: 0.15;
    background: #009DDC;
    left: 0;
    top: 0;
  }

  .condensed & {
    display: flex;
    margin-bottom: 0;
    margin-top: 0;

    &.widget-payscen-scenario-parameter-input-text, &.widget-payscen-scenario-parameter-input-enum {
      label {
        min-width: 75px;
        max-width: 75px;
      }
    }
    &.widget-payscen-scenario-parameter-input-boolean {
      label {
        width: 100%;
        padding-right: 0;
        line-height: 19px;
        margin-top: 5px;
        margin-bottom: 4px;
      }
    }

    label {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 26px;
      padding-right: 10px;
      text-align: right;
      margin-bottom: 0;

      input[type="checkbox"] {
        margin-top: 4px;
        position: absolute;
        padding: inherit;
        margin-left: -15px;
      }
    }
    input[type="text"] {
      height: 26px;
      padding: 10px 5px;
      width: 100%;
    }
    select {
      height: 26px;
      padding: 0 5px;
      white-space: pre;
      option {
        background: #333;
      }
    }
    .locked, .inherited-value, .computed {
      &::before {
        line-height: 26px;
      }
    }
    .input-group {
      display: flex;
      height: 26px;

      input, select {
        height: 100%;
        padding: 0 5px;
        flex: 1 1 auto;
        width: 1%;
      }
      height: 100%;
      & >.btn {
        height: 26px;
        padding-top: 4px;
      }
    }

  }
  .focused-scenario & {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0
    }
  }

  .input-group-placeholder {
    position: relative;
    display: flex;
    flex: 1;
    width: 100%;
    &.input-group {
      display: flex;
      height: 100%;
      .focused-scenario & {
        width: calc(100% - 75px); // unfuck safari and weird other weirdness?  It sucks we have to hardcode 75px.
      }
    }
  }

  .input-group {
    flex: 1;
  }

  .locked {
    input[type="text"] {
      padding-left: 25px;
      color: rgb(5,174,125);
    }
    &::before {
      font-family: 'Material Icons';
      content: 'lock';
      position: absolute;
      font-size: 16px;
      line-height: 35px;
      left: 6px;
      color: rgba(255, 255, 255, 0.4);
    }
  }

  .project-forward i {
    font-size: 17px;
    vertical-align: -3px;
  }

  .computed {
    input[type="text"] {
      color: rgba(255, 255, 255, 0.9);
      padding-left: 25px;   
    }
    &::before {
      font-family: 'Material Icons';
      content:'flash_on';
      position: absolute;
      font-size: 16px;
      line-height: 35px;
      left: 6px;
      color: rgba(255, 255, 255, 0.4);
    }
    
  }
}
