.tile-chart-service {
  .chart-wrapper {
    width: 99%;
    
    transition: opacity linear 0.2s;
    &.loading {
      opacity: 0.5;
    }

    .spinner {
      font-size: 20px;
      text-align: center;
      width: 100%;
      top: 20%;
      position: absolute;
    }

    position: relative;
  }

  .error {
    color: #333;
    background: #cc6666;
    padding: 10px;
    border: #aa3333;
  }

  h4 {
    font-size: 12px;
  }
}