.widget-bar-meter {
  margin-bottom: 6px;
  &:last-child {
    margin-bottom: 0;
  }

  h6 {
    margin-bottom: 6px;
    font-size: 12px;
    margin-top: 0;
  }

  .widget-bar-meter-contents {
    display: flex;

    .widget-bar-meter-container {
      flex: 1;
      border-radius: 3px;
      border-top: 15px solid #eee;
      height: 15px;

      .widget-bar-meter-value {
        margin-top: -15px;
        border-top-color: $color-blue;
        border-top-width: 15px;
        border-top-style: solid;
        transition: width 5s ease-out;
        height: 15px;
        border-radius: 3px;
        width: 0;
      }
    }

    .widget-bar-meter-value-display {
      font-weight: bold;
      width: 30px;
      padding-left: 10px;
      color: $color-blue;
    }
  }

  &.widget-bar-meter-error {
    .widget-bar-meter-container .widget-bar-meter-value {
      border-top-color: $color-red;
    }
    .widget-bar-meter-value-display {
      color: $color-red;
    }
  }

  &.widget-bar-meter-warning {
    .widget-bar-meter-container .widget-bar-meter-value {
      border-top-color: $color-yellow;
    }
    .widget-bar-meter-value-display {
      color: $color-yellow;
    }
  }

  &.widget-bar-meter-success {
    .widget-bar-meter-container .widget-bar-meter-value {
      border-top-color: $color-green;
    }
    .widget-bar-meter-value-display {
      color: $color-green;
    }
  }

}