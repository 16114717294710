.uploader.progress {
  height: 28px !important;
}

.uploadModal {
  color: $color-grey-68;
}

.uploader.progress .progress-bar {
  background-color: $color-blue;
}

.drop-zone {
  background: $color-black-bg;
  color: $color-white;
  min-height: 100vh;
}
.drop-zone h3 {
  color: $color-blue;
}
.drop-zone h1, .drop-zone h4, .drop-zone p, .drop-zone span {
  color: $color-grey-170;
}
.drop-zone-text-container {
  height: 10em;
  position: relative;
}

.drop-zone-text {
  color: $color-light-grey;
  font-size: 30px;
  margin: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.drop-zone-close {
  position: fixed;
  top: 90px;
  right: 20px;
  z-index: $drop-zone-close-z;
  color: $color-white;
}

.name-portfolio {
  margin-top:20px;
  text-align: center;
}

.name-portfolio input {
  display:inline;
  width:200px;
  height:31px;
}

.name-portfolio button {
  margin:-4px 0 0 5px;
}
.upload-icon {
  margin: 0 auto;
  max-width: 100px;
}
.upload-box {
  border: 5px dashed $color-blue;
  padding: 2em;
  margin: 2em auto;
  border-radius: 2em;
}
.upload-box.over {
  border: 10px dashed $color-blue;
}
.drop-zone .btn-lg, .modal .btn-lg {
  font-size: 25px; 
  height: auto;
  padding: 15px 30px;
}
.modal tbody tr {
  cursor: default;
}
.term-content {
  overflow: auto;
}
.term-content .table-list ol li {
  padding: 5px;
  margin-left: 35px;
  font-size: 16px;
}
.term-content ol li {
  padding: 5px;
  font-size: 12px;
}

.term-content p {
  font-size: 12px;
}

.term-title {
  margin-top: 60px;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
}

.uploadModal .modal-dialog {
  width: 600px;
  max-width: 600px;
}

.table td.icon svg {
  width: 29px;
}