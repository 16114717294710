.auth {

  &.unsupportedBrowser form {
    opacity: 0.3;
  }

  .unsupportedBrowserMessage {
    color: $color-red;
    font-size: 18px;
    border: solid 1px $color-red;
    padding: 15px;
    margin-bottom: 30px;
    text-align: center;
  }

  .btn-auth{
    background: white;
  }
  .plugin-bg{
    background: #e0e0e0
  }
  .enter-info-wrapper {
    h5 {
      color: $color-black;
    }
  }

  .tos-input, .code-input {
    label {
      color: #333;
      font-weight: normal;
      input {
        margin-right: 5px;
      }
    }
  }

  .tos-input {
    p { font-size: 14px; color: #333; }
    .tos-contents {
      height: calc(100vh - 500px);
      * { color: #333; }
      overflow-y: scroll;
      p { font-size: 14px; }
      margin-bottom: 20px;
      padding: 20px;
      border: 1px solid #aaa;
    }
    label {
      padding-left: 20px;
      position: relative;
      line-height: 18px;
      &.disabled {
        color: #999;
        input {
          opacity: 0.9;
        }
      }
      input {
        position: absolute;
        top: 3px; left:0;
        margin: 0;
      }
    }
  }

  min-height: 100vh;
  .full-height-sm-min {
    height: calc(100vh - 66px);
  }
  .full-height-sm-min-w-button {
    height: calc(100vh - 141px);
  }
  .vertical-center-sm-min {
    position: absolute;
    transform: translateY(50%);
    top: -50%;
  }
  .xnav {
    min-height: 66px;
    background-color: $color-dark-grey;
    img {
      margin: 1em;
    }
  }
  .container {
    width: 70%;
    margin: 0 auto;
  }
  .bg-gray {
    background-color: $color-white;
  }
  h1,h4,input{
    color: $color-grey-114;
  }
  .fg-black {
    h1,h4,input {
      color: $color-black;
    }
  }
  h4 {
    line-height: 1.2;
  }
  input {
    border: 0;
    padding-left: 0;
    padding-right: 0;
    border-bottom: solid 1px $color-grey-114;
    box-shadow: none !important;
    margin-bottom: 1.5em;
    text-indent: 5px;
  }
  form {
    @media (min-width: $screen-xs-min) {
      max-width: 500px;
      margin: 0 auto;
    }
  }
  .active, .active i {
    color: $color-blue !important;
  }
  .input-box {
    background-color: #fff;
    border: 1px solid $color-blue;
    padding: 1em 1em 0 1em;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
  .drop-shadow {
    box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.25);
  }
  // &.verify form {
  //   @media (min-width: $screen-xs-min) {
  //     max-width: inherit;
  //     margin: inherit;
  //   }
  // }
  .border-highlight {
    border: 1px solid $color-blue;
  }
  .capitalize {
    text-transform: capitalize;
  }
  .method-list {
    margin-top: 20px;
    margin-bottom: 15px;
    li {
      margin-right: 7em;
      &:after {
        content: '—';
        font-size: 40px;
        position: absolute;
        margin-top: -1em;
        margin-left: .55em;
      }
      &:last-of-type {
        margin-right: 0;
        &:after {
          content: '';
        }
      }
    }
  }
  .text-muted {
    i, p {
      color: #c6c6c6 !important;
    }
  }
  .method-list {
    padding: 0;
  }
  .enter-info {
    ul,ol {
      padding-left: 15px;
    }
    ol {
      li {
        margin: 0.5em 0;
        .notes {
          background: #e0e0e0;
          padding: 10px;
          margin: 10px 0 20px 0;
        }
      }
    }
    background: #f5f5f5;
    padding: 20px;
    .enter-info-wrapper {
      min-width: 420px;
      margin: 0 auto;
    }
  }
  .action-footer {
    background: #fff;
    .action-footer-wrapper {
      min-width: 420px;
      margin: 0 auto;
      .button-wrapper {
        text-align: right;
      }
    }
  }

  .text-block {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
    background-color:white;
    padding: 20px;
    animation: pop 0.3s linear 1;
  }

  form {
    .alert {
      color: #727272;
    }
    p.text-error {
      font-size: 14px;
    }
  }

  .navbar li.nav-contact {
    background: rgba(0,0,0,0.3);
    font-size: 14px;
    border-radius: 5px;
    margin: 10px;
    line-height: 14px;
    border: 1px solid rgba(0,0,0,0.5);
    padding: 12px 15px;
    i {
      font-size: 20px;
      padding: 0;
      margin: -5px 10px -5px 0;
    }
    &:hover {
      background: rgba(0,0,0,0.6);
    }
    .dropdown-menu {
      background-color: #292f37;
    }
  }
  .navbar .navbar-right {
    margin-right: inherit;
  }
}
