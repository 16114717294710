.tile-credit-summary {
  .gauge-container {
    width: 60%;
    margin: auto;
    div.title {
      text-align: center;
      margin-bottom: 25px;
      margin-top: -20px;
    }
  }
  ul {
    padding-left: 19px;
    li {
      padding-top: 5px;
    }
  }
  label {
    font-size: 12px;
    font-weight: bold;
  }
  .credit-factors .widget-value, .credit-factors .widget-details {
    margin-bottom: 30px;
  }
  .widget-label-value-medium {
    min-width: 85px;
    margin-right: 10px;

    .widget-value {

    }
  }
  .widget-label-value-medium, .widget-label-value-simple {
    &.danger {
      .widget-value {
        color: $color-red;
        font-weight: 900;
      }
    }
    &.warning {
      .widget-value {
        color: $color-yellow;
        font-weight: 900;
      }
    }
    &.clear {
      .widget-value {
        color: $color-green;
        font-weight: 900;
      }
    }
  }
  .sparkRow {
    height: 45px;
    display: flex;
  }
  .chart {
    max-width: 120px;
    width: 100%;
    height: 24px;
  }
}

