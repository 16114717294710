.plugin-auth{
  .plugin-bg{
    background: #e0e0e0
  }
  min-height: 100vh;
  .full-height-sm-min {
    height: calc(100vh - 66px);
  }
  .xnav {
    min-height: 66px;
    background-color: $color-dark-grey;
    img {
      margin: 1em;
    }
  }
  .container {
    width: 70%;
    margin: 0 auto;
  }
   h1,h4,input{
    color: $color-grey-114;
  }
  h4 {
    line-height: 1.2;
  }
  .text-block {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
    background-color:white;
    padding: 20px;
    animation: pop 0.3s linear 1;
  }
}
