$table-grid-color: 1px solid rgba(0, 157, 220, 0.2);

.api-controls-editor {
  color: $color-white;

  label.btn {
    border-radius: 3px;
    padding-top: 9px;

    &:focus, &.focus {
      color: $color-white;
    }

    &.inactive {
      &:hover, &:active {
        color: $color-grey-170;
        background-color: inherit;
      }
    }
    &.active {
      border: 1px solid $color-blue;
      color: $color-white;
      &:hover, &:focus, &:active {
        background-color: $color-blue;
        color: $color-white;
      }
    }
    &.active.override, &.override {
      border: 1px solid $color-blue;
      background-color: $color-blue;
      color: $color-white;

      &:hover, &:focus, &:active {
        border: 1px solid $color-white;
        color: $color-white;
      }
    }
  }
  input.maxAge, input.maxAttempts, input.dynamic {
    margin-right: 5px;
    color: $color-black;
  }
  input.maxAge, input.maxAttempts {
    width: 70px;    
  }
  input.dynamic {
    width: 100px;
  }
  .units select {
    width: 88px;
    text-overflow: unset;
    padding-right: 0;
  }
  td.maxAge {
    text-align: left;
  }
  td.canceller i {
    color: $color-blue;
    &:hover {
      color: $color-light-blue;
    }
  }
  span.errorMessage, div.errorMessage {
    color: $color-red;
  }
  div.actionButtons {
    text-align: center;
    button:first-child {
      margin-right: 10px;
    }
    margin-bottom: 40px;
    div.errorMessage {
      margin-top: 8px;
    }
    
  }
  .readonly {
    opacity: 0.6;
  }
  table.t1 {
    margin: 1em auto;
    border-collapse: collapse;
    font-family: Arial, Helvetica, sans-serif;
    position: relative;
  }
  .t1 th, .t1 td {
    padding: 4px 8px;
  }
  .t1 thead th {
    background: $color-blue;
    text-transform: lowercase;
    text-align: left;
    font-size: 15px;
    color: #fff;
    position: sticky;
    top: $navbar-height;
    z-index: 1;
  }
  .t1 tr {
    border-right: $table-grid-color;
    border-left: $table-grid-color;
  }
  .t1 tbody tr {
    border-bottom: $table-grid-color;
  }
  .t1 tbody th, .t1 tbody tr td {
    border-right: $table-grid-color;
    border-left: $table-grid-color;
  }
}