.column-match-dialog {
  .header {
    background: #e0e0e0;
    h2 {
      padding: 20px;
      margin: 0;
      text-align: left;
    }
  }
  .contents {
    padding: 20px;
    font-size: 14px;
    text-align: left;

    .group {
      margin-top: 20px;
    }
  }
  .md-icon-button {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.data-upload {
  .data-upload-header {
    display: flex;
    .additional-file {
      flex: 1;
      &:first-child {
        margin-right: 20px;
      }
    }
  }
  .additional-file {
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.05);
    border-radius: 3px;

    h4 {
      margin-top: 0;
      margin-bottom: 20px;

      .material-icons {
        color: #337ab7;
        margin-left: 10px;
        cursor: pointer;
      }
    }

    .edit-name {
      button {
        margin-left: 10px;
      }
    }

    border: 1px solid #e3e3e3;
    padding: 10px;
    margin: 20px 0;

    button {
      margin-right: 10px;
    }

    .mappings p {
      margin-bottom: 20px;
      font-size: 14px;
      a { text-decoration: underline; }
    }

    .additional-file-name {
      width: 350px;
      font-size: 16px;
      padding: 10px;
      margin-bottom: 10px;
    }


  }
  .processing {
    text-align: center;
    padding: 50px;
    border: 1px solid #e3e3e3;
    margin: 20px 0;
    border-radius: 3px;
  }
}