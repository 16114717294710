
.navigator-view {
  color: $color-white;
  .full-size {
    color: inherit;
    width: 100%;
    height: 100%;
    font-weight: normal;
    max-height: 1.6em;
    padding: 3em 0 3em 0;
    margin: -3em 0 -3em 0;
  }
  .full-height img {
    height: 1.6em;
  }

  .ag-grid {

    .ag-overlay-loading-wrapper {
      background-color: rgba(0,0,0,0.5);
      .ag-overlay-loading-center {
        background: rgba(255,255,255,0.2);
        color: #fff;
      }
    }
    .ag-root {
      border-color: transparent;
    }
    .ag-header-cell, .ag-header {
      border-color: rgba(255,255,255,0.1);
    }

    .ag-header-row {
      background-color: $color-purple;	
      color: $color-white;
    }

    .ag-root .ag-row-hover {
      background-color: #005b80;
    }

    .ag-row-even {	
      background-color: $color-grey-tile;	
    }	
    
    .ag-row-odd {	
      background-color: $color-purple-grey;	
    }

    .ag-header-cell {
      border: 0 !important;
    }
    .ag-header {
      border-bottom: 0;
    }

    .ag-body-viewport,&.ag-theme-dark {
      background: transparent;
    }

    .ag-cell {
      padding: 11px;
      border: 0 !important;
      
      a {
        color: #fff;
        padding: 11px 0;
        font-weight: normal;
      }

      // make whole key clickable
      span.key {
        display: block;
        a {
          width: 100%;
          margin-top: -11px;
          display: inline-block;
        }
      }

      span.export {
        position: relative;
        top: -3px;
      }

      span.actions {
        position: relative;
        top: -7px;
        a {
          padding: 0 8px;
          .duplicate &.mbs {
            display: none;
          }
        }
      }
    }
    .ag-row.dim .ag-cell a {
      opacity: .4
    }
    .ag-row.dim .ag-cell a.eye-action {
      opacity: 1;
    }
  }
  &.mbs {
    span.actions a.duplicate {
      display: none;
    }
  }
}
.sticky-navigator-header {
  border-top: 5px solid $color-white;
  white-space: nowrap;
  th {
    display: inline-block;
  }
}

.json-editor-col {
  height:1200px;
  overflow:scroll;
}
.json-editor-btn-collapse {
  color:$color-black !important;
}
.json-editor-btn-edit {
  color:$color-black !important;
}
.json-editor-btn-add {
  color:$color-black !important;
}
.json-editor-btn-save {
  color:$color-black !important;
}
.json-editor-btn-cancel {
  color:$color-black !important;
}
.json-editor-btn-delete {
  color:$color-black !important;
}
.json-editor-btn-movedown {
  color:$color-black !important;
}
.json-editor-btn-moveup {
  color:$color-black !important;
}
.btn-file {
    position: relative;
    overflow: hidden;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: $color-white;
    cursor: inherit;
    display: block;
}

.actions {
  text-align: right !important;
  display: inline-flex;
  justify-content: flex-end;

}
.actions .delete, .actions .edit {
  width: auto !important;
  margin-top: 8px !important;
}
.actions a:last-child {
  margin-right: 30px;
}
.actions .configportfolio {
  width: auto !important;
}
.progress-bar-success {
  background-color: $color-green;
}
.ag-header-row .Status #agHeaderCellLabel{
  float: left;
}
img.portfolio-type {
  width: 22px;
  max-height: 23px;
  margin-left: 8px;
}
img.export {
  width: 12px;
}
.appModal {
  .modal-content {
    width: 600px;
  }
}
.appModal.renamePortfolio {
//   .container {
//     padding: 20px;
//   }
  form {
    // font-size: 16px !important;
    input {
      width: 300px;
    }
  }
  .modal-footer button {
    min-width: 60px;
  }
//   .actions {
//     padding: 30px;
//     text-align: center;
//   }
}
