.tile-property-photos {
  .photo {
    display: inline-block;
    border: 2px solid #009DE2;
    margin-right: 10px;
    margin-bottom: 10px;

    img {
      max-width: 150px;
      max-height: 150px;
    }
  }
}

