.widget-split-columns {
  flex-wrap: wrap;
  display: flex;


  .widget-split-column {
    flex: 1;
    width: 100%;
    min-width: 100%;
    margin-bottom: 3px;
  }

  &.single-column {
    @media print {
      flex-wrap: wrap;
    }
  }

  &.with-padding {
    flex-wrap: nowrap;

    .widget-split-column {
      flex: 1;
      width: 33%;
      max-width: 50%;
      min-width: 33%;
      margin-bottom: 0;
      &:empty {
        display: none;
      }
    }
    
    .widget-split-column:first-child {
      padding-right: 15px;
    }
    .widget-split-column:last-child {
      padding-left: 15px;
    }
  }

  @media print {
    flex-wrap: nowrap;
  }
}