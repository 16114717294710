
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import '~bootstrap5/scss/_functions';

// 2. Include any default variable overrides here

$navbar-height: 60px;
$navbar-margin-bottom: 0;
$navbar-default-bg: #343b46;
$zindex-navbar-fixed: $navbar-z;
$navbar-padding-y: 0;
// $navbar-padding-x: 15px;
$navbar-default-link-color: #fff;
$navbar-default-link-hover-color: #f0f0f0;
$navbar-default-link-active-color: #f0f0f0;
$navbar-padding-x: 0;
$zindex-tooltip: $tooltip-z;
$font-size-base: .9rem;
$dropdown-bg: shade-color($navbar-default-bg, 5%);
$dropdown-link-color: #fff;
$dropdown-link-hover-color: #fff;
$dropdown-link-hover-bg:tint-color($dropdown-bg, 10%);
$grid-float-breakpoint: 200px;
$grid-float-breakpoint-max: 199px;

$blue: #009DDC;

// 3. Include remainder of required Bootstrap stylesheets
@import '~bootstrap5/scss/_variables';
@import '~bootstrap5/scss/_variables-dark';
@import '~bootstrap5/scss/_mixins';
@import '~bootstrap5/scss/_maps';
@import '~bootstrap5/scss/_root';

// 4. Include any optional Bootstrap CSS as needed
@import '~bootstrap5/scss/_utilities';
@import '~bootstrap5/scss/_reboot';
@import '~bootstrap5/scss/_type';
@import '~bootstrap5/scss/_images';
@import '~bootstrap5/scss/_containers';
@import '~bootstrap5/scss/_grid';
@import '~bootstrap5/scss/_helpers';

@import '~bootstrap5/scss/_tables';
@import '~bootstrap5/scss/_forms';
@import '~bootstrap5/scss/_buttons';
@import '~bootstrap5/scss/_transitions';
@import '~bootstrap5/scss/_dropdown';
@import '~bootstrap5/scss/_button-group';
@import '~bootstrap5/scss/_nav';
@import '~bootstrap5/scss/_navbar';

@import '~bootstrap5/scss/_card';
@import '~bootstrap5/scss/_accordion';
@import '~bootstrap5/scss/_breadcrumb';
@import '~bootstrap5/scss/_pagination';
@import '~bootstrap5/scss/_badge';
@import '~bootstrap5/scss/_alert';
@import '~bootstrap5/scss/_progress';
@import '~bootstrap5/scss/_list-group';
@import '~bootstrap5/scss/_close';
@import '~bootstrap5/scss/_toasts';

@import '~bootstrap5/scss/_modal';
@import '~bootstrap5/scss/_tooltip';
@import '~bootstrap5/scss/_popover';
@import '~bootstrap5/scss/_carousel';
@import '~bootstrap5/scss/_spinners';
@import '~bootstrap5/scss/_offcanvas';
@import '~bootstrap5/scss/_placeholders';

// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import '~bootstrap5/scss/utilities/api';


// @import '~bootstrap5/scss/bootstrap';

html {

  .navbar {
    border: solid 1px rgba(0,0,0,0);
    & .navbar-nav > li a {
      color: $navbar-default-link-color;
      text-decoration: none;
    }
    min-height: $navbar-height;
    background-color: $navbar-default-bg;

    > a:first-child {
      margin-left: 15px;
    }
    .account-dropdown {
      margin-right: 15px;
    }
  }
}
