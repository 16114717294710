h1, h2, h3, h4, h5, h6, p, span {
  word-wrap: break-word;
}

h2, h3, h4, h5, p {
  color: $color-white;
}
.admin {
  h2, h3, h4, h5, p {
    color: $color-black;
  }
}

// p {
//   font-size: 18px;
// }

html {
  width: 100%;
  height: auto;
  background-color: $color-black-bg;
  color: $color-white;
}

body {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-color: $color-black-bg;
  color: $color-white;
  
  $record-tabs-background: #2f3242;
  $record-tabs-unselected-text-color: #fff;
  $record-tabs-selected-background: #20232C;
  $record-tabs-border-color: #20232C;
  $record-tabs-selected-color: #1ab595;
  @import "../../app/states/analytics/record-tabs.scss";

  $border-color: transparent;
  $odd-color: #383c41; 
  $even-color: #323439;
  $chart-color: #2b2d31;
  $cell-hover-color: #787d87;
  $text-color: #ffffff;
  $text-underline-color: rgba(255,255,255,0.3);
  $chart-border-color: rgba(255,255,255,0.2);
  $header-color: #ffffff;
  $header-background: #2c2f33;
  $table-border-color: transparent;
  $shadow-spread: 6px;
  $shadow-color: rgba(0,0,0,0.2);
  $font: "Open Sans", sans-serif;
  $totals-background-color: #202225;
  $totals-font-weight: normal;

  $scroller-width: 10px;
  $scroller-background-color: #686c7b;
  $scroller-grip-color: #9ea2af;
  $hover-text-color: #fff;
  $hover-background-color: #333;
      
  $aggregation-row-header-background: shade-color($even-color, 4%);
  $aggregation-row-header-color: #ccc;

  $chart-drop-color: tint-color($odd-color, 10%);

  $config-background: tint-color($odd-color, 10%);
  $config-foreground: $header-color;
  $config-border: #aaa;
  $number-cell-font: "Inconsolata", monospace;
  $font: "Inconsolata", monospace;
  $font-size: 14px;
  $number-cell-font-size: 14px;

  $base-row-height: 32px; 
  $base-row-padding: 7px;

  $column-choices-checkmark-background: #05ae7d;
  $column-choices-checkmark-color: #fff;
  
  @import "../../utilities/girdgrid/Style/GirdGrid.scss";
}

.app {
  position: relative;
  color: $color-grey;
  z-index: $app-z;
  &.container {
    padding-left: 0;
    padding-right: 0;
  }
  // &.container-fluid {
  //   max-width: 90%;
  //   margin-left: auto !important;
  //   margin-right: auto !important;
  // }
}

.container {
  max-width: 90%;
  // margin-left: 5%;
}

.uploadModal {
  color: $color-grey-68;
}
.drop-zone {
  background: $color-black-bg;
  color: $color-white;
}

.modal-content {
  color: #000;
  h1, h2, h3, h4, h5, p {
    color: #000;
  }
}

#sparkline .spark {
  stroke: $color-white;
  opacity: 1;
  stroke-width: 1.5px;
}

.d3-tip {
  background-color: #151515;

  &:after {
    color: #151515;
  }
  &:before {
    color: #151515;
  }

  .col-sm-4 {
    float: left;
    padding-left: 15px;

    &.padding-0 {
      padding-left: 0;
    }
  }
}

.treemapcontainer {
  cursor: pointer;
  height:100%;
}

.growl-container.growl-fixed {
  z-index: $growl-z;
}
.growl-item.ng-enter,
.growl-item.ng-leave {
    -webkit-transition: 1s linear all;
    -moz-transition: 1s linear all;
    -o-transition: 1s linear all;
    transition: 1s linear all;
}
// hide growl countdown numbers
.growl-item button[ng-show="showCountDown(message)"] {
  display: none;
}

button[aria-label="close"] {
  color: black;
}

body.md-dialog-is-showing {
    overflow: visible !important;
}

.selectize-control .ui-select-choices-row.active>a {
  background-color: $color-white !important;
  color: $color-dark-grey !important;
}

.selectize-control .ui-select-choices-row>a:hover {
  background-color: $color-light-dark-grey !important;
}

.selectize-control .ui-select-choices-row.active>a:hover {
  background-color: $color-light-dark-grey !important;
}

#patternbar {
  text {
    fill: $color-white !important;
  }
}

md-content {
  overflow: inherit;
}

json-editor {
  .well {
    background-color: inherit;
    border: 1px solid $color-white-1;
  }
  .form-control {
    background-color: $color-light-dark-grey !important;
  }
  .property-selector {
    color: $color-black;
  }
}
.min-full {
  min-height: calc(100vh - 25px);
}

/* add to home */
.ath-container {
  p {
    text-shadow: none;
  }
}
.dark-theme {
  .ath-container {
    background: #393E51;
  }
  .ath-container:before {
    background-color: white;
  }
  .ag-header-container {
    background-color: $color-black-bg !important;
  }

  
}

noscript.error-message {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 24px;
  transform: translate(-50%, -50%);
}
