.table.table-striped, .table {
  table-layout: fixed;
  td, th {
    word-break: break-word;
    // these three are part of bootstrap5 initialization(???) and they override tr stylings
    // if we don't reset them to inherit
    background-color: inherit;
    box-shadow: inherit;
    color: inherit;
  }
}

// temporary, until ag-grid figures out how we can give them money
.ag-watermark {
  display: none !important;
}

.table:last-child {
  margin-bottom: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: $color-black-bg;
}

.table-striped > tbody > tr:nth-of-type(even) {
  background-color: $color-grey-tile-darker;
}

@media print {
  .table-striped {
    thead>tr>th {
      background: #fff !important;
      color: #000 !important;
    }
    tbody>tr>td {
      background: #fff !important;
      color: #000 !important;
    }
  }
}

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 0;
}

.table .number-column {
  text-align: right;
}

.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td {
  padding: 8px;
  border-top: 0;
  line-height: 1.42857;
  vertical-align: top;
  font-size: 14px;
}

.table > tfoot > tr > th,
.table > tfoot > tr > td {
  color: $color-dark-grey;
  padding: 8px;
  line-height: 1.42857;
  vertical-align: top;
  font-size: 15px;
}

tr.table-active td,
tr.table-active a,
tr.table-active span {
  color: $color-white;
  background-color: $color-blue;
}

tr.pivot-table .table-active span {
  color: $color-white;
  background-color: $color-dark-grey;
}

tr.table-active:hover {
  color: $color-white;
}

.table thead tr {
  background-color: $color-black-1;
  color: #fff;
}

.table tbody {
  color: #fff;
}

.table-hover > tbody > tr:hover {
  background-color: $color-blue-hover !important;
  color: $color-dark-grey;
}

td.td-hover:hover {
  color: $color-white;
  background-color: $color-blue !important;
  cursor: pointer;
}

.table-active {
  background-color: $color-blue;
  color: $color-white;
}

thead th {
  background-color: transparent;
}

.table .pinned {
  position: absolute;
  z-index: $table-pinned-z;
  border-right: 1px solid $color-black-1;
}
.table .pinned-top-left {
  z-index: $table-pinned-top-z;
}
th.pinned {
  background-color: $color-light-dark-grey;
}
tbody tr:nth-of-type(even) .pinned {
  background-color: $color-white;
}
tbody tr:nth-of-type(odd) .pinned {
  background-color: $color-light-grey;
}
tfoot .pinned {
  background-color: $color-white;
}
.pinned-row {
  position: absolute;
  th {
    display: inline-block;
  }
}
table {
  position: relative;
}
.pinned-support {
  display: table;
}


table.fit {
  &>thead>tr>th, &>thead>tr>td, &>tbody>tr>th, &>tbody>tr>td {
    word-break:break-all;
  }
}

.table-striped tbody tr, .table-striped tbody td {
  border-style: none;
}


.for-sale-picket {
  width: 20px;
}
