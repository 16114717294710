.form-control {
  background-color: $color-white;
  color: $color-black;
  font-size: 14px;
  font-weight: normal;
  box-shadow: none;
  transition: border-color ease-in-out .15s, 
  box-shadow ease-in-out .15s;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 0;
}

.md-select-menu-container {
  z-index: $select-menu-container-z;
}

.schema-form-select {
  .md-select-placeholder span {
    font-weight: bold;
    margin-top: -15px;
    display: block;
  }
}

.reportProblemForm.modal {
  textarea {
    height: 85px;
    color: rgba(0,0,0,0.87);
    outline: none;
    margin-bottom: 20px;
  }
}

.form-group {
  margin-bottom: 15px;
}

.checkbox label {
  padding-left: 20px;
  min-height: 20px;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
}

.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;

  input[type="checkbox"] {
    position: absolute;
    margin-top: 4px;
    margin-left: -20px;
  }
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

.form-label {
  margin-bottom: 5px;
}

input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9; // IE8-9
  line-height: normal;
}
