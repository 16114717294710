#waterfallchart {
	padding-top:10px;
	height: 250px;
	margin-left: 30px;
	margin-right:10px;
}
#waterfallchart .d3-tip {
  line-height: 1;
  font-weight: bold;
  padding: 12px;
  background: $color-black;
  color: $color-white;
  border-radius: 2px;
}

/* Creates a small triangle extender for the tooltip */
#waterfallchart.d3-tip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: $color-black;
  content: "\25BC";
  position: absolute;
  text-align: center;
}

/* Style northward tooltips differently */
#waterfallchart .d3-tip.n:after {
  margin: -1px 0 0 0;
  top: 100%;
  left: 0;
}
#waterfallchart .xAxis path{
  fill:none;
  stroke: $color-white;
  stroke-width:1px;
}
#waterfallchart .xAxis line,
#waterfallchart .xAxis text {
  display: none;
}
#waterfallchart rect {
  stroke: $color-black;
  stroke-width: 0.02px;
}
#waterfallchart text {
  fill: $color-white;
}
#waterfallchart svg rect {
  stroke: $color-black;
  stroke-width: 0.1px;
}

#waterfallchart .income-title {
  padding-top: 5px;
  min-height: 25px;
  font-size: 16px;
  font-weight: 600;
}
