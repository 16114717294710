$summary-speeds-chart-height: 84px;
$summary-speeds-tab-height: 22px;
$cashflow-summary-header-height: 35px;
$summary-speeds-height: $summary-speeds-chart-height + $summary-speeds-tab-height + $cashflow-summary-header-height;

.navbar-nav.navbar-right> li.nav-contact > a {
  display: inline-block;
  background: #673ab7;
  font-size: 14px;
  border-radius: 5px;
  // margin: 10px;
  line-height: 14px;
  border: 1px solid hsla(262, 52%, 35%, 1);
  padding: 12px 15px;
  i {
    font-size: 20px;
    padding: 0;
    margin: -5px 10px -5px 0;
  }
}

.scenarios {

  .config-option-details {
    
    display: flex;
    flex-direction: column;

    form {
      padding: 10px;
    }

    .radio {
      margin-top: 0;
    }

    label.computed-label {
      display: block;
      font-weight: normal;
    }

    input[type="date"] {
      background: rgba(0,0,0,0);
      padding: 5px 10px;
      border: 1px solid rgba(255,255,255,0.2);
      margin: 10px 0;
      width: 100%;
      color: $color-white;
    }

    input[type="radio"] {
      margin-right: 5px;
    }

    .btn-default:hover:not([disabled]), {
      background-color: $color-dark-grey;
    }

    .option {
      text-align: right;
      padding: 5px;
      border: 1px solid rgba(255,255,255,0.2);
      margin-bottom: -1px;
      cursor: pointer;

      &.selected {
        background: rgba(255,255,255,0.1);
      }
    }

    .error {
      color: $color-red;
    }
  }

  .payscen-scenarios {
    height: calc(100vh - 136px);
    display: flex;

    .payscen-scenario-details {
      width: calc(100vw - 350px);
      overflow: auto;
    }
    .payscen-deal-details {
      width: 350px;
      overflow: auto;
      background: rgb(30, 33, 34);
    }

    .scenario-scroll-container {
      overflow-y: scroll;
      height: calc(100vh - 37px - 60px - 45px);

      .scenario {
        margin: 10px;
        padding: 10px;
        font-size: 12px;
        background: rgba(255,255,255,0.2);

        position: relative;

        .is-refreshing i {
          color: green;
          position: absolute;
          right: 0;
          font-size: 30px;
          top: 0;
        }
      }
    }

    .payscen-scenario-wrapper {
      flex: 1;
      overflow: auto;
      &.focused-scenario {
        .inner-payscen-scenario-wrapper {
          padding: 10px;
          display: block;

          .widget-payscen-scenario {
            margin: 0px;
          }
        }
      }
      .inner-payscen-scenario-wrapper {
        display: flex;
        padding-bottom: 150px;

        .add-scenario {
          font-size: 48px;
          outline: 0;
          position: sticky;
          top: 0;
          opacity: 0.1;
          height: 45px;
          line-height: 57px;
          cursor: pointer;
          &:hover {
            opacity: 0.3;
          }
        }
        
        .widget-payscen-scenario {
          margin: 10px 1px;
        }
      }
    }
  }

  .scenario-detail-header {
    background: rgb(46, 48, 53);
    padding: 10px;
    margin-bottom: 10px;
    position: sticky;
    top: 0;
    z-index: $scenario-header-top-z;
    box-shadow: 0 2px 2px rgba(0,0,0,0.2);

    a {
      background: rgba(255,255,255,0.1);
      padding: 8px;
      color: #fff;
      font-weight: 400;
      font-size: 11px;
      border-radius: 5px;
      text-transform: uppercase;
      margin-left: 10px;
      &:hover {
        background: rgba(255,255,255,0.2);
        i {
          opacity: 0.8;
        }
      }
      i {
        opacity: 0.5;
        margin-right: 5px;
      }
    }
    
    .close-action {
      position: absolute;
      right: 10px;
      top: calc(50% - 17px);
      font-size: 30px;
      color: #fff;
      line-height: 30px;
      cursor: pointer;
      i {
        opacity: 0.4;
      }
      &:hover {
        i {
          opacity: 1;
        }
      }

      >div, &:hover>div {
        display: inline-block;
        i {
          opacity: 0.4;
        }
        &:hover i {
          opacity: 1;
        }
      }
    }
  }

  .scenario-details {
    padding: 9px;
    overflow-x: hidden;

    .table-wrapper {
      z-index: $scenario-table-contents-z
    }


    .widget-tile {
      margin-bottom: 10px;
    }  
  }
  .beta-disclaimer {
    padding: 9px 20px;
    font-size: 1.5rem;
    background-color: rgba(11, 136, 186, .5);
    font-weight: 600;
  }

  .scenario-filters {
    padding-bottom: 10px;
  }
}

.refresh-board-dialog {
  h3 {
    margin-bottom: 35px;
    i {
      margin-right: 10px;
    }
  }
  .refresh-progress-wrapper {
    height: 50px;
    width: 100%;
    background: #525252;
    border-radius: 5px;

    .refresh-progress {
      background: rgb(5,174,125);
      height: 50px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      transition: width 0.5s linear;
    }
  }
}

.refresh-progress-wrapper {
  height: 20px;
  width: 100px;
  background: #525252;
  border-radius: 2px;
  display: inline-block;
  position: relative;
  margin-top: 10px;
  margin-bottom: -7px;

  .refresh-progress {
    background: rgb(5,174,125);
    height: 20px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    transition: width 0.5s linear;
  }
}

.board-last-updated {
  font-size: 12px;
  opacity: 0.5;
  margin-right: 10px;
}

.board-loading {
  padding: 150px;
  text-align: center;
  h1 {
    font-size: 20px;
    opacity: 0.6;
  }
  background: rgba(255,255,255,0.2);
  margin: 20px;
}

.board-edit-mode {
  margin: 20px;
  height: calc(100vh - 200px);

  .board-edit-status-row {
    display: flex;
    margin-bottom: 10px;
    .btn {
      background: #666;
      color: #fff;
    }
    .btn-default {
      background: #009DDC;
      color: #fff;
    }

    .gap {
      flex: 1;
    }
    .status {
      padding: 5px;
      line-height: 28px;
      margin-right: 10px;
      &.ok {
        color: #fff;
      }
      &.validating {
        color: #d9d98c;
      }
      &.updated {
        color: #40bf40;
      }
      &.problem {
        color: #bf4040;
      }
    }
  }

  .ag-cell, .ag-header-cell, .ag-rich-select-value {
    font-family: "Inconsolata", monospace;
    color: #fff;
  }

  .ag-grid .ag-row-odd {
    background-color: hsla(213, 7%, 20%, 1) !important;
  }

  .ag-row-focus {
    .ag-cell-focus {
      background-color: rgba(200,200,200,0.3);
    }
  }

  .ag-cell-output {
    background-image: linear-gradient(66deg, rgba(0,0,0,0.3) 25%, rgba(0,0,0,0.2) 25%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0.3) 50%, rgba(0,0,0,0.3) 75%, rgba(0,0,0,0.2) 75%, rgba(0,0,0,0.2) 100%); 
    background-size: 10.95px 24.59px;
    color: #aaa;
  }

  .ag-cell-input-disabled {
    // this is a huge PITA to get to line up correctly to cell size....
    background-image: linear-gradient(66deg, rgba(30,30,30,0.7) 25%, rgba(30,30,30,0.9) 25%, rgba(30,30,30,0.9) 50%, rgba(30,30,30,0.7) 50%, rgba(30,30,30,0.7) 75%, rgba(30,30,30,0.9) 75%, rgba(30,30,30,0.9) 100%);
    background-size: 10.95px 24.59px;
    color: #777;
  }

  .ag-cell-status-changed {
    background: #696;
  }
  .ag-cell-status-validating {
    background: #993;
  }
  .ag-cell-status-problem {
    background: #933;
  }

  #ag-grid .ag-cell-input .defaultValue {
    color: rgba(255,255,255,0.4);
  }
}
.ag-virtual-list-container, .ag-virtual-list-viewport {
  background: #222;
}

.board-container {

  .payscen-board-scenario-field {
    background: rgba(0,0,0,0.3) !important;

    &.payscen-board-scenario-solveBy {
      color: #05ae7d;
      font-weight: bold;
    }

    &.payscen-board-scenario-field-default {
      color: rgba(255,255,255,0.4);
    }
  }

  .material-icons.refreshing {
    color: #05ae7d;
    text-decoration: none;
    margin-right: 5px;
    display: inline-block;
  }

  .material-icons.updating {
    color: #999;
    text-decoration: none;
    margin-right: 5px;
    display: inline-block;
  }

}

