.navbar .container-fluid {
  padding-right: 0;
}
.navbar .watermark {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 9px;
  opacity: 0.015;
  color: $color-white;
}
.navbar-brand {

  // padding: 12px 15px;

  img {
    height: 2.25rem;
  }

  &.navbar-brand-with-headline {
    img {
      height: 24px;
      margin-top: -3px;
    }

    .navsubheadline {
//       margin-top: 1px;
      // display:inline-block;
//       vertical-align: top;
//       margin:0px;
//       outline:none;
      font-size: 14px;
      font-weight: 400;
    }

  }
}

ul.navbar-nav > li > .dropdown > a > i.material-icons {
  font-size: 27px;
  padding-top: 5px;
  padding-bottom: 4px;
}

.navbar {
//   .navbar-right {
//     display: flex;
//     >li {
//       float: none;
//       a {
//         padding: 12px 7px;
//       }
//       &:last-child {
//         a {
//           margin-right: 7px;
//         }
//       }
//     }
//   }

  select {
    appearance: none;
    -webkit-padding-end: 18px !important;
    width: 218px;
    height: 41px;

    margin: 5px 18px 10px 18px;
    padding: 10px;
    border: 1px solid #999;
    border-radius: 3px;
    background: rgba(255,255,255,0.05);
    background-image: url("../../../assets/img/down-arrow-transparent.png");
    background-repeat: no-repeat;
    background-position: center right;
    box-shadow: none;
    color: #999;

    &:active {
      background: #fff;
      color: #000;
    }
  }

  #themetoggle {
    >div {
      margin: 10px;
      width: calc(100% - 20px);
    }

    .btn {
      background-color: rgba(255,255,255,0.1);
    }
    .btn.active {
      background-color: rgba(255,255,255,0.3);
      box-shadow: inset 0 3px 5px $color-black-3;
    }
    label.btn {
      width: 50%;
      // height: 30px;
    }
  }

  #pivottoggle {
    >div {
      margin: 10px;
      width: calc(100% - 20px);
    }

    .btn {
      background-color: rgba(255,255,255,0.1);
    }
    .btn.active {
      background-color: rgba(255,255,255,0.3);
      box-shadow: inset 0 3px 5px $color-black-3;
    }
    label.btn {
      width: 50%;
      height: 30px;
    }

    .dropdown-label {
      color: #fff;
      padding: 11px 22px 0 22px;
      display: block;
      font-weight: 600;
    }

    label.btn i.material-icons {
      color: $color-white
    }
  }

//   .dropdown-menu {
//     left: auto;
//     right: 0;
    
//     >li>a {

//       i {
//         margin-right: 5px;
//       }
//     }

//     &.no-scroll {
//       overflow: hidden;
//     }
//   }


}
