// .modal-dialog.modal-xl {
//   width: 80vw;
//   min-width: 900px;
// }
.model-editor.appModal {
  // text-align: left;
  // padding: 10px;

  & .modal-dialog {
    min-height: 80vh !important;
    min-width: 80vw;
    display: inline-flex;
  }

  .nav-tabs>li {
    &>a {
      color: $color-grey !important;
      margin-right: 2px;

    }
    &.active>a {
      color: $color-blue !important;
    }
  }

  .scenario-details {
    padding: 10px;
    padding-top: 0;

    .widget-tile {
      margin-bottom: 10px;
    }
  }
  
  .nav-tabs>li>a, .nav-tabs>li>a {
    border-color: #2c2f33 !important;
  }

  .errors {
    padding: 10px;
    
    .error {
      background: tint-color($color-red, 10%);
      color: #333;
      border: $color-red;
      margin-bottom: 10px;
      padding: 10px;
    }
  }

  .nav-tabs {
    border-bottom: $color-blue;
  }

  .modal-content {
    background-color: $color-black;
    padding: 10px;
    min-height: 80vh;
    min-width: 80vw;
    text-align: left;
  }

  .editor {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 43px;

    .CodeMirror {
      height: 100%;

      .CodeMirror-dialog {
        position: absolute;
        bottom: 10px;
        left: 55px;
        z-index: 2;
        background-color: #383c41;

        input {
          background-color: transparent;
          outline: none;
        }
      }
    }
  }

  .editor-container {
    position: absolute;
    top: 79px;
    bottom: 10px;
    right: 0px;
    left: 0px;

  }

  .actions {
    position: absolute;
    bottom: 0;
    justify-content: center;
    width: 100%;

    .btn {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
      &.btn-warning {
        background-color: #2a2928;
        &:hover,&:focus,&:active {
          background-color: #ef5a1f;
        }
      }
      &.btn-secondary {
        background-color: #2a2928;
        &:hover,&:focus,&:active {
          background-color: $color-blue;
        }
        border: solid 1px $color-blue;
      }
    }
  }

  .name-container {
    color: white;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 600;
  }

  .parameter-container {
    overflow-y: auto;
    bottom: 50px;
    padding-top: 10px;
  }
}