.tile-property-tax-assessor {
  .assessor-row {
    padding-right: 10px;
    .assessor-value {
      color: $color-blue;
      font-weight: bold;
      text-align: right;
    }
    .assessor-status {
      font-weight: bold;
      text-align: right;
    }
  }
}