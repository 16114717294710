.tile-property-valuation {
  .valuation-row {
    padding-right: 10px;
    .valuation-value {
      color: $color-blue;
      font-weight: bold;
      text-align: right;
    }
  }
  .widget-site-links {
    margin-top: 10px;
  }
}
