.searchbox {
  @media (max-width: 500px) {
    display: none;
  }

  &.visible-xs {
    input {
      width: 100%;
    }

    .dropdown-menu {
      border-radius: 0;
    }
  }

  input::placeholder {
    color: $color-white;
    opacity: 0.5;
  }

  .mobile-search {
    margin-top: 1.3em;
  }

  .searchicon {
    color: $color-white;
    // position: absolute;
    // top: 11px;
    // font-size: 15px;
    // right: 10px;
    z-index: $search-icon-z;
  }

  position: relative;
  list-style: none;

  // .navbar & {
  //   margin: 12px 18px;
  //   margin-right: 0;
  // }
  .input-group {
    padding-bottom: 8px;
    height: 36px;

    width: 100%;

    border: 1px solid rgba(255, 255, 255, 0.441);
    border-radius: 3px;
    color: $color-white;

    @media (max-width: 400px) {
      width: 35px;

      &:focus {
        width: 100%;
      }
    }

    @media (min-width: 401px) and (max-width: 430px) {
      width: 55px;

      &:focus {
        width: 100%;
      }
    }

    @media (min-width: 431px) and (max-width: 500px) {
      width: 88px;

      &:focus {
        width: 100%;
      }
    }

    @media (min-width: 501px) and (max-width: 767px) {
      width: 155px;
    }

    @media (min-width: 600px) and (max-width: 659px) {
      width: 255px;
    }

    @media (min-width: 660px) {
      width: 325px;
    }

    &:focus {
      border: 2px solid $color-bluegrey;
    }

    input {
      background-color: rgba(255, 255, 255, 0.1);
      border: none;
      color: $color-white;
    }

    .input-group-text {
      border: none;
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  .loadingicon {
    color: $color-white;
    // position: absolute;
    // top: 11px;
    // right: 32px;
    z-index: $loading-icon-z;
  }

  .results,
  .no-results {
    width: 100%;

    html &.dropdown-menu {
      display: block;
      left: 0;

      max-height: 400px;
      min-height: 50px !important;

      a.dropdown-item {

        padding-bottom: 4px;
        padding-top: 4px;
        padding-left: 12px;
        padding-right: 10px;
        white-space: normal;
        color: $color-white;
        border-bottom-color: rgba(255, 255, 255, 0.2);

        &.focused {
          background: rgba(255, 255, 255, 0.2);
        }

        &:last-child {
          border-bottom: none;
        }

        strong,
        em {
          color: $color-blue !important;
          font-weight: bold;
          font-style: normal;
        }

        div.value {
          line-height: 1.2;
          word-break: break-all;
        }

        .subtype {
          font-size: 10px;
          line-height: 1;
          padding-bottom: 4px;
        }
      }
    }
  }

  .no-results.dropdown-menu {
    .dropdown-item {
      padding-left: 12px;
      padding-top: 8px;
    }
  }
}

html .navbar .searchbox .dropdown-menu {
  max-height: 90vh !important;
}

.searchbox .dropdown-menu {
  box-shadow: 0 2px 8px rgba(255, 255, 255, 0.3);
  background: $color-black-bg;

  .dropdown-item {
    border-bottom-color: rgba(255, 255, 255, 0.2);
  }

  .search-title,
  .search-title:hover,
  .dropdown-item a {
    color: #fff !important;
    background: $color-black-bg;
  }

  .dropdown-item a:hover {
    background: rgba(255, 255, 255, 0.1);
  }
}