.history.modal {
  .modal-body {
    background-color: white;
    margin: 20px;

    .entry {
      text-align: left;
      margin: 20px 0;
      
      h4 {
        margin: 0;
        font-size: 16px;
      }
    }
  }
  .modal-content {
    background-color: #f5f5f5
  }
}
.modal {
  .modal-title {
    width: 100%;
  }
}
