.social-icons {
  display: inline-block;
}

.social-icons a {
  margin-bottom: 10px;
  margin-right:15px;
}

.social-icons a img {
  width: 30px;
  margin-top: 5px;
}