.amortization {
  width: 100%;
  height: 450px;
  bottom: 50px;
  margin-top: 5px;
  background: $color-grey-tile;
  color: $color-white;
}

.amortizationChart {
  text {
    fill: $color-white !important;
  }
}

.amortization .amortizationChart {
  width: 95%;
  height: 75%;
}

.amortization #title {
  position: relative;
  top: 10px;
  font-size: 14px;
}
.amortization {
  font-size: 14px;
  line-height: 16px;
}
.legend .left {
  display: inline-block;
}
.legend .right {
  display: inline-block;
  float: right;
}
#interest, #principal, #monthlypayment, #monthid {
  padding-top: 10px;
}

.amortization .yaxis path{
  display: none;
}

.amortization .yaxis text{
  font-size: 11px;
}
