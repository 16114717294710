#customBuckets {
  .member {
  }
  .default {
    font-weight: bold;
  }
  .show-buckets-button, .show-editor-button {
    cursor: pointer;
    color: $color-blue;
    &:focus {
      outline: none;
    }
  }
  .show-editor-button {
    margin-left: 5px;
  }
  
  ul {
    list-style-type: none;
    padding-left: 0;
    li {
      i {
        margin-right: 6px;
        position: relative;
        top: -2px;
        color: $color-green;
        &.unselected {
          visibility: hidden;
        }
      }
      margin-left: 23px;
      text-indent: -12px;
      margin-bottom: 2px;
      line-height: 1em;
    }
  }
}