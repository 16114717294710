@media print {
  @page {
    size: 35cm 49.5cm;
  }

  body, .apply-background {
    background: #fff !important;
    color: #000 !important;
  }
  
  a[href]:after {
    content: "" !important;
  }
  .a-link {
    color: #000 !important;
  }

  .navbar .navbar-nav {
    display: none;
  }
  .navbar .navbar-brand {
    filter: invert(100%);
  }

  .report-problem {
    display: none !important;
  }

  #navbar, #navbar.navbar-collapse {
    background-color: #fff !important;
  }
  navigation .navbar {
    background-color: #fff !important;
    position: static !important;
    margin-bottom: 0;
    display: block;
    float: none !important; // wtf TSI
  }

  #breadcrumb, #breadcrumb a {
    border-bottom: 0 !important;
    color: #666;
    background-color: #fff !important;
  }

  .overflow-hidden-print {
    overflow: hidden;
  }
  .leaflet-bar {
    display: none;
  }
  .leaflet-control-attribution {
    font-size: 6px !important;
  }
  .force-visible-print {
    display: block !important;
  }
  #ag-grid, .ag-pinned-left-cols-viewport, .ag-body-viewport-wrapper, .ag-body-viewport {
    min-height: 1450px !important;
    height: 1450px !important;
  }
  #ag-grid .ag-bl-full-height-center, #ag-grid .ag-bl-full-height {
    height: inherit;
  }
  #ag-grid #borderLayout_eRootPanel {
    width: 100%;
  }
  #ag-grid .ag-cell {
    background: inherit;
  }
  #ag-grid .ag-header-cell.Actions, .ag-cell[colid="actions"] {
    display: none;
  }
  
  html comments {
    .new-comment, .show-notes, a[ng-click="deleteComment(comment)"] {
      display: none !important;
    }
    .container-fluid {
      background: inherit !important;
    }
    .container-fluid .editable {
      color: inherit !important;
    }
  }

  div .tiles, .widget-tile { // blahhhh
    page-break-inside: avoid;
  }

  div .tiles, .masterCards #personCard, .masterCards #quality {
    background-color: #fff !important;
    border: 1px solid #ccc !important;
    color: #000 !important;
  }

  .masterCards .profile-pic {
    display: none;
  }
  .masterCards #quality {
    display: none;
  }
}
