tile-building-details {

  ul.amenities {
    padding: 0;
    margin: 0;

    li {
      list-style-type: none;
    }

  }
}

