.fill-parent {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.display-none {
  display: none;
}

.display-inherit {
  display: inherit;
}

.display-inline {
  display: inline;
}

.display-inline-block {
  display: inline-block;
}

.display-block {
  display: block;
}

.display-flex {
  display: flex;
}

.display-inline-flex {
  display: inline-flex;
}

.full-width {
  width: 100%;
}

.height-150 {
  height: 150px;
}

.height-100 {
  height: 100%;
}

.full-width {
  width: 100%;
}

.width-100 {
  width: 100%;
}

.overflow-auto {
  overflow: auto;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.hide-scrollbars {
  &::-webkit-scrollbar { 
    display: none;
  }
}

.enable-scrollbar {
  ::-webkit-scrollbar { 
    display: block !important; 
  }
}

.overflow-hidden {
  overflow: hidden;
}

.position-relative {
  position: relative;
}

.position-fixed {
  position: fixed;
}

.break-wrap {
  overflow-wrap: break-word
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overflow-ellipsis-with-break {
  display: -webkit-box;
  width: 100%;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inline {
  display: inline;
}
// floats
.float-start {
  float: left;
}

.float-right {
  float: right;
}
