// Modal
.modal {
  text-align: center;
  .container {
    max-width: 100%;
  }
}
// .modal-backdrop:nth-child(2n-1) {
//   opacity: 0;
// }

@media screen and (min-width: 768px) { 
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
}

.modal-dialog {
  display: inline-block;
  vertical-align: middle;
  min-width: 400px;

  .modal-header {
    background-color: #f5f5f5;
    border-bottom: none;
    padding: 16px 16px 0;
  }

  .modal-body {
    background-color: #f5f5f5;
    font-size: 20px;
    padding: 20px;
  }

  .modal-footer {
    border-top: none;
    justify-content: center;
    padding: 20px;
  }
}
.modal-content {

  color: #000;
  h1, h2, h3, h4, h5, p {
    color: #000;
  }
  border-radius: 5px;
  overflow-y: auto;
  pre {
    background-color: #f5f5f5;
    border: solid 1px rgb(204, 204, 204);
    padding: 10px;
  }
}

.modal-content a{
  text-align: center;
  min-width: 60px;

}

.modal.fade {
  background: rgba(0, 0, 0, 0.5);
}

.modal-backdrop.fade {
  opacity: 0;
}

.appModal {
  md-toolbar.modal-toolbar {
    height: auto;
    min-height: 130px;
    color: $color-dark-grey !important;
    font-size: 24px !important;
    background-color: $color-light-grey !important; 
  }

  md-toolbar .md-button {
    color: $color-dark-grey !important; 
  }

  h4 {
    font-size: 16px !important;
  }

  font-size: 16px;
  max-height: 90% !important;
  overflow: auto;

  form.notification {
    label {
      font-weight: normal;
      margin-left: 5px;
    }
  }

}

.credit-report-modal {
  .modal-header {
    button {
      margin-right: 10px;
    }
  }
  @media (min-width: 992px) {
    .modal-dialog.modal-lg {
      width: 900px;
    }
    .modal-content {
      width: 900px;
    }
  }
}
