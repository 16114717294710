.btn img {
  position: relative;
}

.btn {
  border-radius: 3px;
  color: $color-white;
  font-size: 12px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: inherit;
  font-weight: 700;
  height: 36px;
  padding: 5px 8px;
  -webkit-transition: background-color .2s;
  transition: background-color .2s;
}

.btn-export {
  height: 28px;
  padding: 0 6px 2px 6px;
}

.btn-navigator {
  font-size: 14px;
  width: 100%;
  padding: 7px;
}

.btn-small {
  width: 50px;
  font-size: 14px;
  padding: 7px 10px;
}

.btn-large {
  width: 120px;
  height: 50px;
  font-size: 16px;
  padding: 7px 10px;
}

.btn-regular {
  font-size: 12px;
  padding: 7px 10px;
}

// .btn:hover,
// .btn:focus,
// .btn:active {
//   background-color: $color-light-grey;
//   color: $color-white;
// }
.btn.no-hover:hover,
.btn.no-hover:focus,
.btn.no-hover:active {
  background-color: buttonface;
}
.btn-primary {
  min-width: 60px;
}
.btn-primary.btn-blue {
  background-color: $color-blue;
  color: $color-white;
  border: 1px solid $color-blue;
}

.btn-blue {
  background-color: $color-blue;
  color: $color-white;
  border: 1px solid $color-blue;
}

.btn-secondary {
  background-color: $color-light-dark-grey;
  color: $color-blue;
  border: 1px solid $color-blue;
}

.btn[disabled] {
  cursor: not-allowed;
  pointer-events: inherit;
  color: $color-white;
  opacity: 0.65;
}

.btn-grey {
  background-color: $color-grey-170;
  color: $color-white;
}

.btn-primary:hover:not([disabled]), .btn-blue:hover:not([disabled]),
.btn-primary:focus:not([disabled]), .btn-blue:focus:not([disabled]),
.btn-primary:active:not([disabled]), .btn-blue:active:not([disabled]) {
  background-color: $color-light-blue;
  color: $color-white;
  border: 1px solid $color-light-blue;
}
.btn-secondary:hover:not([disabled]),
.btn-secondary:focus:not([disabled]),
.btn-secondary:active:not([disabled]) {
  background-color: $color-light-grey;
  color: $color-blue;
  border: 1px solid $color-blue;
}
.btn-grey:hover:not([disabled]),
.btn-grey:focus:not([disabled]),
.btn-grey:active:not([disabled]) {
  background-color: $color-blue;
  color: $color-white;
  border: 1px solid $color-blue;
}

.btn-success {
  background-color: $color-green;
  border: 1px solid $color-green;
}

.btn-success:hover:not([disabled]), .btn-success:active:not([disabled]), .btn-success:focus:not([disabled]) {
  background-color: $color-green;
  color: $color-white;
  border: 1px solid $color-green;
}

.btn-success:active:hover:not([disabled]), .btn-success:active:focus:not([disabled]) {
  background-color: $color-green;
  color: $color-white;
  border: 1px solid $color-green;
}

.btn-default {
  min-width: 60px;
  background-color: $color-light-dark-grey;
  color: $color-blue;
  border: 1px solid $color-blue;
}
.btn-default:hover:not([disabled]), .btn-default:focus:not([disabled]), .btn-default:active:not([disabled]) {
  color: $color-blue;
  border: 1px solid $color-blue;
  background-color: $color-light-grey;
}

.btn-default-focus {
  box-shadow: none;
  outline: none;
}

.btn-default:active,
.btn-default.active,
.open > .btn-default.dropdown-toggle {
  background-color: $color-light-grey;
  border-color: $color-grey;
  color: $color-dark-grey;
}

.btn:focus,
.btn.focus,
.btn:active:focus,
.btn:active.focus,
.btn.active:focus,
.btn.active.focus {
  outline: 0;
}

.btn-wrap {
  white-space: normal;
  height: auto;
}

.btn-info {
  font-size: 14px;
  font-weight: 600;
  color: $color-white !important;
  background-color: $color-green !important;
  float: right;
}