.widget-label-value-simple {
  line-height: 14px;
  font-size: 12px;
  margin-bottom: 3px; 
  
  &:last-child {
    margin-bottom: 0;
  }
  
  .widget-label {
    font-weight: 600;
  }

  .transcluded-value {
    margin-left: 5px;
  }

  i.material-icons {
    vertical-align: -3px;
    opacity: 0.5;
  }

  .widget-value:hover {
    i.material-icons {
      opacity: 1;
    }
  }
}