.name-editor.appModal {

  .modal-dialog .modal-content {
    background-color: $color-black;
    padding: 30px 0 20px;
    color: white;
    font-size: 18px;
  }

  .modal-footer {
    border: none;;
  }
  

  // .actions {
  //   margin-top: 50px;

  //   .btn {
  //     margin-right: 10px;

  //     &:last-child {
  //       margin-right: 0;
  //     }
  //   }
  // }

  label {
    margin-right: 5px;
    font-weight: initial;
  }

  input[type="text"] {
    background-color: rgba(255,255,255,0.05);
    border: solid 1px rgba(255,255,255,0.1); 
    color: white;
    font-size: 18px;
    // line-height: 23px;
    // padding: 10px;
    // margin-bottom: 10px;
    // width: 226px;
  }

  input[type="checkbox"] {
    margin-right: 8px;
    position: relative;
    top: -2px;
  }

  .error {
    color: red;
  }

}