.tile-house-proud {
  .proud-factor {
    word-break: break-word;
    hyphens: auto;
    display: flex;
    padding-right: 20px;
    .thumb {
      position: relative;
      top: -3px;
      margin-right: 4px;
    }
    span {
      line-height: 1.2em;
    }
  }
}