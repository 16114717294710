.img-squared {
  position: relative;
}
.img-squared {
  content: "";
  display: block;
  padding-top: 100%;
}

.img-responsive-width {
  width: 100%;
  height: 100%;
}

.img-responsive-square {
  height:100% !important;
  position:absolute;
  left:0;
  top:0;
  width: 100%;
  height: 100%;
}
.square-content{
  width:100%;
  padding-bottom:100%;
  overflow:hidden;
  position:relative;
}

.square-content img {
  position:absolute;
  width:auto;
  min-width:100%;
  min-height:100%;
}