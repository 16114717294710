$summary-speeds-chart-height: 84px;

.root-html-theme .summary-speeds {
  .empty {
    height: $summary-speeds-chart-height;
  }
  .nav-tabs {
    border-bottom: inherit;

    > li.active {
      > a, > a:hover, > a:focus {
        color: #fff;
      }
      > a, a:hover {
        border: solid 1px #383c41 !important;
        border-bottom: 1px solid #fff !important;
      }
    }
    > li > a {
      padding: 2px 15px;
    }
    > li {
      > a {
        color: #999;
      }
      > a:hover {
        border: solid 1px $color-blue !important;
      }
    }
  }
  .multigroup {
    .nav-tabs >  li > a {
      font-size: 10px;
      padding: 2px 2px;
    }
  }
  .speedsummary {
    height: $summary-speeds-chart-height;
  }


  $mbs-darkgrey: #393E51;
  $mbs-backgroundgrey: #23252a;
  $mbs-nav-tab-border: $mbs-backgroundgrey;
  $mbs-purplegrey: #898EA0;
  $mbs-black: shade-color($mbs-darkgrey, 5%);
  $mbs-grey: tint-color($mbs-darkgrey, 5%);
  $mbs-nav-tab-border: $mbs-backgroundgrey !default;
  $mbs-nav-tab-active-bg: $mbs-darkgrey !default;
  $mbs-nav-tab-active-border: $mbs-darkgrey !default;
  $mbs-blue: #4EB6CD !default;

  .nav-tabs>li>a {
    background-color: $mbs-darkgrey !important;
    border: 1px solid $mbs-nav-tab-border !important;
    border-bottom: 1px solid $mbs-black;
    &:hover,&:focus {
      border: 1px solid $mbs-purplegrey !important;
      border-bottom: 1px solid $mbs-black;
    }
  }

  .nav-tabs>li.active>a {
    background-color: $mbs-grey !important;
    border: 1px solid $mbs-grey !important;
    border-bottom: 1px solid $mbs-blue !important;
    &:hover,&:focus {
      background-color: $mbs-grey !important;
      border: 1px solid $mbs-grey !important;
      border-bottom: 1px solid $mbs-blue !important;
    }
  }

  .nav-tabs .ui-select-toggle {
    background-color: $mbs-darkgrey !important;
    border: 1px solid $mbs-darkgrey !important;
    border-bottom: 1px solid $mbs-black !important;
    &:hover, &:focus {
      color: $mbs-darkgrey !important;
      background-color: $mbs-nav-tab-active-bg !important;
      border: 1px solid $mbs-nav-tab-active-border !important;
      border-bottom: 1px solid $mbs-black !important;
    }
  }

}
