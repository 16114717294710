.tile-market-area-analysis {
  .charts {
    position: relative;

    &.loading {
      .widget-split-column {
        opacity: .3;
      }
      .loading-indicator {
        .loadingicon {
          display: block;
        }
      }
    }
    .loading-indicator {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .loadingicon {
        display: none;
      }
    }
  }
  .legend {
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: 4rem;
    .color {
      border: solid 1px white;
      width: 10px;
      height: 10px;
      float: left;
      margin: 2px 5px 0 0;
    }
    .inner {
      margin: 0 auto;
      width: 85px;

    }
    .text {
      margin-left: 15px;
      line-height: 1.5rem;
      margin-bottom: 2px;
    }

  }
  .distances {
    list-style: none;
    display: flex;
    padding: 0;

    .distance {
      flex: 1;
      text-align: center;
      background: #eee;
      border: 1px solid #999;
      border-left-width: 0;
      color: #666;
      cursor: pointer;
      padding: 10px;
      outline: none;

      &.selected {
        background: #337ab7;
        color: #fff;
      }

      &:first-child {
        border-left-width: 1px;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
      &:last-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }

}