.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.flex-item {
  flex: 1;
}
.flex-item-top {
  align-self: flex-start;
}
.flex-item-bottom {
  align-self: flex-end;
}
.flex-item-that-will-be-centered {
  width: 100%;
}
