.interactiveline .inputbox {
  width: auto;
  padding-left: 0;
  margin-left:0;
  margin-right:0;
  margin-top:10px;
  appearance: none;
  border: none;
}
.interactiveline .inputbox:focus{
  border-bottom: solid 2px $color-blue;
  animation: none !important;
  outline: none;
}
.interactiveline #toblink {
  appearance: none;
  animation: blink-empty 1s infinite;
  border-left: transparent solid 1px;
  transition: border 0.3s;
}
.interactiveline #toblinke:focus{
  border-bottom: solid 2px $color-blue;
}

@keyframes blink-empty {
  50% {border-left: 1px solid #333;}
}

.interactiveline .xAxis line,
.interactiveline .xAxis text {
  display: none;
  fill: none;
  stroke: $color-grey-170;
  shape-rendering: crispEdges;
}
.interactiveline .xAxis path {
	fill:none;
	stroke: $color-grey-170;
	stroke-width: 1px;
}
.interactiveline .yAxis line,
.interactiveline .yAxis text {
  display: none;
  fill: none;
  stroke: $color-grey-170;
  shape-rendering: crispEdges;
}
.interactiveline .yAxis path {

	stroke: $color-grey-170;
	stroke-width: 1px;
}

.interactiveline .line {
	fill: #62D7AA;
}


.interactiveline #defaultLikelihood {
	height:30px;
}
.interactiveline #prepayment {
	height:30px;
}
.interactiveline #loss {
	height:30px;
}
.interactiveline #discountRate {
	height:150px;
	margin-top:5px;
}
