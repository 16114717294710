.quality-score {
  .quality-value {
    display: flex;
    padding-right: 15px;

    .quality-value-label {
      word-break: break-word;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
      white-space: nowrap;
      flex: 1;
      padding-left: 3px;
    }
    .quality-value-icon {
      width: 20px;
      text-align: center;
    }
  } 
}