a {
  font-weight: 600;
  color: $color-blue;
  position: relative;
  z-index: $link-z;
  cursor: pointer;
  text-decoration: none;
}

a:focus, a:hover {
  color: $color-blue;
  text-decoration: none;
}

.a-link {
  font-weight: 500;
  color: #fff;
  position: relative;
  z-index: $link-z;
  cursor: pointer;
}

.a-link.white {
  color: $color-white;
}

.a-link:focus, .a-link:hover {
  color: $color-blue;
  text-decoration: none;
}
a.unstyled {
  border: none !important;
  padding: 0 !important;
}

/*pointer*/
.no-pointer {
  cursor: default !important;
}
.pointer-none {
  pointer-events: none !important;
}
.cursor-default {
  cursor: default !important;
}