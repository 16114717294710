.tile-servicing-notes {
  .pagination-controls {
    text-align: right;
    margin-top: 10px;
    display: block;
  }
  tr.selected {
    background: #b4dbf8 !important;
    color: #000;
  }
  ul.keyword-list {
    padding: 0;

    li {
      list-style: none;
      margin-bottom: 5px;

      .keyword-list-label {
        font-size: 12px;
        font-weight: 600;
        margin: 0;
      }
      .keyword-list-usage {
        flex: 1;

        .keyword-usage:after {
          content: ' | ';
        }
        .keyword-usage:last-child:after {
          content: '';
        }
        .keyword-usage a {
          white-space: nowrap;
          font-weight: 400;
        }
      }
    }
  }
}