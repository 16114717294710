$scenario-background: #303336;

.speedsummary {
  .axis path, 
  .axis line {
    fill: none;
    stroke: #999;
    shape-rendering: crispEdges;
  }
  text {
    fill: #fff;
    font-size: 7pt;
    &.dropshadow {
      stroke: $scenario-background;
      stroke-width: 4;
    }
  }
  .dataline {
    fill: none;
    stroke: steelblue;
    stroke-width: 1.5;
  }
  .month12 {
    stroke: moccasin;
  }
  .month24 {
    stroke: brown;
  }
  .month36 {
    stroke: orange;
  }
  .last {
    stroke: green;
  }
  .vert {
    stroke-dasharray: 2;
    stroke-width: 2;
  }
  .horiz {
    stroke-width: 2;
  }
  .focused {
    display: none;
    margin: 0 15px;
  }
  .focused-scenario & {
    .std {
      display: none;
    }
    .focused {
      display: block;
    }
  }
}
