.widget-record-cycler {
  .arrow {
    outline: 0;
    background: rgba(60,60,60,0.75);
    color: #fff;
    height: 50px;
    cursor: pointer;
    width: 50px;
    position: fixed;
    top: 50%;
    border-radius: 25px;
    text-align: center;
    z-index: $cycle-arrow-z;
    box-shadow: 2px 3px 5px rgba(0,0,0,0.3);
    &.move-right {
      right: 10px;
    }
    &.move-left {
      left: 10px;
      i {
        margin-left: -4px;
      }
    }
    i {
      font-size: 40px;
      font-weight: bold;
      text-align: center;
      line-height: 52px;
      text-shadow: 2px 4px 5px rgba(0,0,0,0.3);
    }
    &:hover {
      background: rgba(128,128,128,1);
    }
  }
}
