.dashboard-view {
  .contents {
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;

    > * {
      display: block;
      width: calc(33.33% - 10px);
      margin-right: 10px;
      margin-bottom: 10px;

      @media (max-width: 1023px) {
        width: calc(50% - 10px);
      }

      @media (max-width: 800px) {
        width: calc(100% - 10px);
      }
    }
    margin-right: -10px;
  }
}