/**
 *  If you want to override some bootstrap variables, you can change values here.
 *  bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_variables.scss
 */

@import 'scss/colors';
@import 'scss/z-index';
$scenario-background: #303336;

$mbs-backgroundgrey: #23252a;
$summary-speeds-chart-height: 84px;
$summary-speeds-tab-height: 22px;
$cashflow-summary-header-height: 35px;
$summary-speeds-height: $summary-speeds-chart-height + $summary-speeds-tab-height + $cashflow-summary-header-height;

// $icon-font-path: '../../fonts/bootstrap/';
// $fa-font-path: '/fonts';s
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import 'bootstrap';


@import 'scss/**/*.scss';
@import '../app/directives/**/*.scss';
@import 'services/**/*.scss';
@import '../app/services/**/*.scss';
@import 'states/**/*.scss';
@import '../app/states/**/*.scss';

$header-color: #ffffff;
$font: "Inconsolata", monospace;
$number-cell-font: "Inconsolata", monospace;

$header-background: #343b46;
$border-color: transparent;
$scroller-background-color: #f0f0f0;
$scroller-grip-color: #ddd;

$aggregation-row-header-background: shade-color(#f5f5f5, 7%);
$aggregation-row-header-color: #666;

$font-size: 14px;
$number-cell-font-size: 14px;

$base-row-height: 32px; 
$base-row-padding: 7px;

$column-choices-checkmark-background: #05ae7d;
$column-choices-checkmark-color: #fff;

@import "../utilities/girdgrid/Style/GirdGrid.scss";
@import "~@selectize/selectize/dist/css/selectize.css";