vertical-divider {
  border-left: 1px solid rgba(255,255,255,0.3);
  width: 15px;
  min-width: 15px;
  margin-left: 14px;
}

md-divider.comfy-divider {
  margin: 20px 0;
}

.dropdown-menu {
  max-height: 400px;
  overflow-y: auto;
  a.dropdown-item > i {
    margin-right: 5px;
  }
}

.leaflet-tooltip.map-number-stroke {
  background: transparent;
  border: 0;
  box-shadow: none;
  color: #fff;
  font-weight: bold;
  text-stroke: 5px #fff;
  -webkit-text-stroke: 5px #fff;
}

.leaflet-tooltip.map-number {
  background: transparent;
  border: 0;
  box-shadow: none;
  color: #fff;
  font-weight: bold;
  .map-number-value {
    padding: 0 4px;
    border-radius: 8px;
  }
}
.iffy-hide {
  display: none !important;
}

.tooltip-inner {
  word-break: break-word;
}

treecontrol.simple-tree-control {

  font-family: "Open Sans";

  li {
    padding: 0;
  }
  li li {
    padding: 0 0 0 13px;
  }

  * {
    outline: none !important;
  }

  ul {
    margin-bottom: 7px;
  }

  li.tree-expanded i.tree-branch-head {
    font-style: normal;
    &:after {
      content: "▼";
      font-size: 10px;
      display: inline-block;
      width: 13px;
    }
  }

  li.tree-collapsed i.tree-branch-head {
    font-style: normal;
    &:after {
      content: "▶";
      font-size: 10px;
      display: inline-block;
      width: 13px;
    }
  }

  li.tree-leaf i.tree-leaf-head {
    padding-left: 13px;
  }

  li .tree-selected {
    
  }

  li .tree-unselectable {
    
  }

}

.inline-block {
  display: inline-block;
}

/* Need to make sure growl notification is on top of bootstrap navbar and modal */ 

body .growl-container.growl-fixed {
  z-index: $growl-z;

  // Updating growl styling to co-exist with bootstrap 5
  button.close {
    padding: 0;
    border: 0;
    background-color: rgba(0,0,0,0);

    float: right;
    font-size: 1.35rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
  }
}

nav.navbar.fixed-top {
  position: fixed;
}

.hans {
  transition: perspective  3s ease-in-out;
  perspective: 800px;

  body {
    transition: transform  3s ease-in-out;
    transform: rotate3d(0, 1, 0.15, 10deg) scale(0.75);
  }
}