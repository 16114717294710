@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'), 
      local('MaterialIcons-Regular'), 
      url(../../assets/fonts/materialicons.woff2) format('woff2'),
      url(../../assets/fonts/materialicons.ttf) format('truetype'),
      url(../../assets/fonts/materialicons.woff) format('woff'),
      url(../../assets/fonts/materialicons.eot);
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.material-icons {
  display: inline-flex;
  vertical-align: middle;
}

.material-icons.md-8  { font-size: 8px; }
.material-icons.md-10 { font-size: 10px; }
.material-icons.md-12 { font-size: 12px; }  
.material-icons.md-16 { font-size: 16px; }
.material-icons.md-14 { font-size: 14px; }
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-20 { font-size: 20px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-26 { font-size: 26px; }
.material-icons.md-26 { font-size: 26px; }
.material-icons.md-30 { font-size: 30px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

.title .material-icons.icon-title {
  font-size: 22px;
  position: absolute;
}

.record .tiles .material-icons.icon-title {
  font-size: 20px;
  position: absolute;
}
