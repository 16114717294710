.tile-associated-people {
  .md-list {
    padding: 8px 0;

    .md-list-item {
      position: relative;
      padding: 0 16px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-height: 48px;
      height: auto;
    }
  }
}