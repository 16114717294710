.grid-wrapper {
  overflow: hidden;
  .ag-grid.pivot-table, .ag-grid.list-view {


    .ag-overlay-loading-wrapper {
      background-color: rgba(0,0,0,0.5);
      .ag-overlay-loading-center {
        background: rgba(255,255,255,0.2);
        color: #fff;
      }
    }
    .ag-root {
      border-color: transparent;
    }
    .ag-header-cell, .ag-header {
      border-color: rgba(255,255,255,0.1);
    }

    .ag-cell, .ag-header-cell, .ag-floating-bottom {
      border: 0;
    }

    .ag-header {
      background: none;
    }

    .ag-header-row {
      background-color: $color-purple;	
      color: $color-white;
    }

    .ag-root .ag-row-hover {
      background-color: #005b80;
    }

    .ag-row-even {	
      background-color: $color-grey-tile;	
    }	
    
    .ag-row-odd {	
      background-color: $color-purple-grey;	
    }

    .ag-cell {
      padding: 11px;
      
      font-size: 12px;
      a {
        color: #fff;
        padding: 11px 0;
        font-weight: normal;
      }
    }

    .ag-header-icon {
      position: absolute;
      top: 0;
      left: 0;
    }

    .ag-pinned-left-header {
      .ag-cell-label-container .ag-header-cell-label .ag-header-cell-text {
        left: 0;
        text-align: left;
      }
    }

    .ag-cell-label-container {
      position: relative;
      height: calc(100% - 10px);
      .ag-header-cell-label {

        .ag-header-cell-text {
          font-size: 12px;
          text-overflow: clip;
          overflow: visible;
          white-space: normal;
          font-weight: 600;
          width: 100%;
          position: absolute;
          right: 0;
          bottom: 0;
          text-align: right;
        }
      }
    }
  }
}