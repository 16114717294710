.border-dotted-grey {
  border: 1px dashed $color-grey-200;
  border-radius: 10px;
}

.border-top-grey {
  border-top: 1px solid $color-white-1;
}

.border-left-grey {
 border-left: 1px solid $color-grey-200;
}

.border-top-white {
   border-top: 2px solid $color-grey-200;
}

.border-bottom-grey {
  border-bottom: 1px solid $color-white-1;
}

.border-bottom-red {
  border-bottom: 2px solid $color-red;
}

.border-bottom-green {
  border-bottom: 2px solid $color-green;
}

.border-bottom-yellow {
  border-bottom: 2px solid $color-yellow;
}

.border-bottom-white {
  border-bottom: 2px solid $color-grey-200;
}

.border-radius-0 {
  border-radius: 0;
}

.border-green {
  border-color: $color-green;
}

.border-yellow {
  border-color: $color-yellow;
}

.border-red {
  border-color: $color-red;
}

.border-left-grey-thick {
  border-left: solid 2px $color-grey-200;
}

.border-left-thick {
  border-left: solid 2px;
}