.tile-listings {
  .percentChange {
    &.up {
      color: $color-green;
      &::before {
        content: '↑ ';
      }
    }
    &.down {
      color: $color-red;
      &::before {
        content: '↓ ';
      }
    }
  }
  .no-break {
    white-space: nowrap;
  }
  table.table.table-striped {
    table-layout: auto;
  }
  .view-more {
    text-align: center;
  }
  .sources {
    .icon {
      margin-right: 5px;
      img {
        width: 18px;

        &.trulia {
          width: 7px;
        }
      }
    }
  }
}