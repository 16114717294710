.custom-buckets-editor-react {
  .modal-lg.modal-dialog {
    width: 90vw;
    min-width: 900px;
    max-width: inherit;
    .modal-content {
      height: 90vh;
      padding: 10px 0;

      .modal-body {
        padding: 0;
        height: 100%;

        .container {
          height: 100%;

          .row {
            height: 100%;

            .col {
              height: 100%;
            }
          }
        }
      }
    }
  }

  // position: relative;
  font-size: 13px;

  .fieldname {
    background-color: #E1E1E1;
    color: black;
    font-weight: bold;
    border-radius: 5px;
    padding: 0 5px;
    margin: 0 3px;
    cursor: move;   
    font-family: monospace;
    white-space: nowrap;

    &.field-undefined {
      color: $color-red;
    }
  }

  .bucket-type-editor {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: stretch;

    label {
      margin-top: 4px;
      margin-bottom: 0;
    }

    .custom-buckets-label {
      .error {
        color: $color-red;
        text-align: left;
        margin-left: 120px;
        margin-top: -9px;
      }
    }

    .type-label {
      display: flex;
      margin-left: 20px;
      padding: 0 10px 10px;
      text-align: left;
      font-size: 15px;
      label {
        width: 100px;
        font-weight: 700;
      }
      input {
        flex: 1 100%;
      }
    }
    .actions {
      display: flex;
      justify-content: center;

      button {
        margin: 10px 10px 0;
      }
    }
    .bucket-editor {
      flex: 1 100%;
      position: relative;
      overflow: auto;

      .queryedit {
        pre {
          background-color: inherit;
          border: none;
          padding: 0;
          display: inline;
          font-family: monospace;
          font-size: 13px;
          white-space: pre-wrap;
        }
      }

      .card {
        background-color: #f5f5f5;
        margin-bottom: 10px;
        padding: 9px;
        border-radius: 3px;
      }

      .custom-bucket-actions {
        margin-top: 5px;
        text-align: right;
      }
      .field-value-pair {
        .field {
          font-weight: 600;
        }
      }
    }
  }

  .record-info {
    height: 100%;
    .field-selector {
      height: 50%;
      border: solid 1px #cfcfcf;
    }
    .help {
      height: 50%;
      border: solid 1px #cfcfcf;
      border-top: 0;
      overflow: auto;
      text-align: left;
      padding: 10px;

      h4 {
        margin-top: 0;
        font-size: 16px;
      }
      h5 {
        font-size: 14px;
      }
    }
  }
  .bucket-placeholder {
    background-color: $color-light-bluegrey;
    height: 100px;
    margin-bottom: 20px;
  }

  .bucket-editor {
    text-align: left;
    position: relative;
    margin-bottom: 10px;
    span.grippy {
      position: absolute;
      left: 0;
      top: 8px;
      box-sizing: content-box;
      word-wrap: normal;
      content: '....';
      width: 10px;
      height: 20px;
      display: inline-block;
      overflow: hidden;
      line-height: 5px;
      padding: 3px 4px;
      cursor: move;
      vertical-align: middle;
      margin-top: -.7em;
      margin-right: .3em;
      font-size: 12px;
      font-family: sans-serif;
      letter-spacing: 2px;
      color: #cccccc;
      text-shadow: 1px 0 1px black;
    }
    span.grippy::after {
      content: '.. .. .. ..';
    }
    &.readonly {
      span.grippy, .bucket-actions {
        visibility: hidden;
      }
    }
    .query input {
      height: 50px;
    }
    .editor-container {
      margin-left: 20px;

      .title, .query {
        display: flex;
        label {
          width: 100px;
        }
        input, textarea {
          flex: 1 100%;
          padding: 2px 3px
        }
        textarea {
          height: 10rem;
          display: none;
        }
        .queryedit {
          background-color: white;
          flex: 1 100%;
          padding: 4px;
          border-width: 1px;
          border-style: solid;
          border-color: #DFDFDF;
          position: relative;
          font-family: monospace;
          word-break: break-all;
        }
      }
      .title {
        font-size: inherit;
      }
      .error {
        margin-left: 95px;
        color: $color-red;
        margin-bottom: 5px;
      }
    }
    .info-container {
      display: flex;
      justify-content: space-between;
      .bucket-actions {
        text-align: right;
        order: 1;
        .delete-bucket-button {
          cursor: pointer;
        }
      }
      .non-member i {
        visibility: hidden;
      }
      .analysis {
        &.analyzing {
          opacity: 0.4;
          text-align: center;
          margin-top: 5px;
        }
        flex-grow: 1;
        .invalid {
          display: flex;
          i {
            color: $color-red;
            margin-right: 10px;
            position: relative;
            top: 2px;
          }
        }
        .valid {
          display: flex;
          .member.default {
            color: $color-green;
          }
        }
        .field-values {
          margin-left: 20px;
          margin-bottom: 10px;
          .field-value-pair {
            display: flex;
            position: relative;
          }
          .field, .value {
          }
          .field {
            margin-right: 5px;
          }
        }
        .drawer {
          i {
            font-size: x-large;
            cursor: pointer;
          }
        }
        .javascriptQuery {
          margin-left: 20px;
        }
      }
    }
  }
}

