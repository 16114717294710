.tile-logs {
  .log-list {
    max-height: 300px;
    overflow: auto;
    .log {
      font-family: monospace;
      border-bottom: 1px solid rgba(255,255,255,0.1);
      padding: 3px 0;
    }
  }
}
