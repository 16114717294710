.cashflow-table {
  $tranche-background: #1e2223;
  $tranche-selected-background: #1f5c7f;
  
  .table-wrapper {
    position: relative;
    min-height: calc(100vh - 220px); // better way?
  }

  .calc_to {
    .radio {
      display: inline-block;
      margin-left: 20px;
      margin-top: 8px;
      margin-bottom: 0;
    }
  }

  .spinner {
    font-size: 30px;
    text-align: center;
    width: 100%;
    top: 0;
    position: absolute;
    z-index: $spinner-z;
    background: rgba(38,38,38,0.8);
    line-height: 100px;
    bottom: 0;
    opacity: 0;
    left: -1000%;

    transition: opacity 0.3s linear;
    &.loading {
      left: 0;
      opacity: 1;
    }
  }

  .scenario-detail-header {
    display: flex;
    a {
      height: 32px;
      margin-left: 20px;
      margin-top: 2px;
    }
  }

  .selectize-control.single {
    margin: -10px;
    padding: 10px 0 5px;
    max-width: 350px;
    min-width: 350px;
    border-right: 1px solid rgba(0,0,0,0.4);

    .selectize-dropdown {
      background: shade-color($tranche-background, 5%);
      color: #fff;
      border-color: tint-color($tranche-background, 30%);
      &.active {
        background: #40444e;
        color: #fff;
      }
    }
    .optgroup-header {
      color: #666;
      background: #000;
      font-size: 11px;
      font-weight: bold;
      text-transform: uppercase;
      &:after {
        content: ':'
      }
    }

    .selectize-input {
      background: transparent;
      border-color: transparent;
      padding-left: 10px;
      box-shadow: none;
      color: #fff;
      // font-size: 18px;

      &:after {
        border-top-color: #fff;
      }

      input {
        color: #fff;
        &::placeholder {
          color: #ccc;
        }
      }
      
      &.input-active {
        background: rgba(255, 255, 255, 0.15);
        color: #fff;

        &:after {
          border-bottom-color: #fff;
        }
      }
    }
  }
}