$color-neg: $color-green;
$color-pos: $color-red;
#sparkline {
  height: 24px;
}
#sparkline .spark {
  fill: none;
  stroke: $color-white;
  opacity: 1;
  stroke-width: 1.5px;
}
#sparkline .avg {
  fill: none;
  stroke: $color-light-blue;
  stroke-width: 0.5px;
  opacity: 1;
}
#sparkline .utilization #min {
  fill: $color-neg;
}
#sparkline .utilization #max {
  fill: $color-pos;
}
#sparkline .totaldebt #min {
  fill: $color-neg;
}
#sparkline .totaldebt #max {
  fill: $color-pos;
}
#sparkline .monthlydebt #min {
  fill: $color-neg;
}
#sparkline .monthlydebt #max {
  fill: $color-pos;
}
#sparkline .openaccounts #min {
  fill: $color-pos;
}
#sparkline .openaccounts #max {
  fill: $color-neg;
}
#sparkline .derogatories #min {
  fill: $color-neg;
}
#sparkline .derogatories #max {
  fill: $color-pos;
}
#sparkline .dti #min {
  fill: $color-neg;
}
#sparkline .dti #max {
  fill: $color-pos;
}
#sparkline .fico #max {
  fill: $color-neg;
}
#sparkline .fico #min {
  fill: $color-pos;
}
#sparkline .vpr #max {
  fill: $color-blue;
}
#sparkline .vpr #min {
  fill: $color-blue;
}

.d3-tip {
  background-color: #151515;
  line-height: 1;
  font-weight: bold;
  padding: 12px;
  background: $color-black;
  color: $color-white;
  border-radius: 2px;
}

/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color:  #151515;
  content: "\25BC";
  position: absolute;
  text-align: center;
  margin: -1px 0 0 0;
  top: 100%;
  left: 0;
}

.d3:before{
  color: #151515;
}

.d3-tip.e:after {
  content: "\25C0";
  text-align: left;
  margin: -30px 0 0 -8px;
}