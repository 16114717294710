.record-view, .record-view-content, .record {
  background-color: $color-black-bg;
  color: $color-white;
}
.pivot-view {
  background-color: #20232C;
  color: #fff;
}

.record, .masterCards {
  min-height: 110px;
  border: 1px solid $color-black-3;
  border-radius: 7px;
  background-color: #20232C;
}

.tiles {
  padding: 5px;
  // border: 1px solid $color-black-1;
  border-radius: 7px;
  border-color: #222;
  background-color: $color-grey-tile;
  font-size: 12px;
  color: $color-white;
}

.tiles .title {
  padding-left: 5px;
}

.tiles .table>thead>tr>th, .table>thead>tr>td, .table>tbody>tr>th, .table>tbody>tr>td {
  font-size: 12px !important;
}

.title {
  width: 100%;
  padding-top: 5px;
  padding-right: 0;
  padding-bottom: 0;
  text-transform: capitalize !important;
  font-weight: 600;
  font-size: 16px;
  min-height: 25px;
  vertical-align: center;
}

.sub-title {
  width: 100%;
  padding-bottom: 0;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 12px;
  min-height: 20px;
}

.fa.fa-file-text-o{
  color: $color-white;
}

md-divider {
  border-top-color: $color-white-1 !important;
}

/* packery layout */
.packery-container {
  position: absolute !important;
  top: 0;
  bottom: 0;
  right: 0;
  float: left;
  width: 100%;
}

.packery-sizer, .packery-object {
  width: 49%;
  height:auto;
  display: block;
  font-size: 12px;
  background-color: $color-white;
  color: $color-grey !important;

  @media screen and (max-width: 1025px){
    width: 97%;
  }
}

.packery-object {
  border: none !important;
  //border: 1px solid $color-black-1;
}

.packery-object .title {
  width: 100%;
}

.masonry {
  column-count: 2;
  column-gap: 1em;
}

.masonry-3-col {
  column-count: 3;
  column-gap: 1em;
}

.masonry-item { 
  display: inline-block;
  width: 100%;
}

@media only screen and (max-width: 960px) {
  .masonry {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }
  .masonry-3-col {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }
}

/* risk & spending */
.barContainer {
  padding: 0;
  width: 100%;
  height: 16px;
  background: $color-light-grey;
  overflow: hidden;
  border-radius: 3px;
  margin-right: 0;
}

.barContainer .barValue {
  padding: 0;
  height:16px;
  float: left;
}

$barHeight: 15px;
.barContainer {
  width: 95%;
  height: $barHeight;
  background: $color-light-grey;
  overflow: hidden;
  border-radius:3px;

  .barValue {
    height: $barHeight;
    float: left;
  }

  .animateWidth{
    animation: slideIn 2s;
    -webkit-animation: slideIn 2s;
  }

  @keyframes slideIn {
    0% { width: 0; }
    100% { width: normal; }
  }

  @-webkit-keyframes slideIn {
    0% { width: 0; }
    100% { width: normal; }
  }
}

/*maps*/
.card-row-underline {
  border-bottom: 1px solid $color-grey-200;
  margin-left: 20px;
  margin-right: 20px;
}

/*override*/
// .overrideForm {

//   input{
//     font-size: 14px !important;
//   }

//   form md-input-container{
//     margin-top: 14px !important;
//   }

//   form md-input-container .md-errors-spacer {
//     height: 8 !important;
//     min-height: 8 !important;
//   }


//   form md-input-container label {
//     font-size: 14px;
//     margin-bottom:0;
//   }

//   md-input-container.md-input-focused label{
//     color: $tsi-darkblue !important;
//   }

//   md-input-container.md-input-focused .md-input{
//     border-color: $tsi-darkblue !important;
//   }

//   .md-button.md-primary.md-raised {
//     background-color: $tsi-blue !important;
//   }

//   .cancel {
//     background-color: $tsi-lightgrey !important;
//   }

//   .delete {
//     background-color: $tsi-darkblue !important;
//     color: $tsi-white;
//   }

// }

// .overrideButton {
//   .md-button:hover, .md-button.md-focused {
//     background-color: transparent !important;
//   }
// }