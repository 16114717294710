.dashboard-widget {
  position: relative;
  box-shadow: 0 2px 5px rgba(0,0,0,0.6);
  border: solid 1px rgba(80,80,80,1);
  
  > h4 {
    font-weight: 300;
    margin: 0px;
    padding: 10px;
    padding-right: 30px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .dashboard-widget-contents {
    padding: 10px;
    display: block;
    height: 250px;
  }
  .dashboard-widget-configuration {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    color: rgba(0,0,0,0.5);
    cursor: pointer;
  }
  .material-icons {
    color: $color-light-grey;
  }
}