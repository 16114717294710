$color-black-1: rgba(33, 33, 33, 0.1) !default;
$color-black-3: rgba(33, 33, 33, 0.3) !default;
$color-black: #212121 !default;
$color-black-bg: #20232C !default;
$color-purple: #393E51 !default;
$color-dark-grey: #343b46 !default;
$color-grey-tile:  #5B6473 !default;
$color-grey-tile-darker: #535A67 !default;
$color-purple-grey: #898EA0 !default;
$color-light-dark-grey: #e1e4e9 !default;
$color-grey-68: rgba(68,68,68,1) !default;
$color-grey: rgba(100, 100, 100, 1) !default;
$color-grey-114: rgba(114, 114, 114, 1) !default;
$color-grey-130: rgba(130, 130, 130, 1) !default;
$color-grey-170: rgba(170, 170, 170, 1) !default;
$color-grey-200: rgba(220, 220, 220, 1) !default;
$color-grey-230: rgba(238, 238, 238, 1) !default;
$color-light-grey: rgba(245, 245, 245, 1) !default;
$color-white: rgba(255, 255, 255, 1) !default;
$color-white-1: rgba(255, 255, 255, 0.3) !default;
$color-red: #FF5252 !default;
$color-red-1: #F22E2E !default;
$color-green: #1AB595 !default;
$color-green-1: #0FA385 !default;
$color-yellow: #FFC107 !default;
$color-blue: #009DDC !default;
$color-blue-1: #3692D8 !default;
$color-light-blue: #6ab5e8 !default;
$color-blue-hover: rgba(180, 219, 248, 1) !default;
$color-bluegrey: #607D8B !default;
$color-light-bluegrey: #e9f1f7 !default;

/*bg colors*/
.bg-black {
  background-color: $color-black;
}
.bg-black-1 {
  background-color: $color-black-1;
}
.bg-dark-grey {
  background-color: $color-dark-grey;
}
.bg-grey {
  background-color: $color-grey;
}
.bg-white {
  background-color: $color-white;
}
.bg-light-grey {
  background-color: $color-light-grey;
}
.bg-grey-200 {
  background-color: $color-grey-200;
}
.bg-light-bluegrey {
  background-color: $color-purple;
}
.bg-grey-230 {
  background-color: $color-dark-grey;
}
.bg-light-blue {
  background-color: $color-light-blue;
}
.bg-blue {
  background-color: $color-blue;
}
.bg-bluegrey {
  background-color: $color-bluegrey;
}
.bg-green {
  background-color: $color-green;
}
.bg-yellow {
  background-color: $color-yellow;
}
.bg-red {
  background-color: $color-red;
}

/*fg colors*/
.fg-black {
  color: $color-black;
}
.fg-dark-grey {
  color: $color-dark-grey;
}
.fg-grey {
  color: $color-grey;
}
.fg-light-grey {
  color: $color-light-grey;
}
.fg-grey-170 {
  color: $color-grey-170;
}
.fg-white {
  color: $color-white;
}
.fg-blue {
  color: $color-blue;
}
.fg-hover-blue-light {
  color: $color-grey-170;
}
.fg-hover-blue-light:hover {
  transition: all 400ms;
  color: $color-blue;
}
.fg-hover-blue {
  color: $color-grey;
}
.fg-hover-blue:hover {
  transition: all 400ms;
  color: $color-blue;
}
.fg-light-blue {
  color: $color-light-blue;
}
.fg-green {
  color: $color-green;
}
.fg-yellow {
  color: $color-yellow;
}
.fg-red {
  color: $color-red;
}



/*fill colors*/
.fill-white {
  fill: $color-white;
}
.fill-blue {
  fill: $color-blue;
}
.fill-dark {
  fill: $color-dark-grey;
}
.fill-black {
  fill: $color-black !important;
}
.fill-red {
  fill: $color-red;
}



/*union*/
$color-blue-union: rgba(39, 74, 159, 1) !default;
$color-darkblue-union: rgba(26, 50, 108, 1) !default;
$color-uniongrey: rgba(111, 112, 114, 1) !default;
$color-uniongrey-light: rgba(153, 153, 153, 1) !default;
$color-union-green: rgba(5, 174, 125, 1) !default;
.fg-union-green {
  color: $color-union-green;
}
.bg-union-green {
  background-color: $color-union-green;
}
/* end of union*/