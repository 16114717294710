$tranche-background: #1e2223;
$tranche-selected-background: #1f5c7f;

.widget-payscen-tranche-analytics {
  background: $tranche-background;
  padding: 0px;
  margin-bottom: 0px;

  &.has-tranche {
    padding-bottom: 5px;
  }

  .btn:not(.is-focused) {
    background: transparent;
  }

  .pin-board {
    i.material-icons {
      margin-right: 5px;
      font-size: 14px;
    }
    .separator {
      width: 100%;
      border-bottom: 1px solid #999;
      margin: 5px 0;
    }
    >label>a>i.material-icons {
      margin-left: 5px;
      font-size: 11px;
    }

    .has-value .btn {
      background: #673ab7;
      padding-top: 4px;
    }

    .btn {     
      .is-modified {
        font-size: 10px;
        opacity: 0.5;
        margin-left: 0px;
        margin-right: 5px;
        overflow: hidden;
      }
      
      display: inline-flex;
      margin-left: 0;
      width: 100%;
      height: 100%;
      align-items: center;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 400;
      background: rgba(255,255,255,0.05);

      &:not([disabled]):not(.disabled):hover{
        color: inherit;
      }

      &.dropdown-toggle::after {
        margin-left: auto !important;
      }
    }

    .input-group {
      .focused-scenario & {
        width: calc(100% - 75px);
      }
      width: 100%;

      .button-text {
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

      }

      .is-modified {
        font-size: 10px;
        opacity: 0.5;
        margin-left: 0px;
        margin-right: 5px;
        width: 100%;
        max-width: 100%;
        overflow: hidden;
      }

      .btn {
        
        .caret {
          position: absolute;
          right: 6px;
          top: 10px;
        }
      }
    }
  }

  .condensed {
    padding: 0 0 5px;
  }

  .tranche-header {
    background: rgba(0,0,0,0.2);
    height: 35px;
    display: flex;

    .btn {
      width: 60px;
      margin: 5px;
      height: 25px;
      line-height: 15px;
      font-size: 11px;
      font-weight: normal;
    }

    .selectize-control {
      width: 100%;
    }
  }

  &.is-focused {
    background: tint-color($tranche-background, 5%);
  }

  .widget-payscen-scenario-parameter-input:first-child {
    margin-top: 10px;
  }

  .selectize-control.single {
    .selectize-dropdown {
      background: shade-color($tranche-background, 5%);
      color: #fff;
      border-color: tint-color($tranche-background, 30%);
      white-space: nowrap;
      overflow: hidden;
      &.active {
        background: #40444e;
        color: #fff;
      }
    }

    .selectize-input {
      background: transparent;
      border-color: transparent;
      padding-left: 10px;
      box-shadow: none;
      color: #fff;
      // font-size: 18px;

      &:after {
        border-top-color: #fff;
      }

      input {
        color: #fff;
        &::placeholder {
          color: #ccc;
        }
      }
      
      &.input-active {
        background: rgba(255, 255, 255, 0.15);
        color: #fff;

        &:after {
          border-bottom-color: #fff;
        }
      }
    }
  }
}