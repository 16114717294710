
/* cyrillic-ext */

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url("../../assets/fonts/open-sans-light-cyrillic-ext.woff2") format('woff2');
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url("../../assets/fonts/open-sans-light-cyrillic.woff2") format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url("../../assets/fonts/open-sans-light-greek-ext.woff2") format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url("../../assets/fonts/open-sans-light-greek.woff2") format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url("../../assets/fonts/open-sans-light-vietnamese.woff2") format('woff2');
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url("../../assets/fonts/open-sans-light-latin-ext.woff2") format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url("../../assets/fonts/open-sans-light-latin.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url("../../assets/fonts/open-sans-cyrillic-ext.woff2") format('woff2');
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url("../../assets/fonts/open-sans-cyrillic.woff2") format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url("../../assets/fonts/open-sans-greek-ext.woff2") format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url("../../assets/fonts/open-sans-greek.woff2") format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url("../../assets/fonts/open-sans-vietnamese.woff2") format('woff2');
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url("../../assets/fonts/open-sans-latin-ext.woff2") format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url("../../assets/fonts/open-sans-latin.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url("../../assets/fonts/open-sans-semi-bold-cyrillic-ext.woff2") format('woff2');
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url("../../assets/fonts/open-sans-semi-bold-cyrillic.woff2") format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url("../../assets/fonts/open-sans-semi-bold-greek-ext.woff2") format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url("../../assets/fonts/open-sans-semi-bold-greek.woff2") format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url("../../assets/fonts/open-sans-semi-bold-vietnamese.woff2") format('woff2');
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url("../../assets/fonts/open-sans-semi-bold-latin-ext.woff2") format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url("../../assets/fonts/open-sans-semi-bold-latin.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url("../../assets/fonts/open-sans-bold-cyrillic-ext.woff2") format('woff2');
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url("../../assets/fonts/open-sans-bold-cyrillic.woff2") format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url("../../assets/fonts/open-sans-bold-greek-ext.woff2") format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url("../../assets/fonts/open-sans-bold-greek.woff2") format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url("../../assets/fonts/open-sans-bold-vietnamese.woff2") format('woff2');
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url("../../assets/fonts/open-sans-bold-latin-ext.woff2") format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url("../../assets/fonts/open-sans-bold-latin.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
 font-family: 'Open Sans';
 font-style: normal;
 font-weight: 300;
 src: local('Open Sans Light'), local('OpenSans-Light'), url("../../assets/fonts/open-sans-light.woff") format('woff');
}
@font-face {
 font-family: 'Open Sans';
 font-style: normal;
 font-weight: 400;
 src: local('Open Sans'), local('OpenSans'), url("../../assets/fonts/open-sans.woff") format('woff');
}
@font-face {
 font-family: 'Open Sans';
 font-style: normal;
 font-weight: 600;
 src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url("../../assets/fonts/open-sans-semi-bold.woff") format('woff');
}
@font-face {
 font-family: 'Open Sans';
 font-style: normal;
 font-weight: 700;
 src: local('Open Sans Bold'), local('OpenSans-Bold'), url("../../assets/fonts/open-sans-bold.woff") format('woff');
}

/* vietnamese */
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 400;
  src: local('Inconsolata Regular'), local('Inconsolata-Regular'), url("../../assets/fonts/inconsolata1.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 400;
  src: local('Inconsolata Regular'), local('Inconsolata-Regular'), url("../../assets/fonts/inconsolata2.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 400;
  src: local('Inconsolata Regular'), local('Inconsolata-Regular'), url("../../assets/fonts/inconsolata3.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
 
body {
  font-family: 'Open Sans', sans-serif;
  // font-family: 'Inconsolata', 'Open Sans', sans-serif;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

// font size
.font-bold {
  font-weight: bold;
}

.font-uppercase {
  text-transform: uppercase;
}

.font-italic {
  font-style: italic;
}

.font-size-6 {
  font-size: 6px;
}
.font-size-8 {
  font-size: 8px;
}
.font-size-9 {
  font-size: 9px;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-11 {
  font-size: 11px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-13 {
  font-size: 13px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-15 {
  font-size: 15px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-17 {
  font-size: 17px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-19 {
  font-size: 19px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-26 {
  font-size: 26px;
}

.font-size-36 {
  font-size: 36px;
}

.font-size-sm-16 {
  font-size:16px;
}

.font-size-gt-sm-16 {
  @include media-breakpoint-down(md)
    {
      font-size:16px;
    }
}

.font-size-gt-sm-12 {
  @include media-breakpoint-down(md) 
    {
      font-size:12px;
    }
}

.font-size-30 {
  font-size: 30px;
}

.font-size-36 {
  font-size: 36px;
}

.font-size-40 {
  font-size: 40px;
}

.line-height-11 {
  line-height: 11px;
}

.line-height-15 {
  line-height: 15px;
}

.line-height-30 {
  line-height: 30px;
}

.line-height-32 {
  line-height: 30px;
}

.line-height-34 {
  line-height: 34px;
}

.line-height-40 {
  line-height: 40px;
}

.line-height-45 {
  line-height: 45px;
}

.line-height-50 {
  line-height: 50px;
}

.line-height-100 {
  line-height: 100px;
}

.text-error {
  color: $color-red;
}

.text-center {
  text-align: center;  
}

.text-center-sm {
  text-align: center;
}
.fa-rotate-25 {
  -webkit-transform: rotate(25deg);
  -moz-transform: rotate(25deg);
  -ms-transform: rotate(25deg);
  -o-transform: rotate(25deg);
  transform: rotate(25deg);
}
.fa-rotate-90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.fa-rotate-135 {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.font-capitalize {
  text-transform: capitalize;
}
