.widget-list {
  .widget-list-item {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &:not(.with-padding) {
    >.widget-list-right:not(:empty) {
      margin-top: 15px;
    }
  }


  @keyframes fadeIn { 
    from { opacity: 0; } 
  }

  .widget-list-loading {
    color: #999;
    animation: fadeIn 1s infinite alternate;
  }

  &.with-padding {
    flex-wrap: nowrap;

    >.widget-list-left {
      padding-right: 15px;
      max-width: 50%;
    }
    >.widget-list-right {
      padding-left: 15px;
      max-width: 50%;
    }
  }
}