#progressRedial {
  width: 100%;
  background: $color-white;
  color: $color-grey;
}

#progressRedial .subtext {
  fill: $color-grey;
  font-size: 16px;
}

#progressRedial svg g .min, #progressRedial svg g .max, #progressRedial svg g .middle {
  font-weight: 800;
}

#progressRedial .Quality .min {
  fill: $color-red;
}
#progressRedial .Quality .middle {
  fill: $color-yellow;
}

#progressRedial .Quality .max {
  fill: $color-green;
}
